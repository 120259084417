import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, Typography, TextField, IconButton, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSearchAction, clearGetUserSearchAction } from '../../../store/actions';
import ReceivedRatingTable from "./receivedRating";
import GivenRatingTable from './givenRatings';
import Profile from './profile';
import LinkedRealationships from './linkedDetails';
import Loader from '../../../components/loader'; // Ensure this import is correct
import { toast } from 'react-toastify';

function SearchWithPAN() {
    const [searchValue, setSearchValue] = useState("");
    const [value, setValue] = useState("0");
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { successData, loading, error, errorInfo } = useSelector((state) => state.getUserSearchAction) || {};

    const tabNames = ["Profile", "Received Rating", "Given Rating", "Disputes", "Linked Details"];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearch = () => {
        if (searchValue) {
            dispatch(
                getUserSearchAction({
                    endPoint: `admin/userSearch/?pan_number=${searchValue}`,
                })
            );
        }
    };

    const handleSearchChange = (e) => {
        const upperCaseValue = e.target.value.toUpperCase();
        setSearchValue(upperCaseValue);
    };

    useEffect(() => {
        return () => {
            dispatch(clearGetUserSearchAction());
            setSearchValue("");
            setValue("0");
        };
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(errorInfo);
        }
    }, [error]);

    let content;

    if (loading) {
        content = <Loader className="loader" />;
    } else if (searchValue && successData) {
        content = (
            <Box sx={{ width: '100%', maxWidth: "100%", typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            variant='scrollable'
                            scrollButtons='auto'
                            allowScrollButtonsMobile
                            sx={{
                                '& .MuiTabs-scrollButtons': {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                        >
                            {tabNames.map((name, index) => (
                                <Tab
                                    label={name}
                                    value={String(index)}
                                    key={name}
                                    sx={{
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: 'primary.main',
                                            borderRadius: 1,
                                        },
                                        fontFamily: 'DM Sans, sans-serif',
                                        fontWeight: 500,
                                        fontSize: isMobile ? '0.8rem' : '1rem',
                                        minWidth: isMobile ? '100px' : '150px',
                                        marginX: '8px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            borderRadius: 1,
                                        },
                                    }}
                                />
                            ))}
                        </TabList>
                    </Box>
                    {tabNames.map((name, index) => (
                        <TabPanel value={String(index)} key={name} sx={{ padding: 0 }}>
                            <Box sx={{ padding: isMobile ? 1 : 2 }}>
                                {index === 2 && <GivenRatingTable givenRatings={successData?.givenRatings} loading={loading} />}
                                {index === 1 && <ReceivedRatingTable receivedRatings={successData?.recievedRatings} searchValue = {searchValue} loading={loading} />}
                                {index === 0 && <Profile profile={successData?.user} searchValue= {searchValue} />}
                                {index === 4 && <LinkedRealationships linkedDetails={successData?.linkedRelationships} />}
                                {/* Add other components for other tabs */}
                            </Box>
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
        );
    } else {
        content = (
            <Typography
                variant="h5"
                align="center"
                marginTop="20%"
                color="error"
                sx={{
                    animation: "flash 2s infinite",
                    "@keyframes flash": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0 },
                        "100%": { opacity: 1 },
                    },
                }}
            >
                {'***  Alert: Please Enter PAN Number for User Details ***'}
            </Typography>
        );
    }

    return (
        <Box minHeight='100vh' boxShadow={2} m={2} p={2}>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mb={2}>
                <Typography variant={isMobile ? 'h6' : 'h5'} mr={isMobile ? 0 : 2} mb={isMobile ? 2 : 0} fontWeight={400}>
                    Search with PAN number
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    border={1}
                    borderColor="black"
                    padding={1}
                    borderRadius={1}
                    width={isMobile ? '100%' : 'auto'}
                >
                    <TextField
                        placeholder="Enter PAN number"
                        variant="standard"
                        size="medium"
                        onChange={handleSearchChange}
                        value={searchValue}
                        fullWidth={!isMobile}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: isMobile ? (
                                <IconButton onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            ) : null,
                            style: { textAlign: 'center' },
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                    {!isMobile && (
                        <IconButton onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    )} 
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {content}
            </Box>
        </Box>
    );
}

export default SearchWithPAN;
