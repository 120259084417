import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PropTypes from "prop-types"

import "./NotificationDropdown.css"; // Import the CSS file

function NotificationDropdown({ notifications, onClose }) {
  return (
    <TableContainer component={Paper}>
      <Table className="notificationTable" aria-label="notifications table">
        <TableHead>
          <TableRow></TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((notification, index) => (
            <TableRow
              key={notification.id}
              style={{
                borderBottom:
                  index === notifications.length - 1
                    ? "none"
                    : "1px solid #e0e0e0",
              }}
            >
              <TableCell className="notificationCell">
                <div
                  style={{
                    textAlign: "left",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                >
                  {notification.heading}
                </div>
                <div style={{ textAlign: "left" }}>{notification.text}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NotificationDropdown;


NotificationDropdown.propTypes = {
  notifications:PropTypes.any.isRequired,
  onClose:PropTypes.func.isRequired
}