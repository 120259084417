import React from "react";
import '../../styles/scoreDetails.css'
import PopTypes from "prop-types"

const IsGSTPaid = ({isGSTPaid}) => {
  return (
    <>
    
      <div className="styleforheading">GST Paid on Time</div>
      <div className="main">
        <h5>Yes : {isGSTPaid['0'].yes}</h5>
        <h5>No : {isGSTPaid['0'].no}</h5>
        
    </div>
    </>
  );
};

export default IsGSTPaid;

IsGSTPaid.propTypes = {
  isGSTPaid:PopTypes.string.isRequired
}
