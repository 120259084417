import sliceCreator from '../index.js';
import {Edit_User} from "../../../constants/index.js"

const _patchEditUserAction = sliceCreator('Edit_User', Edit_User, 'patch');

const { asyncAction: patchEditUserAction, reducer, clearData: clearPatchEditUserAction } = _patchEditUserAction;

export default reducer;

export {
    patchEditUserAction,
    clearPatchEditUserAction
}