import sliceCreator from '../index.js';
import {Edit_Business} from "../../../constants/index.js"

const _patchEditBusinessAction = sliceCreator('Edit_Business', Edit_Business, 'patch');

const { asyncAction: patchEditBusinessAction, reducer, clearData: clearPatchEditBusinessAction } = _patchEditBusinessAction;

export default reducer;

export {
    patchEditBusinessAction,
    clearPatchEditBusinessAction
}