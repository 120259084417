import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordAction, verifyPasswordAction, clearForgotPasswordActionData, clearVerifyPasswordActionData } from '../../store/actions';
import Navbar from '../navbar';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [panNumber, setPanNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);

  const { loading, successData, error, errorInfo } = useSelector((state) => state.forgotPasswordReducer);
  const { loading: verifyLoading, successData: verifySuccessData, error: verifyError, errorInfo: verifyErrorInfo } = useSelector((state) => state.verifyForgotPasswordReducer);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleconfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const handleForgotPassword = () => {
    if(newPassword==='' || confirmPassword==='' || newPassword !== confirmPassword) {
      if(newPassword==='' || confirmPassword==='')
        toast.error('New Password and Confirm Password cannot be empty.');
      else
      toast.error('New Password and Confirm Password do not match.');
      return;
    }
    const panRegex = /^\w{5}\d{4}\w$/;
      if (!panRegex.test(panNumber)) {
        toast.error("Invalid PAN number. It should be in the format 'AAAAA9999A'.");
        return;
      }
    if(panNumber===''){
      toast.error('PAN Number cannot be empty.');
      return;
    }
    const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(phoneNumber)) {
        toast.error("Invalid phone number. It should be exactly 10 digits.");
        return;
      }
    if(phoneNumber===''){
      toast.error('Phone Number cannot be empty.');
      return;
    }
    
    const payload = {
      pan_number: panNumber,
      phone_number: phoneNumber,
    };
    dispatch(forgotPasswordAction({ ...payload }));
  };

  const handleVerifyPasswordReset = () => {
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      toast.error("Invalid OTP. It should be exactly 6 digits.");
      return;
    }
    const payload = {
      phone_number: phoneNumber,
      otp: otp,
      new_password: newPassword,
    };
    dispatch(verifyPasswordAction({ ...payload }));
  };

  useEffect(() => {
    if (successData) {
      setIsOtpSent(true);
    setTimer(120); // 1 minute timer
    toast.success("Sending OTP please wait...");
    dispatch(clearForgotPasswordActionData())

    }
  }, [successData,dispatch]);

  useEffect(() => {
    if(verifySuccessData){
      setTimeout(() => {
        dispatch(clearForgotPasswordActionData());
        dispatch(clearVerifyPasswordActionData());
        navigate('/login');
      }, 2000);
    }
  }, [verifySuccessData,navigate,dispatch]);

  useEffect(() => {
    if (error) {toast.error(errorInfo?.data || errorInfo); dispatch(clearForgotPasswordActionData())}

    if (verifyError) {toast.error(verifyErrorInfo?.data || verifyErrorInfo); dispatch(clearVerifyPasswordActionData())}
    if (verifySuccessData) toast.success('Password updated successfully...');
    // Ensure to clear these messages or handle them appropriately to avoid duplicate toasts
  }, [dispatch,errorInfo,verifyErrorInfo,loading, error, verifyLoading, verifyError, verifySuccessData]);

  return (
    <>
      <Navbar />
      <Container maxWidth="sm" sx={{ mt: 5, pb: 5 }}>
      
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Account Claim/Forgot Password
          </Typography>
          { (loading || verifyLoading) && <p>Please Wait....</p>}
          <TextField
            fullWidth
            label="PAN Number"
            variant="outlined"
            value={panNumber}
            onChange={(e) => setPanNumber(e.target.value.trim().toUpperCase())}
            disabled={isOtpSent}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.trim())}
            disabled={isOtpSent}
            margin="normal"
          />
          <TextField
            fullWidth
            label="New Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value.trim())}
            disabled={isOtpSent}
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value.trim())}
            disabled={isOtpSent}
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleToggleconfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
          />
          {!isOtpSent && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleForgotPassword}
              sx={{ mt: 3 }}
              disabled = {(loading)}
            >
              {loading ? "loading" : "Send OTP"}
            </Button>
          )}
          {isOtpSent && (
            <>
              <TextField
                fullWidth
                label="OTP"
                variant="outlined"
                value={otp}
                onChange={(e) => setOtp(e.target.value.trim())}
                margin="normal"
              />
              {timer <= 0 ? <Alert severity="error">OTP has expired. Please request a new OTP.</Alert> : <Typography variant="body1" sx={{ mt: 2 }}>
                OTP expires in: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
              </Typography>}
              
              <Box display="flex" justifyContent="space-between" mt={2}>
                {timer <= 0 ? (
                  <Button variant="contained" color="primary" onClick={handleForgotPassword}>
                    Resend OTP
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleVerifyPasswordReset} disabled={timer <= 0 || verifyLoading }>
                    Submit
                  </Button>
                )}
              </Box>
              
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
