import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRelatedPANDetailsAction,
  updateRelatedPANStatusAction,
  deleteRelatedPANAction,
} from "../../../store/actions";
import Table from "../../../components/table";
import TableData from "../../../utils/tableutils";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
  Checkbox,
  Grid,
  TextField,
  useMediaQuery,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import Loader from "../../../components/loader";







const RelatedPANDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const columns = React.useMemo(() => TableData.reletedPan, []);

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);

  const { successData, loading } = useSelector(
    (state) => state.getRelatedPANDetailsAction
  );

  
  useEffect(() => {
    dispatch(getRelatedPANDetailsAction());
  }, [dispatch]);

  useEffect(() => {
    if (successData) {
      setData(successData);
    }
  }, [successData]);

  const onClickEditDetails = (item) => {
    setFormData({
      id: item.id,
      name: item.name,
      user_id_logged: item.user_id_logged,
      user_id_main: item.user_id_main,
      user_pan_assoc: item.user_pan_assoc,
      isValid: item.isValid,
      relationship: item.relationship,
    });
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };

  const handleDeleteClick = (item) => {
    setFormData({
      id: item.id,
    });
    setOpen(true);
  };

  const handleDeleteConfirmClick = () => {
    dispatch(deleteRelatedPANAction({ endPoint: `admin/linkedRelationship/${formData.id}/`, ...formData }));
    setOpen(false);
    setFormData({}); // Clear form data after submission
    toast.success("Related Pan details deleted successfully");
    // Optionally, update data immediately on delete success
    setData(data.filter(item => item.id !== formData.id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateRelatedPANStatusAction({ endPoint: `admin/linkedRelationship/${formData.id}/`, ...formData }));
    setShow(false);
    setFormData({}); // Clear form data after submission
    toast.success("Related Pan details updated successfully");
    // Optionally, update data immediately on update success
    setData(data.map(item => item.id === formData.id ? { ...item, ...formData } : item));
  };


let content;

if (loading) {
  content = <Loader/>; // Show loading indicator while fetching data
} else if (data.length > 0) {
  content = (
    <Table
      columns={columns}
      data={data}
      onClickEditDetails={onClickEditDetails}
      handleDeleteClick={handleDeleteClick}
    />
  );
} else {
  content = "No Pan Relation Request";
}


  return (
    <Box height="84vh" boxShadow={4} m={2} p={2}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" mb={1}>
          Related PAN Details
        </Typography>
      </Box>
      {content}
      <Box>
        <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
          <Box position="relative" sx={{ p: 2 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 5,
                right: 8,
                "&:hover": { backgroundColor: "none", color: "red" },
              }}
            >
              <CloseIcon />
            </IconButton>
   
            <DialogContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{}}
              >
                <Typography
                  gutterBottom
                  sx={{
                    mt: 2,
                    fontWeight: "bold",
                    color: "darkblue",
                    fontSize: "30px",
                    fontFamily: "Arial",
                  }}
                >
                  Edit Relation
                </Typography>
                <Box
                  sx={{
                    maxWidth: 600,
                    margin: "auto",
                    padding: 2,
                    border: "1px solid #ccc",
                    borderRadius: 4,
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={10} sm={6}>
                        <TextField
                          id="id"
                          name="id"
                          label="ID"
                          value={formData.id}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={6}>
                        <TextField
                          id="name"
                          name="name"
                          label="Name"
                          value={formData.name}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="relationship"
                          name="relationship"
                          label="Relationship"
                          value={formData.relationship}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="user_id_logged"
                          name="user_id_logged"
                          label="User ID Logged"
                          value={formData.user_id_logged}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="user_id_main"
                          name="user_id_main"
                          label="User ID Main"
                          value={formData.user_id_main}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="user_pan_assoc"
                          name="user_pan_assoc"
                          label="User PAN Assoc"
                          value={formData.user_pan_assoc}
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                          <Checkbox 
                            id="isValid"
                            checked={formData.isValid}
                            onChange={() => {
                              setFormData({
                                ...formData,
                                isValid: !formData.isValid,
                              });
                            }}
                          />
                          <label htmlFor="isValid">Is Valid</label>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleDeleteConfirmClick} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default RelatedPANDetails;
