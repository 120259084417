import sliceCreator from '../../index.js';
import {RELETEDPAN} from "../../../../constants/index.js"

const _deleteRelatedPANAction = sliceCreator('RELETEDPAN', RELETEDPAN, 'delete');

const { asyncAction: deleteRelatedPANAction, reducer, clearData:  cleardeleteRelatedPANAction } = _deleteRelatedPANAction;

export default reducer;

export {
    deleteRelatedPANAction,
    cleardeleteRelatedPANAction
}