import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Upgrade from "../../assets/Upgrade.jpg";
import "./index.css";

const PlanUpgradeModel = ({ show, handleClose }) => {


 
  if (!show) return null;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm" >
  <Box position="relative" sx={{ p: 2 }}>
    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 ,'&:hover': { backgroundColor: 'none',color:"red" }}}>
      <CloseIcon />
    </IconButton>
    <DialogTitle>
      <Typography variant="h6" align="center" sx={{ color:"red" }}>
        **Unlock Nationwide Rating!**
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ p: 1 }}>
        <img className="PlanUpgradeImg" src={Upgrade} alt="Plan Upgrade" />
        <Typography gutterBottom sx={{ mt: 2, fontWeight:"bold", color:"darkblue",fontSize:"30px",fontFamily:"Roboto"}}>
          Upgrade Your Plan
        </Typography>
        <Typography variant="h6" sx={{ mt: 1 }}>
          We noticed you're currently on a CB Pro plan.
        </Typography>
        <Typography variant="body1" className="Planpara" sx={{ mt: 1 }}>
          Upgrade from CB Pro to CB Enterprise or CB Ent+Emp and rate users across the country. Expand your reach and maximize the ClearBiz platform's potential.
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions >
      <Box width="100%" display="flex" justifyContent="center" gap={"50px"} sx={{ pb: 2 }}>
        <Button  variant="contained" color="primary" href="/about/pricing" target="_blank" sx={{ '&:hover': { backgroundColor: 'primary.main',boxShadow: 'none',color:"white" },}}>
          Compare Plans
        </Button>
            <Button variant="contained" color="secondary" className="UpgradeBtn" component="a" href="https://wa.me/message/YVSLTCOJGS7SB1" target="_blank" sx={{ '&:hover': { boxShadow: 'none',color:"white" },}}
            >
          Contact Sales
        </Button>
      </Box>
    </DialogActions>
  </Box>
</Dialog>
  );
};



export default PlanUpgradeModel;

PlanUpgradeModel.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};