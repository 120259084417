import {
    combineReducers
} from "redux";
import loginUserDetails from '../slices/login'
import globalConfigData from '../slices/signup/globalconfig'
import gstdetailsData from '../slices/gstdetails'
import registrationData from '../slices/verifypage'
import searchPartnersData from '../slices/search'
import myPartnerData from '../slices/moredetails'
import getMyPartnerData from '../slices/dashboard'
import getMyPartnerRatingsData from '../slices/partnerdetails'
import getMyRatingData from '../slices/myratings'
import  getRaiseDispute  from "../slices/Raisedispute";
import postRaiseDispute from '../slices/Raisedispute/createraisedispute'
import profileData from '../slices/profile'
import updateProfileData from '../slices/profile/updateprofile'
import putResolvedDispute from '../slices/Resolveddispute'
import getMyNotificationsAction  from "../slices/notifications";
import putNotificationsAction from "../slices/notifications/notificationput";
import  postPaymentDetails  from "../slices/payment";
import postPasswordResetAction  from "../slices/passwordreset";
import getSubscriptionData from '../slices/subscription'
import isSubscriptionActive from "../slices/issubscriptionactive";
import forgotPasswordReducer from "../slices/forgotpassword";
import verifyForgotPasswordReducer from '../slices/verifypassword';

import sendOtpReducer from "../slices/sendotp";
import verifyOtpReducer from "../slices/verifyotp";
import loginWithOtpReducer from "../slices/loginwithotp";
import getAdminDashboardAction from "../slices/admindashboard"
import getUserSearchAction from "../slices/adminUserSearch"
import getadminPaymentsAction from "../slices/adminPayments"
import getUserSearchByLocation  from "../slices/adminSearchDb";
import patchAdminPaymentsAction from "../slices/adminUpdatePayments"
import deleteadminPaymentsAction from "../slices/adminDeletePayments"
import getadminInctiveUsersAction from "../slices/adminInactiveUsers";
import getRelatedPANDetailsAction from "../slices/adminReletedPan/get"
import updateRelatedPANStatusAction from "../slices/adminReletedPan/update"
import deleteRelatedPANAction from "../slices/adminReletedPan/delete"
import postLinkedReationActon from "../slices/linkedRelationship"
import patchEditBusinessAction  from "../slices/adminEditBusiness";
import patchEditUserAction  from "../slices/adminUserEdit";
import deleteEditReceivedRatingAction from "../slices/adminUserDelete"
import editReceivedRatingAction  from "../slices/adminEditRecievedRatings";
    
export default combineReducers({
    loginUserDetails,
    globalConfigData,
    registrationData,
    searchPartnersData,
    myPartnerData,
    getMyPartnerData,
    getMyPartnerRatingsData,
    getMyRatingData,
    getRaiseDispute,
    postRaiseDispute,
    profileData,
    updateProfileData,
    putResolvedDispute,
    getMyNotificationsAction,
    putNotificationsAction,
    postPaymentDetails,

    postPasswordResetAction,

    gstdetailsData,
    getSubscriptionData,
    isSubscriptionActive,
    forgotPasswordReducer,
    verifyForgotPasswordReducer,
    sendOtpReducer,
    verifyOtpReducer,
    loginWithOtpReducer,

    getAdminDashboardAction,
    getUserSearchAction,
    getadminPaymentsAction,
    patchAdminPaymentsAction,
    deleteadminPaymentsAction,
    getUserSearchByLocation,
    getadminInctiveUsersAction,
    getRelatedPANDetailsAction,
    updateRelatedPANStatusAction,
    deleteRelatedPANAction,
    postLinkedReationActon,
    patchEditBusinessAction,
    patchEditUserAction,
    deleteEditReceivedRatingAction,
    editReceivedRatingAction
});
