
import sliceCreator from '../index.js';
import {GLOBAL_CONFIG} from "../../../constants"
const _globalConfigAction = sliceCreator('GLOBAL_CONFIG_ACTION', GLOBAL_CONFIG, 'get');

const { asyncAction: globalConfigAction, reducer, clearData: clearGobalConfigData } = _globalConfigAction;

export default reducer;

export {
    globalConfigAction,
    clearGobalConfigData
}


