import React from "react";
import '../../styles/scoreDetails.css'
import PropTypes from "prop-types"

const Reachability = ({reachability,customers}) => {

  return (
    <>
    
      <div className="styleforheading">Reachability</div>
      <div className="main">
  {customers === 0 ? (
    <h5>No ratings yet</h5>
  ) : (
    <h5>
      {Math.round(reachability)}% from {customers} {customers === 1 ? 'Customer' : 'Customers'}
      
    </h5>
  )}
</div>

    </>
  );
};

export default Reachability;


Reachability.propTypes = {
  reachability: PropTypes.string.isRequired,
  customers:PropTypes.string.isRequired
}