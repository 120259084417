
import * as Yup from "yup";
export const newcustomer = Yup.object({
  name: Yup.string().required("Please enter name*"),
  city: Yup.string().required("Please enter city*"),
  email: Yup.string().when("skipEmail", (skipEmail, schema) => {
    return skipEmail[0] ? schema : schema.required("Email is Required").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,"Enter a Valid Email");

  }),
  businessname: Yup.string().required("Please enter your business name*"),
  phoneNumber: Yup.string()
  .matches(/^\d{10}$/, 'Phone number should be exactly 10 digits')
  .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
  .matches(/^\d*$/, 'Phone number should not contain alphabetic characters')
  .required('Please enter a valid 10-digit phone number'),

  pan: Yup.string().length(10).required("Please enter your PAN*"),

  confirmPanNumber: Yup.string()
    .oneOf([Yup.ref('pan'), null], 'PAN numbers must match') 
    .required('Please confirm your PAN*'),

  industry: Yup.object().shape({
    value: Yup.number().required('Please select Industry'),
    label: Yup.string().required()
  }),
  company: Yup.object({
    value: Yup.number().required('Please select Company'),
    label: Yup.string().required()
  }).required('Please select Company'),
  partner: Yup.object({
    value: Yup.number().required('Please select Partner'),
    label: Yup.string().required()
  }).required('Please select Partner'),
  district: Yup.object({
    value: Yup.string().required('Please select District'),
    label: Yup.string().required()
  }).required('Please select District'),
  state: Yup.object({
    value: Yup.string().required('Please select State'),
    label: Yup.string().required()
  }).required('Please select State'),
  
  date: Yup.date()
  .required('Date of Incorporation is required'),
});
