import React, { useState,useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar";
import {
  clearRegistrationData,
  globalConfigAction,
  registrationAction,
  getProfileAction,
} from "../../store/actions";
import {
  dispatchAll,
  getDistricts,
  getLableAndValuesFromData,
  getStates,
} from "../../utils";
import "./newcustomer.scss";
import { newcustomer } from "./validationfornewcustomer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import {SITE_KEY} from "../../constants/index"
import PlanUpgradeModel from "../../components/CustomModals/PlanUpgradeModel"



function Newcustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyTypes, setCompanyTypes] = useState([]);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaChecked,setCaptchaChecked] = useState(false)
  // const [partnerData, setPartnerData] = useState(location.state);
  const [showPlanModal, setShowPlanModal] = useState(false);
  
  const {  successData} =
    useSelector((state) => state.globalConfigData) || {};
  const {
    error: registrationError,
    errorInfo: registrationErrorInfo,
    successData: registrationSuccessData,
  } = useSelector((state) => state.registrationData) || {};


  const { successData:profileData } = useSelector(state => state.profileData);

  useEffect(() => {
    dispatch(getProfileAction());
}, [dispatch]);




  const initialValues = {
    state: {},
    district: {},
    pan: "",
    confirmPanNumber: "",
    date: "",
    businessname: "",
    company: {},
    industry: {},
    partner: {},
    phoneNumber: "",
    email: "",
    city: "",
    name: "",
    skipEmail: false,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: newcustomer,
    onSubmit: (values) => {
      
      if (!captchaValue) {
        setCaptchaChecked(true);
        toast.error("Please complete the reCAPTCHA verification");
        return;
      }
      
      const userData = {
        user: {
          email: values.skipEmail ? "NA" : values.email,
          user_type: values.partner.value,
          first_name: values.name,
          pan_number: values.pan,
          phone_number: values.phoneNumber,
          captcha: captchaValue 

        },
        business: {
          industry: values.industry.value,
          company: values.company.value,
          name: values.businessname,
          pan_number: values.pan,
          city: values.city,
          incorporation_date: values.date,
          state: values.state.value,
          district: values.district.value,
        },
      };

      if (values.skipEmail === true) {
        delete userData.user.email;
      }
      if (values.date === "") {
        delete userData.business.incorporation_date;
      }
      dispatch(registrationAction(userData));
    },
  });

  React.useEffect(() => {
    return () => dispatchAll([clearRegistrationData], dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  


  useEffect(() => {
    
    if (registrationSuccessData?.success) {
      toast.success("Customer Details added successfully");
      if (profileData.premium_type === 1) {
        if (values.state.value === profileData.business.state) {
          console.log("profileData", profileData);
          console.log("values", values.state.value);
          console.log("registrationSuccessData", registrationSuccessData.user);
          navigate("/moredetails", {
            state: {
              id: registrationSuccessData["data"]["user"]["id"],
              is_business_exists: false,
            },
            replace: false,
          });
        } else {
          setShowPlanModal(true);
        }
      } else if (profileData.premium_type === 2 || profileData.premium_type === 3) {
        navigate("/moredetails", {
          state: {
            id: registrationSuccessData["data"]["user"]["id"],
            is_business_exists: false,
          },
          replace: false,
        });
      } else {
        setShowPlanModal(true);
      }
    }
  }, [registrationSuccessData, navigate, profileData]);
  

useEffect(() => {
    if (registrationError) {
      toast.error(registrationErrorInfo);
      dispatch(clearRegistrationData());
    }
  }, [registrationError, registrationErrorInfo, dispatch]);
   
  useEffect(() => {
    if (successData) {
      setCompanyTypes(getLableAndValuesFromData(successData.company_type));
      setIndustryTypes(getLableAndValuesFromData(successData.industry_type));
      setPartnerTypes(getLableAndValuesFromData(successData.partner_type));
    }
  }, [successData]);

  const onChangeDropDown = (selectedKey, selectedValue) => {
    if (selectedKey === "state") {
      values.district = {};
    }
    setFieldValue(selectedKey, selectedValue);
  };

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaChecked(false)
  };

  const handleCloseModal = () => {
    navigate("/dashboard")
    setShowPlanModal(false);
  };

  return (
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <Sidebar />

        <PlanUpgradeModel
        show={showPlanModal}
        handleClose={handleCloseModal}
      />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={8}>
          {/* <div className='col-md-12'> */}
          <Grid container spacing={1} position="relative">
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              ml={2}
              style={{ justifyContent: "center" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h5" align="center">New Customer Details</Typography>
              </Grid>
              <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              position="absolute"
              top={0}
              right={0}
              m={2}
            >
              <Link to="/dashboard">
                <FontAwesomeIcon icon={faTimes} />
              </Link>
            </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              ml={2}
              style={{ alignItems: "center" }}
            >
              <form
                className="row"
                style={{ width: "100%" }}
                onSubmit={handleSubmit}
              >
                {/* <Paper variant="outlined" elevation={6}> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mb={1}
                  mt={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="h5">Business Details</Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={2}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="businessname"
                      value={values.businessname}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      onBlur={handleBlur}
                      className="form-control"
                      id="businessname"
                      placeholder="Business Name"
                    />

                    {errors.businessname && touched.businessname ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.businessname}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="industry" className="form-label">
                      Industry
                    </label>
                    <Select
                      defaultValue={values.industry}
                      value={values.industry}
                      name="industry"
                      id="industry"
                      onChange={(selectedIndustry) =>
                        setFieldValue("industry", selectedIndustry)
                      }
                      options={industryTypes}
                    />
                    {errors.industry && touched.industry ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.industry.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="companyType" className="form-label">
                      Company Type
                    </label>
                    <Select
                      defaultValue={values.company}
                      name="company"
                      id="company"
                      value={values.company}
                      onChange={(selectedCompany) =>
                        setFieldValue("company", selectedCompany)
                      }
                      options={companyTypes}
                    />
                    {errors.company && touched.company ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.company.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="companyType" className="form-label">
                      Partner Type
                    </label>
                    <Select
                      defaultValue={values.partner}
                      name="partner"
                      id="partner"
                      value={values.partner}
                      onChange={(selectedPartner) =>
                        setFieldValue("partner", selectedPartner)
                      }
                      options={partnerTypes}
                    />
                    {errors.partner && touched.partner ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.partner.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="panNumber" className="form-label">
                      Pan Number
                    </label>
                    <input
                      type="text"
                      name="pan"
                      value={values.pan}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      onBlur={handleBlur}
                      className="form-control"
                      id="pan"
                      placeholder="Pan"
                    />
                    {errors.pan && touched.pan ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.pan}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
              <label htmlFor="confirmPanNumber" className="form-label">
                Confirm PAN Number
              </label>
              <input
                type="text"
                name="confirmPanNumber"
                value={values.confirmPanNumber}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                onBlur={handleBlur}
                className="form-control"
                id="confirmPanNumber"
                placeholder="Confirm Pan Number"
              />{errors.confirmPanNumber && touched.confirmPanNumber ? (
                <p
                  className="form-error remove-margin"
                  style={{ color: "red" }}
                >
                  {errors.confirmPanNumber}
                </p>
              ) : null}
              </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="panNumber" className="form-label">
                      Date Of Incorporation
                    </label>
                    <input
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      id="date"
                      placeholder=""
                    />

                    {errors.date && touched.date ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.date}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}></Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}></Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mb={1}
                  mt={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="h5">Owner Details</Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={2}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      onBlur={handleBlur}
                      className="form-control"
                      id="name"
                      placeholder="Name"
                    />
                    {errors.name && touched.name ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.name}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={"90%"}
                      >
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          {" "}
                          Email ID
                        </label>
                        <div>
                          <label htmlFor="skipEmail">Skip Email?</label>
                          <input
                            style={{ marginLeft:"10px"}}
                            type="checkbox"
                            id="skipEmail"
                            name="skipEmail"
                            value={values.skipEmail}
                            onChange={handleChange}
                            
                          />
                        </div>
                      </Grid>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        autoComplete="off"
                        value={values.skipEmail ? "NA" : values.email}
                        id="email"
                        placeholder="Email ID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={values.skipEmail}
                      />
                      {errors.email && touched.email ? (
                        <p className="form-error error-message remove-margin">
                          {errors.email}
                        </p>
                      ) : null}
                    </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="phoneNumber" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Mobile Number"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      id="phoneNumber"
                      onInput={(e) => {
                        // Use a regular expression to remove non-numeric characters
                        e.target.value = e.target.value.replace(/^\D/g, "");
                      }}
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.phoneNumber}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <Select
                      defaultValue={values.state}
                      name="state"
                      id="state"
                      value={values.state}
                      onChange={(selectedState) =>
                        onChangeDropDown("state", selectedState)
                      }
                      options={getStates()}
                    />
                    {errors.state && touched.state ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.state.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="state" className="form-label">
                      District
                    </label>
                    <Select
                      defaultValue={values.district}
                      name="district"
                      id="district"
                      value={values.district}
                      onChange={(selectedDistrict) =>
                        onChangeDropDown("district", selectedDistrict)
                      }
                      options={
                        values.state.value
                          ? getDistricts(values.state.value)
                          : []
                      }
                    />
                    {errors.district && touched.district ? (
                      <p className="form-error error-message" style={{ color: "red" }}>
                        {errors.district.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} mr={2}>
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      onBlur={handleBlur}
                      className="form-control"
                      id="city"
                      placeholder="City"
                    />
                    {errors.city && touched.city ? (
                      <p className="form-error error-message" style={{ color: "red" }}>{errors.city}</p>
                    ) : null}
                  </Grid>
                  <Grid item margin={2}>
                      <ReCAPTCHA
                        sitekey={SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                      {captchaChecked && <p style={{color:'red'}}>please complete the captcha*</p>}
                    </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={4}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                    >
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
  );
};

export default Newcustomer;
