import { Box } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import SideBar from "../../components/sidebar";
import Grid from "@mui/material/Grid";
import {
  clearMyPartnerActionData,
  myPartnerAction
} from "../../store/actions";
import { canEnableField,dispatchAll } from "../../utils";
import DropdownItems from "../../utils/dropdownutils";
import { moredetails } from "../moredetails/schemaformoredetails";
import "./moredetails.scss";
const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [partnerData] = React.useState(location.state);
  const {
    successData: ratingData,
    error: ratingError,
    errorInfo: ratingErrorInfo,
  } = useSelector((state) => state.myPartnerData) || {};

  React.useEffect(() => {
    if (ratingData) {
      navigate("/dashboard");
      toast.success("Rating added to customer successfully");
    }
  }, [ratingData,navigate]);

  React.useEffect(() => {
    if (ratingError) {
      toast.error(ratingErrorInfo);
    }
  }, [ratingError,ratingErrorInfo]);
  React.useEffect(() => {
    return () => dispatchAll([clearMyPartnerActionData], dispatch);
  }, [dispatch]);
  const initialValues = {
    score: {},
    totaloutstanding: {},
    ontimepayment: {},
    reachability: {},
    recoveryprobability: {},
    since: "",
    comments: "",
    dso: {},
    pendingrecievable: {},
    // gst: {},
    is_gst_paid: {},
    capital: {}, 
    asset: {},
    legal: {},
    transactionPaidOnTime: {},
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: moredetails,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const payload = {
        score: values.score.value,
        total_outstanding: values.totaloutstanding.value,
        on_time_Payment: values.ontimepayment.value,
        reachability: values.reachability.value,
        recovery_probability: values.recoveryprobability.value,
        comments: values.comments,
        dso: values.dso.value,
        is_business_exists: partnerData.is_business_exists,
        known_since: values.since,
        pending_receivables: values.pendingrecievable.value,
        business_partner_assoc: partnerData.id,
        
        is_gst_paid: values.is_gst_paid.value,
        capital: values.capital.value,
        assets: values.asset.value,
        legal_proceedings: values.legal.value,
        transaction_paid_ontime: values.transactionPaidOnTime.value,
      };
      payload.known_since === "" && delete payload.known_since;
      dispatch(myPartnerAction(payload));
    },
  });

  return (
   
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <SideBar />

        <Box component="main" sx={{ flexGrow: '1', p: 3 }} mt={0}>





          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: '5%',
            overflow:'hidden'


          }}>
            <div style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "#f0f0f0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: '10%',
              maxWidth: '90%',
              marginTop: '5px',
              minHeight: '80vh'
            }}>
              <Grid container item xs={12} sm={12} md={12} lg={12} ml={2}>

                <div className="">
                  <h5 className="card-title text-center">
                    <strong>Rate My Partner</strong>
                  </h5>

                  <form onSubmit={handleSubmit}>
                    <div className="row g-3  m-5">
                      <div className="col-md-12 text-center">
                        <div
                          className="row text-center"
                          style={{ marginTop: "-0px", marginBottom: "40px" }}
                        >
                          <div className="col-md-4  text-md-right">
                            <label htmlFor="score" className="form-label scorestyle">
                              Score 
                            </label>
                          </div>
                          <div className="col-md-4">
                            <Select
                              defaultValue={values.score}
                              value={values.score}
                              name="score"
                              id="score"
                              onChange={(selectedScore) =>
                                setFieldValue("score", selectedScore)
                                
                              }
                              options={DropdownItems.score}
                            />
                          </div>
                        </div>

                        {errors.score && touched.score ? (
                          <p className="form-error" style={{ color: "red" }}>
                            {errors.score.value}
                          </p>
                        ) : null}
                      </div>

                      <div className={`col-md-3 ${!canEnableField(values.score, "totaloutstanding")
                          ? "d-none"
                          : ""
                        }`}>
                        <label htmlFor="totaloutstanding" className="form-label">
                          Current Outstanding
                        </label>
                        <Select
                          defaultValue={values.totaloutstanding}
                          value={values.totaloutstanding}
                          name="totaloutstanding"
                          id="totaloutstanding"
                          onChange={(selectedtotaloutstanding) =>
                            setFieldValue(
                              "totaloutstanding",
                              selectedtotaloutstanding
                            )
                          }
                          options={DropdownItems.totaloutstanding}
                        />
                        {errors.totaloutstanding && touched.totaloutstanding ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.totaloutstanding.value}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "dso") ? "d-none" : ""
                          }`}
                      >
                        <label htmlFor="dso" className="form-label">
                          Days Since Outstanding
                        </label>
                        <Select
                          defaultValue={values.dso}
                          value={values.dso}
                          name="dso"
                          id="dso"
                          onChange={(selecteddso) =>
                            setFieldValue("dso", selecteddso)
                          }
                          options={DropdownItems.dso}
                        />
                        {errors.dso && touched.dso ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.dso.value}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "pendingrecievable")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="pendingrecievable" className="form-label">
                          Pending Receivable
                        </label>
                        <Select
                          defaultValue={values.pendingrecievable}
                          value={values.pendingrecievable}
                          name="pendingrecievable"
                          id="pendingrecievable"
                          onChange={(selectedpendingrecievable) =>
                            setFieldValue(
                              "pendingrecievable",
                              selectedpendingrecievable
                            )
                          }
                          // options={DropdownItems.pendingrecievable}
                          options={[
                            // Only include the placeholder if no value has been selected
                            ...(values.pendingrecievable ? [] : [{ value: '', label: 'Select an option...', disabled: true }]),
                            ...DropdownItems.pendingrecievable
                          ]}
                        />
                        {errors.pendingrecievable && touched.pendingrecievable ? (
                          <p className="form-error error-message remove-margin remove-position  checkbox-error-message">
                            {errors.pendingrecievable.value}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={`col-md-3  ${!canEnableField(values.score, "since")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="since" className="form-label">
                          Since
                        </label>
                        <input
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          name="since"
                          value={values.since}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          id="since"
                          placeholder="since"
                        />
                        {errors.since && touched.since ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.since}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "legal")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="Capital / Assest worth ?" className="form-label">
                          Legal Proceedings
                        </label>
                        <Select
                          defaultValue={values.legal}
                          value={values.legal}
                          name="legal"
                          id="legal"
                          onChange={(selectedlegal) =>
                            setFieldValue("legal", selectedlegal)
                          }
                          options={DropdownItems.legal}
                        />
                          {errors.legal && touched.legal ? (
                        <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                          {errors.legal.value}
                        </p>
                      ) : null}
                      </div>
                    
                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "reachability")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="reachability" className="form-label">
                          Reachability{" "}
                        </label>
                        <Select
                          defaultValue={values.reachability}
                          value={values.reachability}
                          name="reachability"
                          id="reachability "
                          onChange={(selectedreachability) =>
                            setFieldValue("reachability", selectedreachability)
                          }
                          options={DropdownItems.reachability}
                        />
                        {errors.reachability && touched.reachability ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.reachability.value}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "recoveryprobability")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="recoveryprobability" className="form-label">
                          Recovery probability
                        </label>
                        <Select
                          defaultValue={values.recoveryprobability}
                          value={values.recoveryprobability}
                          name="recoveryprobability"
                          id="recoveryprobability "
                          onChange={(selectedrecoveryprobability) =>
                            setFieldValue(
                              "recoveryprobability",
                              selectedrecoveryprobability
                            )
                          }
                          options={DropdownItems.recoveryprobability}
                        />
                        {errors.recoveryprobability && touched.recoveryprobability ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.recoveryprobability.value}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className={`col-md-3 top-height ${!canEnableField(values.score, "percentages")
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor="ontimepayment" className="form-label">
                          On-time Payment
                        </label>
                        <Select
                          defaultValue={values.ontimepayment}
                          value={values.ontimepayment}
                          name="ontimepayment"
                          id="ontimepayment"
                          onChange={(selectedontimepayment) =>
                            setFieldValue("ontimepayment", selectedontimepayment)
                          }
                          options={DropdownItems.percentages}
                        />
                        {errors.ontimepayment && touched.ontimepayment ? (
                          <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                            {errors.ontimepayment.value}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className={`col-md-3 top-height ${!canEnableField(
                          values.score,
                          "transactionPaidOntime"
                        )
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <label htmlFor=" transactionpaidontime" className="form-label">
                          Transaction paid on time
                        </label>
                        <Select
                          defaultValue={values.transactionPaidOnTime}
                          value={values.transactionPaidOnTime}
                          name=" transactionpaidontime"
                          id=" transactionpaidontime"
                          onChange={(selectedTransactionPaidOnTime) =>
                            setFieldValue(
                              "transactionPaidOnTime",
                              selectedTransactionPaidOnTime
                            )
                          }
                          options={DropdownItems.transactionpaidontime}
                        />
                        {errors.transactionPaidOnTime && touched.transactionPaidOnTime ? (
                  <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                    {errors.transactionPaidOnTime.value}
                  </p>
                ) : null}
                     </div>
     
                     <div
                               className={`col-md-3 top-height ${
                                 !canEnableField(values.score, "gst") ? "d-none" : ""
                               }`}
                             >
                       <label htmlFor="GST" className="form-label">
                         GST Paid on Time
                       </label>
                     {/* <Select
                         defaultValue={values.gst}
                         value={values.gst}
                         name="gst"
                         id="gst"
                         onChange={(selectedgst) =>
                           setFieldValue("gst", selectedgst)
                         }
                         options={DropdownItems.GST}
                       />  */}
                       <Select
                         defaultValue={values.is_gst_paid}
                         value={values.is_gst_paid}
                         name="is_gst_paid"
                         id="is_gst_paid"
                         onChange={(selectedgst) =>
                           setFieldValue("is_gst_paid", selectedgst)
                         }
                         options={DropdownItems.GST}
                        />
                        {errors.is_gst_paid && touched.is_gst_paid ? (
                          <p className="form-error" style={{ color: "red" }}>
                            {errors.is_gst_paid.value}
                          </p>
                        ) : null}
                        
                    
                     </div>
     
                     <div
                               className={`col-md-3 top-height ${
                                 !canEnableField(values.score, "capital")
                                   ? "d-none"
                                   : ""
                               }`}
                               >
                       <label htmlFor="Capital " className="form-label">
                         Capital worth{" "}
                       </label>
                       <Select
                         defaultValue={values.capital}
                         value={values.capital}
                         name="capital"
                         id="capital"
                         onChange={(selectedcapital) =>
                           setFieldValue("capital", selectedcapital)
                         }
                         options={DropdownItems.capital_or_asseets}
                       />
                       {errors.capital && touched.capital ? (
                  <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                    {errors.capital.value}
                  </p>
                ) : null}
                     </div>
                     <div
                               className={`col-md-3 top-height ${
                                 !canEnableField(values.score, "assertworth")
                                   ? "d-none"
                                   : ""
                               }`}
                               >
                       <label htmlFor="assets " className="form-label">
                         Assests worth{" "}
                       </label>
                       <Select
                         defaultValue={values.asset}
                         value={values.asset}
                         name="asset"
                         id="asset"
                         onChange={(selectedassertworth) =>
                           setFieldValue("asset", selectedassertworth)
                         }
                         options={DropdownItems.capital_or_asseets}
                       />
                       {errors.asset && touched.asset ? (
                  <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                    {errors.asset.value}
                  </p>
                ) : null}
                     </div>
                  
     
                     <div className={`col-md-6 top-height ${(!values.score.value || values.score.value >= 9) && 'mx-auto'}`}>
                       <label htmlFor="comments" className="form-label">
                         Comments
                       </label>
                       <textarea
                         type="text"
                         name="comments"
                         value={values.comments}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         className="form-control"
                         id="comments"
                         placeholder="Comments"
                         style={{ height: '38px' }}
                       />
                       {errors.comments && touched.comments ? (
                  <p className="form-error error-message remove-margin remove-position checkbox-error-message">
                    {errors.comments}
                  </p>
                ) : null}
                     </div>
     
                     <div className="text-center col-md-12 mt-3 ">
                       <button
                         type="submit"
                         className="btn btn-primary next-button-ratemycustomer"
                       >
                         Submit
                       </button>
                     </div>
                   </div>
                 </form>
               </div>
           </Grid>
  </div>
  </div>
  </Box>
 
    
      </Box>
   
  );
};

export default Index;
