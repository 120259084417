import sliceCreator from '../index.js';
import {ADMIN_DASHBOARD} from "../../../constants"

const _getAdminDashboardAction = sliceCreator('ADMIN_DASHBOARD', ADMIN_DASHBOARD, 'get');

const { asyncAction: getAdminDashboardAction, reducer, clearData: clearGetAdminDashboardActionData } = _getAdminDashboardAction;

export default reducer;

export {
    getAdminDashboardAction,
    clearGetAdminDashboardActionData
}
