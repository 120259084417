import React from "react";
import "./Footer.scss";

const index = () => {
  return (
    <footer className="footer" >
      <div className="copyright">
        <p>
          &copy; Copyrights{" "}
          <strong>
            <span>ClearBiz</span>
          </strong>{" "}
          All Rights Reserved.
        </p>
        <p>A Brand of CLEARBIZ SOFTWARE SOLUTIONS PRIVATE LIMITED</p>
      </div>
    </footer>
  );
};

export default index;
