import React, { useState } from "react";
import "./Partnerdetails.scss";
import * as Yup from "yup";
import SideBar from "../../components/sidebar";
import Ratingcard from "../../components/ratingcard";
import { Formik, Form, Field } from "formik";
import PeopleIcon from "@mui/icons-material/People";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getMyPartnerRatingsAction,
  clearGetMyPartnerRatingsActionData,postLinkedReationActon
} from "../../store/actions";
import { toast } from "react-toastify";
import { dispatchAll, getOverallDetailsOfUser } from "../../utils";


import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  IconButton,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import Comments from "../../components/comments";
import OutstandingLoans from "../../components/outstandings";
import PendingReceivables from "../../components/pendingReceivables";
import LegalProceedings from "../../components/legalProceedings";
import Reachability from "../../components/reachability";
import RecoveryProbability from "../../components/recoveryProbability";
import IsGSTPaid from "../../components/isgstpaid";
import CapitalWorth from "../../components/capitalWorth";
import AssetWorth from "../../components/assetWoth";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const [selectedAssocPartner] = React.useState(
    location.state.selectedAssocPartner
  );
  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: {
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
      5: 0.0,
      6: 0.0,
      7: 0.0,
      8: 0.0,
      9: 0.0,
      10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });
  const { successData, error, errorInfo } =useSelector((state) => state.getMyPartnerRatingsData) || {};
  const business_partner_assoc_id = selectedAssocPartner?.id;
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [outStanding, setOutStanding] = React.useState("");
  const [pendingReceivables, setPendingReceivables] = React.useState("");
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [dsoAverageDays, setDsoAverageDays] = React.useState(0);
  const [legalProceedings, setLegalProceedings] = React.useState(0);
  const [reachability, setReachability] = React.useState(0);
  const [reachabilityCustomerCount, setReachabilityCustomerCount] =
    React.useState(0);
  const [comments, setComments] = React.useState([
    {
      heading: "",
      comment: "",
    },
  ]);
  const [recoveryProbability, setRecoveryProbability] = React.useState([
    {
      yes: 0,
      yes_with_multiple_attempts: 0,
      no: 0,
      difficult: 0,
    },
  ]);
  const [assetWorth, setAssetWorth] = React.useState([
    {
      Yes: 0,
      Moderate: 0,
      No: 0,
    },
  ]);

  const [capitalWorth, setCapitalWorth] = React.useState([
    {
      Y: 0,
      M: 0,
      N: 0,
    },
  ]);
  const [gstPaid, setGstPaid] = React.useState([
    {
      yes: 0,
      no: 0,
    },
  ]);

  React.useEffect(() => {
    return () => dispatchAll([clearGetMyPartnerRatingsActionData], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(
      getMyPartnerRatingsAction({
        endPoint: `getPartnerRatings/?partner_id=${business_partner_assoc_id}`,
      })
    );
  }, [dispatch, business_partner_assoc_id]);

  React.useEffect(() => {
    if (successData) {
      // Define a mapping of parameters to values for total outstanding
      const parameterToValue = {
        0: 0,
        1: 100000,
        2: 350000,
        3: 750000,
        4: 1500000,
        5: 3500000,
        6: 7500000,
        7: 10000000,
      };

      // parameter for dso value
      const dosParameter = {
        0: 0,
        1: 30,
        2: 60,
        3: 90,
        4: 180,
        5: 365,
        6: 730,
      };
      // Initialize reachabilityCustomerCount to 0
      let reachabilityCustomerCount = 0;
      let noOfLegalProceeding = 0;
      let reachabilityPercentage = 0;

      // Count the number of "Yes" values in the filtered data
      const yesCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null) {
          if (parameter === 0) {
            return count + 1;
          }
        }
        return count;
      }, 0);

      // Count the number of "Yes" values in the filtered data
      const difficultCount = successData.reduce((count, data) => {
        const parameter = data["reachability"];
        if (parameter !== null) {
          if (parameter === 0) {
            return count + 1;
          }
        }
        return count;
      }, 0);

      // Calculate the reachabilityCustomerCount
      successData.forEach((data) => {
        if (data["reachability"] !== null) {
          reachabilityCustomerCount++;
        }
      });

      if (yesCount) {
        // Calculate the reachability percentage
        reachabilityPercentage = (yesCount / reachabilityCustomerCount) * 100;
      } else if (difficultCount) {
        // Calculate the reachability percentage
        reachabilityPercentage =
          (difficultCount / reachabilityCustomerCount) * 50;
      }

      const totalDso = successData.reduce((sum, data) => {
        const parameter = data["dso"];
        return sum + dosParameter[parameter];
      }, 0);

      // dso average days
      const dsoAverageDays = totalDso / successData.length;

      // current total oustanding of all customers
      const totalSumOustanding = successData.reduce((sum, data) => {
        const parameter = data["total_outstanding"];

        if (parameter === 0) return sum;
        return sum + parameterToValue[parameter];
      }, 0);

      // calculating total customer
      const totalCustomer = successData.length;

      // current total recievable of all customers
      const totalSumRecievable = successData.reduce((sum, data) => {
        const parameter = data["pending_receivables"];
        if (parameter === 0) return sum;
        return sum + parameterToValue[parameter];
      }, 0);

      // this is used for comment exctracting all feilds it include heading and comment
      successData.map((data) => {
        const userType = data["business_partner_main"]["user_type"]["name"];
        const industryType =
          data["business_partner_main"]["business"]["industry"]["name"];
        const location =
          data["business_partner_main"]["business"]["city"] +
          "," +
          data["business_partner_main"]["business"]["state"];
        const heading = `${industryType} ${userType} from ${location} `;
        const comment = data["comments"];

        if (comment && heading) {
          setComments((prevComment) => {
            return [...prevComment, { heading: heading, comment: comment }];
          });
        }
        return "";
      });

      successData.forEach((data) => {
        const legalProceeding = data["legal_proceedings"];
        if (legalProceeding) {
          noOfLegalProceeding++;
        }
      });

      setLegalProceedings(noOfLegalProceeding);

      // here we find the recovery probability of all customers
      successData.forEach((data) => {
        const parameter = data["recovery_probability"];
        if (parameter !== null) {
          setRecoveryProbability((prevRecovery) => {
            const newRecovery = [...prevRecovery];
            const lastItem = newRecovery[newRecovery.length - 1];

            if (parameter === 0) {
              lastItem.yes += 1;
            } else if (parameter === 1) {
              lastItem.yes_with_multiple_attempts += 1;
            } else if (parameter === 2) {
              lastItem.difficult += 1;
            } else if (parameter === 3) {
              lastItem.no += 1;
            }

            return newRecovery;
          });
        }
      });

      // here we find the gstpaid review of all customers
      successData.forEach((data) => {
        const parameter = data["assets"];
        if (parameter !== null) {
          setAssetWorth((prevAsset) => {
            const newAsset = [...prevAsset];
            const lastItem = newAsset[newAsset.length - 1];

            if (parameter === 0) {
              lastItem.Yes += 1;
            } else if (parameter === 1) {
              lastItem.Moderate += 1;
            } else if (parameter === 2) {
              lastItem.No += 1;
            }
            return newAsset;
          });
        }
      });

      successData.forEach((data) => {
        const parameter = data["capital"];
        if (parameter !== null) {
          setCapitalWorth((prevAsset) => {
            const newAsset = [...prevAsset];
            const lastItem = newAsset[newAsset.length - 1];

            if (parameter === 0) {
              lastItem.Y += 1;
            } else if (parameter === 1) {
              lastItem.M += 1;
            } else if (parameter === 2) {
              lastItem.N += 1;
            }
            return newAsset;
          });
        }
      });

      // here we find the gstpaid review of all customers
      successData.forEach((data) => {
        const parameter = data["is_gst_paid"];
        if (parameter !== null) {
          setGstPaid((prevGST) => {
            const newGST = [...prevGST];
            const lastItem = newGST[newGST.length - 1];

            if (parameter === true) {
              lastItem.yes += 1;
            } else if (parameter === false) {
              lastItem.no += 1;
            }
            return newGST;
          });
        }
      });

      // setting the values which gathered

      setOutStanding(totalSumOustanding);
      setPendingReceivables(totalSumRecievable);
      setTotalCustomer(totalCustomer);
      setDsoAverageDays(dsoAverageDays);
      setReachability(reachabilityPercentage);
      setReachabilityCustomerCount(reachabilityCustomerCount);
      setPartnersRatings([...successData]);
      setOverallDetails({ ...getOverallDetailsOfUser(successData) });
    }
  }, [successData]);

  React.useEffect(() => {
    if (error) {
      toast.success(errorInfo);
    }
  }, [error, errorInfo]);

  const handleClose = () => {
    setShow(false);
  };

 const familyRelations = [
    { value: "Spouse", label: "Spouse" },
    { value: "Partner", label: "Partner" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Sibling", label: "Sibling" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Aunt/Uncle", label: "Aunt/Uncle" },
    { value: "Cousin", label: "Cousin" },
    { value: "In-Law", label: "In-Law" }
];


  const validationSchema = Yup.object({
    panno: Yup.string().required("PAN No. is required")
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/, 'Invalid PAN number'),
    confirmpanno: Yup.string()
    .oneOf([Yup.ref('panno'), null], 'PAN numbers must match')
    .required("Please confirm your PAN number"), 
    name: Yup.string().required("Name is required"),
    relation: Yup.string().required("Relation is required"),
  });
  const initialValues = {
    panno: "",
    name: "",
    relation: "",
    confirmpanno: "",
  };

 const handleSubmit =async (values, actions) => {
  try {
    await dispatch(
      postLinkedReationActon({
        user_id_main: business_partner_assoc_id,
        user_pan_assoc: values.panno,
        relationship: values.relation,
        name: values.name,
      
      })
    ).unwrap();
    actions.resetForm();
    toast.success("Successfully requested to add a relation to your partner.")
    setShow(false)
  } catch (err) {
    toast.error(err.message || 'An error occurred');
  }

 };
  const handleChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value.toUpperCase());
  };

  return (
    <>
      {isMobile ? (
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: "1", p: 3 }} mt={8}>
            <Grid container>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  Rating Details of{" "}
                  <span className="customer-name">{`${selectedAssocPartner.business.name}`}</span>
                </h2>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <div style={{ textAlign: "right" }}>
                  <div className="mobileback back">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                        color: "rgb(68, 72, 133)",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      <KeyboardBackspaceIcon /> Back
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              mt={-1}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              xs={12}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="cardone">
                  <PeopleIcon />
                  {`Total Partners : ${overallDetails.totalCustomers}`}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card2">{`Total Business Partners : ${overallDetails.totalPartners}`}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card3">{`Total Number of Disputes : ${partnersRatings?.[0]?.no_of_disputes}`}</div>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={2} lg={12}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <div className="rectangle">
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      Overall Rating for{" "}
                      {`${selectedAssocPartner.business.name}`}
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: deepOrange[500],
                          width: "55px",
                          height: "55px",
                          fontSize: "15px",
                        }}
                      >
                        {overallDetails.avgRating}/10
                      </Avatar>
                    </div>

                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginTop: "5px",
                        fontSize: "20px",
                      }}
                      className="riv"
                    >
                      Total : {partnersRatings.length} Ratings
                    </Typography>
                    <div className="partnerdetailsrating">
                      <Ratingcard
                        overallDetails={overallDetails}
                        partnerName={`for ${selectedAssocPartner.business.name}`}
                        partnersRatings={partnersRatings}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid
                  container
                  lg={8}
                  spacing={4}
                  style={{ marginLeft: "-3px", marginTop: "-0px" }}
                >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <OutstandingLoans
                      outstanding={outStanding}
                      totalCustomer={totalCustomer}
                      dsoAverageDays={dsoAverageDays}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <RecoveryProbability
                      recoveryProbability={recoveryProbability}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <LegalProceedings legalProceedings={legalProceedings} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Reachability
                      reachability={reachability}
                      customers={reachabilityCustomerCount}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <PendingReceivables
                      pendingReceivables={pendingReceivables}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <IsGSTPaid isGSTPaid={gstPaid} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <CapitalWorth capitalWorth={capitalWorth} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <AssetWorth assetWorth={assetWorth} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

             <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={10}>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Add related PAN of{" "}
                    <span className="customer-name">
                      {selectedAssocPartner.business.name}
                    </span>{" "}
                    ?
                  </p>
                  <Button
                    type="button"
                    onClick={() => setShow(true)}
                    variant="contained"
                  >
                    Add
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* comment sections */}
            <Box sx={{ flexGrow: 1 }} mt={1}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                  <Comments comments={comments} />
                </Grid>
              </Grid>
            </Box>

            <div>
              <Grid
                container
                mt={2}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                xs={12}
                spacing={2}
                justifyContent={"space-around"}
              >
                <Grid item xs={8}></Grid>
              </Grid>
            </div>
          </Box>

          <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
            <Box position="relative" sx={{ p: 2 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 8,
                  "&:hover": { backgroundColor: "none", color: "red" },
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                      color: "darkblue",
                      fontSize: isMobile ? "24px" : "30px",
                      fontFamily: "Arial",
                    }}
                  >
                    Add Related Pan Details
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: isMobile ? "100%" : 600,
                      width: isMobile ? "90%" : 400,
                      margin: "auto",
                      padding: isMobile ? 1 : 2,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                    }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    
                    >
                      {({ errors, touched,setFieldValue }) => (
                        <Form className="formik-form">
                          <div className="form-group-relatedform">
                            <label htmlFor="panno">PAN NUMBER </label>
                            <Field
                              placeHolder="Enter Relation PAN Num"
                              className="form-input"
                              name="panno"
                              type="text"
                              maxlength="10"
                               onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.panno && touched.panno ? (
                              <div className="error-message">
                                {errors.panno}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group-relatedform">
                            <label htmlFor="confirmpanno"> Confirm PAN NUMBER </label>
                            <Field
                              placeHolder="Confirm Relation PAN Num"
                              className="form-input"
                              name="confirmpanno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.confirmpanno && touched.confirmpanno ? (
                              <div className="error-message">
                                {errors.confirmpanno}
                              </div>
                            ) : null}
                          </div>
                          
                          <div className="form-group-relatedform">
                            <label htmlFor="name">Name</label>
                            <Field
                              placeHolder="Enter Relation Name"
                              name="name"
                              type="text"
                              className="form-input"
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="relation">Relation</label>
                            <Field
                              name="relation"
                              as="select"
                              className="form-input"
                            >
                              <option value="">Select The RelationShip</option>
                              {familyRelations.map((relation) => (
                                <option
                                  key={relation.value}
                                  value={relation.value}
                                >
                                  {relation.label}
                                </option>
                              ))}
                            </Field>
                            {errors.relation && touched.relation ? (
                              <div className="error-message">
                                {errors.relation}
                              </div>
                            ) : null}
                          </div>

                          <Button sx={{ marginTop:"5px" }}  type="submit">Submit</Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <Box
            component="main"
            minWidth={100}
            sx={{ flexGrow: "1", p: 3 }}
            mt={8}
          >
            <Grid container>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  Rating Details of{" "}
                  <span className="customer-name">{`${selectedAssocPartner.business.name}`}</span>
                </h2>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <div style={{ textAlign: "right" }}>
                  <button
                    onClick={() => navigate(-1)}
                    className="back"
                    style={{
                      backgroundColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      color: "rgb(68, 72, 133)",
                      cursor: "pointer",
                      float: "right",
                    }}
                  >
                    <KeyboardBackspaceIcon /> Back
                  </button>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              mt={-1}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              xs={12}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="cardone">
                  <PeopleIcon />
                  {`Total Partners : ${overallDetails.totalCustomers}`}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card2">{`Total Business Partners : ${overallDetails.totalPartners}`}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {" "}
                <div className="card3">{`Total Number of Disputes : ${partnersRatings?.[0]?.no_of_disputes}`}</div>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={2} lg={12}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <div className="rectangle">
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        textAlign: "center",
                      }}
                    >
                      Overall Rating for{" "}
                      {`${selectedAssocPartner.business.name}`}
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: deepOrange[500],
                          width: "55px",
                          height: "55px",
                          fontSize: "15px",
                        }}
                      >
                        {overallDetails.avgRating}/10
                      </Avatar>
                    </div>

                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginTop: "5px",
                        fontSize: "20px",
                      }}
                      className="riv"
                    >
                      Total : {partnersRatings.length} Ratings
                    </Typography>
                    <div className="partnerdetailsrating">
                      <Ratingcard
                        overallDetails={overallDetails}
                        partnerName={`for ${selectedAssocPartner.business.name}`}
                        partnersRatings={partnersRatings}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid
                  container
                  lg={8}
                  spacing={4}
                  style={{
                    marginLeft: "-3px",
                    marginTop: "-0px",
                    height: "725px",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <OutstandingLoans
                      outstanding={outStanding}
                      totalCustomer={totalCustomer}
                      dsoAverageDays={Math.round(dsoAverageDays)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <RecoveryProbability
                      recoveryProbability={recoveryProbability}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-150px" }}
                  >
                    <LegalProceedings legalProceedings={legalProceedings} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-150px" }}
                  >
                    <Reachability
                      reachability={reachability}
                      customers={reachabilityCustomerCount}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-180px" }}
                  >
                    <PendingReceivables
                      pendingReceivables={pendingReceivables}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-180px" }}
                  >
                    <IsGSTPaid isGSTPaid={gstPaid} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-200px" }}
                  >
                    <CapitalWorth capitalWorth={capitalWorth} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginTop: "-200px" }}
                  >
                    <AssetWorth assetWorth={assetWorth} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={10}>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Add related PAN of{" "}
                    <span className="customer-name">
                      {selectedAssocPartner.business.name}
                    </span>{" "}
                    ?
                  </p>
                  <Button
                    type="button"
                    onClick={() => setShow(true)}
                    variant="contained"
                  >
                    Add
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* comment sections */}

            <Grid container spacing={1} justifyContent={"center"}>
              <Grid item xs={12} sm={10}> 
                <Comments comments={comments} />
              </Grid>
            </Grid>

           <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
            <Box position="relative" sx={{ p: 2 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 8,
                  "&:hover": { backgroundColor: "none", color: "red" },
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                      color: "darkblue",
                      fontSize: isMobile ? "24px" : "30px",
                      fontFamily: "Arial",
                    }}
                  >
                    Add Related Pan Details
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: isMobile ? "100%" : 600,
                      width: isMobile ? "90%" : 400,
                      margin: "auto",
                      padding: isMobile ? 1 : 2,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                    }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    
                    >
                      {({ errors, touched,setFieldValue }) => (
                        <Form className="formik-form">
                          <div className="form-group-relatedform">
                            <label htmlFor="panno">PAN NUMBER </label>
                            <Field
                              placeHolder="Enter Relation PAN Num"
                              className="form-input"
                              name="panno"
                              type="text"
                              maxlength="10"
                               onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.panno && touched.panno ? (
                              <div className="error-message">
                                {errors.panno}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group-relatedform">
                            <label htmlFor="confirmpanno"> Confirm PAN NUMBER </label>
                            <Field
                              placeHolder="Confirm Relation PAN Num"
                              className="form-input"
                              name="confirmpanno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.confirmpanno && touched.confirmpanno ? (
                              <div className="error-message">
                                {errors.confirmpanno}
                              </div>
                            ) : null}
                          </div>
                          
                          <div className="form-group-relatedform">
                            <label htmlFor="name">Name</label>
                            <Field
                              placeHolder="Enter Relation Name"
                              name="name"
                              type="text"
                              className="form-input"
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="relation">Relation</label>
                            <Field
                              name="relation"
                              as="select"
                              className="form-input"
                            >
                              <option value="">Select The RelationShip</option>
                              {familyRelations.map((relation) => (
                                <option
                                  key={relation.value}
                                  value={relation.value}
                                >
                                  {relation.label}
                                </option>
                              ))}
                            </Field>
                            {errors.relation && touched.relation ? (
                              <div className="error-message">
                                {errors.relation}
                              </div>
                            ) : null}
                          </div>

                              <Button sx={{ marginTop:"5px" }} type="submit">Submit</Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Index;
