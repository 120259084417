import React from 'react';
import { Box, Card, CardMedia, CardContent, Typography, Grid, useTheme } from '@mui/material';
import TrustIcon from '@mui/icons-material/ThumbUp'; // Example icon for trust
import CollaborationIcon from '@mui/icons-material/GroupWork'; // Example icon for collaboration
import ReliabilityIcon from '@mui/icons-material/Verified'; // Example icon for reliability

const Pillars = () => {
  const theme = useTheme();
  const pillarsData = [
    {
        title: 'TRUST',
        description: 'Building a foundation of trust leads to lasting partnerships.',
        icon: <TrustIcon fontSize="large" />,
        image: require('../../assets/1111.png') // Adjust the path as necessary
      },
    {
      title: 'COLLABORATION',
      description: 'Effective collaboration fosters innovative solutions.',
      icon: <CollaborationIcon fontSize="large" />,
      image: require('../../assets/222.png') // Replace with path to your image
    },
    {
      title: 'RELIABLE PARTNERS',
      description: 'Reliable partners are key to steady success.',
      icon: <ReliabilityIcon fontSize="large" />,
      image: require('../../assets/333.png') // Replace with path to your image
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(3), textAlign: 'center', backgroundColor: '#0073b1' }}>
    <Typography variant="h4" component="div" sx={{ marginBottom: theme.spacing(4), fontWeight: 'bold', color: '#fff' }}>
      3 Pillars for Successful Business Partnerships
    </Typography>
    <Grid container spacing={theme.spacing(3)} justifyContent="center">
      {pillarsData.map((pillar, index) => (
        <Grid item xs={12} sm={6} md={4} key={pillar.title}>
          <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            height: '100%',
          }}>
            <CardMedia
              component="img"
              image={pillar.image}
              alt={pillar.title}
              sx={{ width: '100%', height: theme.spacing(25), objectFit: 'cover' }}
            />
            <CardContent sx={{ textAlign: 'center', flexGrow: 1, color: '#0073b1' }}>
              {pillar.icon}
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#0073b1', marginTop: theme.spacing(2) }}>
                {pillar.title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'black' }}>
                {pillar.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);
};

export default Pillars;
