   
import sliceCreator from '../index.js';
import {USER_SEARCH} from "../../../constants"

const _getUserSearchAction = sliceCreator('USER_SEARCH', USER_SEARCH, 'get');

const { asyncAction: getUserSearchAction, reducer, clearData: clearGetUserSearchAction } = _getUserSearchAction;

export default reducer;

export {
    getUserSearchAction,
    clearGetUserSearchAction
}