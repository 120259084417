import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha, styled, useTheme } from "@mui/material/styles";
import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import clearbiz2 from "../../assets/clearbiz2.png";
import { navList } from "../../constants";
import {
  clearLoginUserData,
  clearSearchPartnerActionData,
  getMyNotificationsAction,
  searchPartnerAction,
  putNotificationsAction,
} from "../../store/actions";

import { dispatchAll } from "../../utils";
import NotificationDropdown from "../notification";
import "./sidebar.scss";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const drawerWidth = 100;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 0, 1, 1),
    // vertical padding + font size from searchIcon
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.7)', // Customize the color as needed
      opacity: 1, // Ensure the opacity is set to 1 for full intensity
    },
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },

  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Tahoma, sans-serif",
  fontWeight: "bold",
  fontSize: "1.5rem",
  marginRight: theme.spacing(2), 
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1), 
    marginLeft: theme.spacing(1),  
  },
}));

const mobileMenuId = "primary-search-account-menu-mobile";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // Removed open state styles from here
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const { successData: getNotificationData } =
    useSelector((state) => state.getMyNotificationsAction) || {};
  const { successData: putNotificationsActionData } =
    useSelector((state) => state.putNotificationsAction) || {};
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const {
    successData: searchData,
    error: searchError,
    errorInfo: searchErrorInfo,
  } = useSelector((state) => state.searchPartnersData);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const { successData: updateNotificationData } =
    useSelector((state) => state.updateNotificationData) || {};

  const notificationsData = React.useMemo(() => {
    if (getNotificationData) {
      return getNotificationData;
    }
  }, [getNotificationData]);



  React.useEffect(() => {
    if (updateNotificationData) {
      dispatch(updateNotificationData({ endpoint: `notifications/` }));
    }
  }, [updateNotificationData, dispatch]);

 

  const handleNotificationClick = (selectedIndex) => {
    const selectedId = notificationsData[selectedIndex].id;

    dispatch(
      putNotificationsAction({ endPoint: `notifications/${selectedId}/` })
    );
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const openNotification = Boolean(notificationAnchorEl);
  const dummyNotifications = [];

  const textStyleUnread = {
    fontWeight: "bold",
    fontSize: "15px",
  };

  const textStyleRead = {
    fontWeight: "normal",
    fontSize: "15px",
  };

  React.useEffect(() => {
    dispatch(getMyNotificationsAction());
  }, [dispatch]);

 
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (Array.isArray(searchData) && searchData.length === 0) {
      navigate("/noresultssearch");
      dispatch(clearSearchPartnerActionData());
    } else if (searchData) {
      const data = Array.isArray(searchData) ? searchData[0] : searchData;
      // if is_user_exists is true, then it is same as PAN response so setting is_from_gst to false
      const is_from_gst =
        Array.isArray(searchData) && data.is_user_exists
          ? false
          : searchValue.length === 15;
      navigate("/searchprofile", {
        state: { ...data, is_from_gst },
        replace: true,
      });
      dispatch(clearSearchPartnerActionData());
    }
  }, [searchData, dispatch, navigate, searchValue.length]);
  React.useEffect(() => {
    if (searchError) {
      if (searchErrorInfo === "User does not exist. Please register.") {
        toast.error(searchErrorInfo);
      } else {
        navigate("/dashboard");
        toast.error(searchErrorInfo);
      }
      dispatch(clearSearchPartnerActionData());
    }
  }, [searchError, searchErrorInfo, dispatch, navigate]);
  React.useEffect(() => {
    return () => dispatchAll([clearSearchPartnerActionData], dispatch);
  }, [dispatch]);
  const onSearchClick = () => {
    

    if (searchValue.length === 15||searchValue.length === 10) {
      dispatch(
        searchPartnerAction({
          endPoint: `searchPartner/?searchKey=${searchValue}`,
        })
      );
    }
    else(toast.error("Invalid PAN number or GST number."));
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickLogout = () => {
    dispatchAll([clearLoginUserData], dispatch);
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("is_premium_user");
    localStorage.removeItem("is_company");
    localStorage.removeItem("is_subscription_active");
    localStorage.removeItem('lastActivityTime');
    navigate("/");
  };


  const logoutTimeoutRef = useRef(null);

  const resetLogoutTimer = () => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }
    const currentTime = Date.now();
    localStorage.setItem('lastActivityTime', currentTime);
    logoutTimeoutRef.current = setTimeout(() => {
      onClickLogout();
    }, 43200000); // 12 hour of inactivity
  };
  
  const checkInactivity = () => {
    const lastActivityTime = localStorage.getItem('lastActivityTime');
    if (lastActivityTime) {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - parseInt(lastActivityTime, 10);
      if (timeSinceLastActivity > 43200000) {
        onClickLogout();
      } else {
        resetLogoutTimer();
      }
    } else {
      resetLogoutTimer(); // Initialize the timer if no activity time is stored
    }
  };
  
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      checkInactivity();
    }
  };
  
  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    const handleEvent = () => resetLogoutTimer();
  
    events.forEach((event) => window.addEventListener(event, handleEvent));
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Check for inactivity on component mount
    checkInactivity();
  
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleEvent));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (logoutTimeoutRef.current) {
        clearTimeout(logoutTimeoutRef.current);
      }
      localStorage.removeItem('lastActivityTime'); // Clear stored time on logout
    };
  }, []);
  

  
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
         <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton> 
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={(event) => setNotificationAnchorEl(event.currentTarget)}
        >
          <Badge badgeContent={2} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </MenuItem>
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );
  

  const onClickSideMenuItem = (item) => {
    navigate(item.goTo);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ bgcolor: "#457b9d" }}>
        <Toolbar>
          <CustomTypography variant="h6" noWrap component="div">
            <Link to="/dashboard">
              <img
                src={clearbiz2}
                className="clearbiz-img"
                alt="Clearbiz Logo"
              />
            </Link>
          </CustomTypography>

          <Search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.trim())}
            title="Enter search keyword"
          >
            <StyledInputBase
              placeholder="search with GSTIN or PAN"
              inputProps={{ "aria-label": "search", maxLength: 15 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value.trim())}
              onInput={(e) => {
                e.target.value = ("" + e.target.value).toUpperCase().trim();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSearchClick();
                }
              }}
            />
            <SearchIconWrapper>
              <SearchIcon onClick={() => onSearchClick()} />
            </SearchIconWrapper>
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
              size="large"
              aria-label="show 1 new mails"
              color="inherit"
            >
              <Badge badgeContent={0} color="error"> 
                 <MailIcon /> 
              </Badge>
            </IconButton>  */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={(event) => setNotificationAnchorEl(event.currentTarget)}
            >
              <Badge color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popover
              open={openNotification}
              anchorEl={notificationAnchorEl}
              onClose={handleNotificationClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ maxHeight: "60vh" }}
            >
              <div className="notification">
                {notificationsData && notificationsData.length > 0 ? (
                  notificationsData.map((notification, index) => (
                    <div
                      key={notificationsData.id}
                      className="notification"
                      style={{ marginTop: `${index === 0 ? 15 : 5}px` }}
                    >
                      <button
                        onClick={(e) => handleNotificationClick(index)}
                        style={{
                          ...(notification.is_read
                            ? textStyleRead
                            : textStyleUnread),
                          all: "unset", // This removes the default button styles
                          cursor: "pointer", // This makes it clear that the button is clickable
                        }}
                      >
                        {notification.title}
                      </button>
                      <p style={{ marginBottom: "5px" }}>
                        {notification.description}
                      </p>
                      {index < notificationsData.length - 1 && (
                        <hr style={{ marginBottom: "5px" }} />
                      )}
                    </div>
                  ))
                ) : (
                  <p>No notifications to display.</p>
                )}
              </div>

              <NotificationDropdown
                notifications={dummyNotifications}
                onClose={handleNotificationClose}
              />
            </Popover>
            
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <TopBar /> */}
      {renderMobileMenu}
      <Drawer variant="permanent" open={open}>
  <DrawerHeader>
    <IconButton onClick={handleDrawerClose}>
      {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </IconButton>
  </DrawerHeader>
  <Divider />
  <List>
    {navList.map((item, index) => (
      <StyledTooltip key={item.title} title={item.title} placement="right">
        <ListItem
          key={item.title}
          disablePadding
          sx={{ display: "block" }}
          onClick={() => onClickSideMenuItem(item)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              flexDirection: 'column',
              alignItems: 'center',
              px: 2.5,
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
                color: theme.palette.common.white,
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mb: 1,
                alignSelf: "center",
                fontSize: "20px",
              }}
            >
              <i className={`no-active-sidebar ${item.icon}`}></i>
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{ opacity: open ? 1 : 0, alignSelf: "center" }}
            />
          </ListItemButton>
          <Divider />
        </ListItem>
      </StyledTooltip>
    ))}
  </List>
  <Box sx={{ flexGrow: 1 }} />
  <Box sx={{ pb: 2 }}>
  <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navList.map((item, index) => (
            <StyledTooltip key={item.title} title={item.title} placement="right">
              <ListItem
                key={item.title}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => onClickSideMenuItem(item)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: theme.palette.info.dark,
                      color: theme.palette.common.white,
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mb: 1,
                      alignSelf: "center",
                      fontSize: "20px",
                    }}
                  >
                    <i className={`no-active-sidebar ${item.icon}`}></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0, alignSelf: "center" }}
                  />
                </ListItemButton>
                <Divider />
              </ListItem>
            </StyledTooltip>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ pb: 2 }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                flexDirection: 'column',
                alignItems: 'center',
                px: 2.5,
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={handleOpenDialog} // Open the confirmation dialog
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mb: 1,
                  alignSelf: "center",
                  fontSize: "20px",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" sx={{ opacity: open ? 1 : 0, alignSelf: "center" }} />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '500px' } }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Sign Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus color="primary">
            No
          </Button>
          <Button onClick={onClickLogout} color="primary" >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  </Box>
</Drawer>

    </Box>
  );
}
