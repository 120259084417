
import sliceCreator from '../index.js';
import {SEARCH_PARTNER} from "../../../constants"
const _searchPartnerAction = sliceCreator('SEARCH_PARTNER', SEARCH_PARTNER, 'get');

const { asyncAction: searchPartnerAction, reducer, clearData: clearSearchPartnerActionData } = _searchPartnerAction;

export default reducer;

export {
    searchPartnerAction,
    clearSearchPartnerActionData
}