/* eslint-disable react-hooks/exhaustive-deps */

// create functional component RateMyCustomer
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { useLocation, useNavigate,Link } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { useFormik } from "formik";
import DropdownItems from "../../utils/dropdownutils";
import { toast } from "react-toastify";
import { dispatchAll, canEnableField } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";







import {
  clearMyPartnerActionData,
  myPartnerAction
} from "../../store/actions";
import { editAndViewRatingSchema } from "./editAndViewRatingSchema";



const RateMyCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { partnerData, isEdit = false } = state || {};

  const {
    successData: ratingData,
    error: ratingError,
    errorInfo: ratingErrorInfo,
  } = useSelector((state) => state.myPartnerData) || {};

  useEffect(() => {
    if (ratingData) {
      navigate("/dashboard");
      toast.success("Rating updated successfully");
    }
  }, [ratingData]);

  useEffect(() => {
    if (ratingError) {
      toast.error(ratingErrorInfo);
    }
  }, [ratingError]);
  useEffect(() => {
    return () => dispatchAll([clearMyPartnerActionData], dispatch);
  }, []);

  const isMobile = useMediaQuery("(max-width: 767px)")
  const initialValues = {
    score: isEdit
      ? DropdownItems.score.filter((v) => v.value === partnerData.score)[0]
      : {},
    totaloutstanding:
      isEdit && partnerData.total_outstanding !== ""
        ? DropdownItems.totaloutstanding.filter(
          (v) => v.value === partnerData.total_outstanding
        )[0]
        : {},
    pendingrecievables:
      isEdit && partnerData.pending_receivables !== ""
        ? DropdownItems.pendingrecievable.filter(
          (v) => v.value === partnerData.pending_receivables
        )[0]
        : {},
    transactionPaidOntime:
      isEdit && partnerData.transaction_paid_ontime !== ""
        ? DropdownItems.transactionpaidontime.filter(
          (v) => v.value === partnerData.transaction_paid_ontime
        )[0]
        : {},
    ontimepayment:
      isEdit && partnerData.on_time_Payment !== null && partnerData.on_time_Payment !== undefined
        ? DropdownItems.percentages.filter(
          (v) => v.value === partnerData.on_time_Payment
        )[0]
        : {},
        reachability:
        isEdit && partnerData.reachability !== null && partnerData.reachability !== undefined
          ? DropdownItems.reachability.filter(
              (v) => v.value === partnerData.reachability
            )[0]
          : {},
        recoveryprobability:
        isEdit && partnerData.recovery_probability !== null && partnerData.recovery_probability !== undefined
          ? DropdownItems.recoveryprobability.filter(
              (v) => v.value === partnerData.recovery_probability
            )[0]
          : {},
          capital:
          isEdit && partnerData.capital !== null && partnerData.capital !== undefined
            ? DropdownItems.capital_or_asseets.filter(
                (v) => v.value === partnerData.capital
              )[0]
            : {},
        assets:
          isEdit && partnerData.assets !== null && partnerData.assets !== undefined
            ? DropdownItems.capital_or_asseets.filter(
                (v) => v.value === partnerData.assets
              )[0]
            : {},
    since: isEdit ? partnerData.known_since : "",
    comments: isEdit ? partnerData.comments : "",
    dso:
      isEdit && partnerData.dso !== ""
        ? DropdownItems.dso.filter((v) => v.value === partnerData.dso)[0]
        : {},
        gst:
      isEdit && partnerData.is_gst_paid !== ""
        ? DropdownItems.GST.filter(
          (v) => v.value === Number(partnerData.is_gst_paid)
        )[0]
        : {},
        legal:
        isEdit && partnerData.legal_proceedings !== null && partnerData.legal_proceedings !== undefined
          ? DropdownItems.legal.filter(
              (v) => v.value === Number(partnerData.legal_proceedings)
            )[0]
          : {},
    
  };



  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
   validationSchema: editAndViewRatingSchema,
    onSubmit: (values) => {
      const payload = {
        score: values.score?.value,
        business_partner_assoc: partnerData.business_partner_assoc.id,

      };
    
      const fields = [
        { name: 'totaloutstanding', value: values.totaloutstanding?.value },
        { name: 'reachability', value: values.reachability?.value },
        { name: 'recoveryprobability', value: values.recoveryprobability?.value },
        { name: 'comments', value: values.comments },
        { name: 'dso', value: values.dso?.value },
        { name: 'legal', value: values.legal?.value },
        { name: 'capital', value: values.capital?.value },
        { name: 'assertworth', value: values.assets?.value },
        { name: 'gst', value: values.gst?.value },
        { name: 'since', value: values.since },
        { name: 'pendingrecievable', value: values.pendingrecievables?.value },
        { name: 'business_partner_assoc', value: partnerData.business_partner_assoc.id },
     
        { name: 'transactionPaidOntime', value: values.transactionPaidOntime?.value },
        { name: 'percentages', value: values.ontimepayment?.value}
      ];
      fields.forEach(field => {
        if (canEnableField(values.score, field.name)) {
          if (field.name === 'recoveryprobability') {
            payload['recovery_probability'] = field.value;
          } else if (field.name === 'legal') {
            payload['legal_proceedings'] = field.value;
          } else if (field.name === 'assertworth') {
            payload['assets'] = field.value;
          } else if (field.name === 'transactionPaidOntime') {
            payload['transaction_paid_ontime'] = field.value;
          } else if (field.name === 'percentages') {
            payload['on_time_Payment'] = field.value;
          } else if (field.name === 'pendingrecievable') {
            payload['pending_receivables'] = field.value;
          } else if (field.name === 'totaloutstanding') {
            payload['total_outstanding'] = field.value;
          } else if (field.name === 'gst') {
            payload['is_gst_paid'] = field.value;
          }


          else {
            payload[field.name] = field.value;
          } 
        }
      });
      dispatch(myPartnerAction(payload));
    },
  });

  return (
    <>

      {
        // this is for mobile view
        isMobile ? (
          <Box sx={{}}>
            <SideBar />
            <Box
              sx={{

                top: "45%",

              }}
            >

              <Link to="/dashboard">
                <FontAwesomeIcon

                  icon={faTimes}
                />
              </Link>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: '50px',

              }}>
                <div style={{



                  display: "flex",
                  flexDirection: "column", // To stack the child elements vertically
                  alignItems: "center",


                }}>
                  <Grid container item xs={12} sm={12} md={12} lg={12} ml={2}>
                    <div>


                      <h5 className="card-title text-center">
                        <strong>Rate My partner</strong>
                      </h5>

                      <form onSubmit={handleSubmit}>
                        <div className="row g-3  m-5">
                          <div className="col-md-12 text-center">
                            <div
                              className="row align-items-center"
                              style={{ marginTop: "-20px", marginBottom: "40px" }}
                            >
                              <Link to="/dashboard" style={{ position: "absolute", top: -75, right: -50 }}>
                                <FontAwesomeIcon icon={faTimes} />
                              </Link>

                              <div className="col-md-4 text-md-right" >

                                <label
                                  htmlFor="score"
                                  className="form-label scorestyle"
                                >
                                  Score
                                </label>
                              </div>
                              <div className="col-md-4">
                                <Select
                                  defaultValue={values.score}
                                  value={values.score}
                                  name="score"
                                  id="score"
                                  onChange={(selectedScore) =>
                                    setFieldValue("score", selectedScore)
                                  }
                                  options={DropdownItems.score}
                                />
                              </div>


                            </div>

                            {errors.score && touched.score ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.score.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 ${!canEnableField(values.score, "totaloutstanding")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="totaloutstanding"
                              className="form-label"
                            >
                              Current Outstanding
                            </label>
                            <Select
                              defaultValue={values.totaloutstanding}
                              value={values.totaloutstanding}
                              name="totaloutstanding"
                              id="totaloutstanding"
                              onChange={(selectedtotaloutstanding) =>
                                setFieldValue(
                                  "totaloutstanding",
                                  selectedtotaloutstanding
                                )
                              }
                              options={DropdownItems.totaloutstanding}
                            />
                            {errors.totaloutstanding && touched.totaloutstanding ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.totaloutstanding.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "dso") ? "d-none" : ""
                              }`}
                          >
                            <label htmlFor="dso" className="form-label">
                              Days Since Outstanding
                            </label>
                            <Select
                              defaultValue={values.dso}
                              value={values.dso}
                              name="dso"
                              id="dso"
                              onChange={(selecteddso) =>
                                setFieldValue("dso", selecteddso)
                              }
                              options={DropdownItems.dso}
                            />
                            {errors.dso && touched.dso ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.dso.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "pending_recievables")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="pendingrecievable" className="form-label">
                              Pending Receivable
                            </label>
                            <Select
                              defaultValue={values.pendingrecievables}
                              value={values.pendingrecievables}
                              name="pendingrecievables"
                              id="pendingrecievables"
                              onChange={(selectedpendingrecievables) =>
                                setFieldValue("pendingrecievables", selectedpendingrecievables)
                              }
                              options={DropdownItems.pendingrecievable}
                            />
                            {errors.pendingrecievables && touched.pendingrecievables ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.pendingrecievables.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3  ${!canEnableField(values.score, "since")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="since" className="form-label">
                              Since
                            </label>
                            <input
                              type="date"
                              max={new Date().toISOString().split("T")[0]}
                              name="since"
                              value={values.since}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              id="since"
                              placeholder="since"
                            />
                            {errors.since && touched.since ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.since}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "legal")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Legal Proceedings
                            </label>
                            <Select
                              defaultValue={values.legal}
                              value={values.legal}
                              name="legal"
                              id="legal"
                              onChange={(selectedlegal) =>
                                setFieldValue("legal", selectedlegal)
                              }
                              options={DropdownItems.legal}
                            />
                            {errors.legal && touched.legal ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.legal.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "reachability")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="reachability" className="form-label">
                              Reachability{" "}
                            </label>
                            <Select
                              defaultValue={values.reachability}
                              value={values.reachability}
                              name="reachability"
                              id="reachability "
                              onChange={(selectedreachability) =>
                                setFieldValue(
                                  "reachability",
                                  selectedreachability
                                )
                              }
                              options={DropdownItems.reachability}
                            />
                            {errors.reachability && touched.reachability ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.reachability.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "recoveryprobability")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="recoveryprobability"
                              className="form-label"
                            >
                              Recovery probability
                            </label>
                            <Select
                              value={values.recoveryprobability}
                              name="recoveryprobability"
                              id="recoveryprobability"
                              onChange={(selectedrecoveryprobability) =>
                                setFieldValue(
                                  "recoveryprobability",
                                  selectedrecoveryprobability
                                )
                              }
                              options={DropdownItems.recoveryprobability}
                            />
                            {errors.recoveryprobability && touched.recoveryprobability ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.recoveryprobability.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "on_time_Payment")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="ontimepayment" className="form-label">
                              On-time Payment
                            </label>

                            <Select
                              value={
                                partnerData.on_time_Payment === null
                                  ? DropdownItems.percentages.find(
                                    (item) => item.value === 0
                                  )
                                  : DropdownItems.percentages.find(
                                    (item) =>
                                      item.value === partnerData.on_time_Payment
                                  )
                              }
                              name="on_time_payment"
                              id="on_time_payment"
                              onChange={(selectedpercentages) =>
                                setFieldValue("on_time_payment", selectedpercentages)
                              }
                              options={DropdownItems.percentages}
                            />
                             {errors.ontimepayment && touched.ontimepayment ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.ontimepayment.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(
                              values.score,
                              "transactionPaidOntime"
                            )
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor=" transactionpaidontime"
                              className="form-label"
                            >
                              Transaction paid on time
                            </label>
                            <Select
                              value={values.transactionPaidOntime}
                              name="transactionPaidOntime"
                              id="transactionPaidOntime"
                              onChange={(selectedTransactionPaidOntime) =>
                                setFieldValue(
                                  "transactionPaidOntime",
                                  selectedTransactionPaidOntime
                                )
                              }
                              options={DropdownItems.transactionpaidontime}
                            />
                             {errors.transactionPaidOntime && touched.transactionPaidOntime ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.transactionPaidOntime.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "gst") ? "d-none" : ""
                              }`}
                          >
                            <label htmlFor="GST" className="form-label">
                              GST Paid on Time
                            </label>

                            <Select
                              defaultValue={values.gst}
                              value={values.gst}
                              name="gst"
                              id="gst"
                              onChange={(selectedGST) =>
                                setFieldValue("gst", selectedGST)
                              }
                              options={DropdownItems.GST}
                            />
                             {errors.gst && touched.gst ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.gst.value}
                              </p>
                            ) : null}
                             
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "capital")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Capital worth{" "}
                            </label>
                            <Select
                              defaultValue={values.capital}
                              value={values.capital}
                              name="capital"
                              id="capital"
                              onChange={(selectedcapital) =>
                                setFieldValue("capital", selectedcapital)
                              }
                              options={DropdownItems.capital_or_asseets}
                            />
                              {errors.capital && touched.capital ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.capital.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "assets")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Assest worth{" "}
                            </label>
                            <Select
                              value={values.assets}
                              name="assets"
                              id="assets"
                              onChange={(selectedAssets) =>
                                setFieldValue("assets", selectedAssets)
                              }
                              options={DropdownItems.capital_or_asseets}
                            />
                              {errors.assets && touched.assets ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.assets.value}
                              </p>
                            ) : null}

                          </div>



                          <div className={`col-md-6 top-height ${values.score.value >= 9 && 'mx-auto'}`}>
                            <label htmlFor="comments" className="form-label">
                              Comments
                            </label>
                            <textarea
                              type="text"
                              name="comments"
                              value={values.comments}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              id="comments"
                              placeholder="Comments"
                              style={{ height: '38px' }}
                            />
                             {errors.comments && touched.comments ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.comments.value}
                              </p>
                            ) : null}

                          </div>




                          <div className="text-center col-md-12 mt-3 ">

                            <div className="text-center col-md-12 mt-3">

                              <button
                                type="submit"
                                className="btn btn-primary "
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div></form>
                    </div>

                  </Grid>
                </div>
              </div>

            </Box>
          </Box>
        ) : (
        // this is htmlFor desktop view
          <Box sx={{ display: 'flex' }}>
            <SideBar />
            <Box component="main" sx={{ flexGrow: '1', p: 3,minHeight:'90vh' }} mt={0}>
              <div style={{
                display: "flex",
                justifyContent: "center",
                marginTop: '5%',
                overflow: 'hidden',
                minHeight:'70vh'
              }}>
                <div style={{
                  border: "1px solid #ccc",
                  padding: "20px",
                  borderRadius: "10px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: '10%',
                  maxWidth: '90%',
                  marginTop: '5px',
                  // marginBottom: '100px'  
                }}>
                  <Grid container item xs={12} sm={12} md={12} lg={12} ml={2}>
                    <div>
                      <h5 className="card-title text-center">
                        <strong>Rate My partner</strong>
                      </h5>
                      <form onSubmit={handleSubmit}>
                        <div className="row g-3  m-5">
                          <div className="col-md-12 text-center">
                            <div
                              className="row align-items-center"
                              style={{ marginTop: "-20px", marginBottom: "50px" }}
                            >
                              <Link to="/dashboard" style={{ position: "absolute", top: -75, right: -50 }}>
                                <FontAwesomeIcon icon={faTimes} />
                              </Link>

                              <div className="col-md-4  text-md-right">
                                <label htmlFor="score" className="form-label scorestyle">
                                  Score
                                </label>
                              </div>
                              <div className="col-md-4">
                                <Select
                                  defaultValue={values.score}
                                  value={values.score}
                                  name="score"
                                  id="score"
                                  onChange={(selectedScore) =>
                                    setFieldValue("score", selectedScore)
                                  }
                                  options={DropdownItems.score}
                                />
                                
                              </div>
                            </div>
                            {errors.score && touched.score ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.score.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 ${!canEnableField(values.score, "totaloutstanding")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="totaloutstanding"
                              className="form-label"
                            >
                              Current Outstanding
                            </label>
                            {/* <Select
                            defaultValue={values.totaloutstanding}
                            value={values.totaloutstanding}
                            name="totaloutstanding"
                            id="totaloutstanding"
                            onChange={(selectedtotaloutstanding) =>
                              setFieldValue(
                                "totaloutstanding",
                                selectedtotaloutstanding
                              )
                            }
                            options={DropdownItems.totaloutstanding}
                          /> */}
                            <Select
                              value={values.totaloutstanding}
                              name="totaloutstanding"
                              id="totaloutstanding"
                              onChange={(selectedtotaloutstanding) =>
                                setFieldValue(
                                  "totaloutstanding",
                                  selectedtotaloutstanding
                                )
                              }
                              options={DropdownItems.totaloutstanding}
                            />
                            {errors.totaloutstanding && touched.totaloutstanding ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.totaloutstanding.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "dso") ? "d-none" : ""
                              }`}
                          >
                            <label htmlFor="dso" className="form-label">
                              Days Since Outstanding
                            </label>
                            <Select
                              defaultValue={values.dso}
                              value={values.dso}
                              name="dso"
                              id="dso"
                              onChange={(selecteddso) =>
                                setFieldValue("dso", selecteddso)
                              }
                              options={DropdownItems.dso}
                            />
                            {errors.dso && touched.dso ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.dso.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "pendingrecievable")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="pendingrecievable" className="form-label">
                              Pending Receivable
                            </label>

                            <Select
                              defaultValue={values.pendingrecievables}
                              value={values.pendingrecievables}
                              name="pendingrecievables"
                              id="pendingrecievables"
                              onChange={(selectedpendingrecievables) =>
                                setFieldValue("pendingrecievables", selectedpendingrecievables)
                              }
                              options={DropdownItems.pendingrecievable}
                            />
                            {errors.pendingrecievables && touched.pendingrecievables ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.pendingrecievables.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3  ${!canEnableField(values.score, "since")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="since" className="form-label">
                              Since
                            </label>
                            <input
                              type="date"
                              max={new Date().toISOString().split("T")[0]}
                              name="since"
                              value={values.since}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              id="since"
                              placeholder="since"
                            />
                            {errors.since && touched.since ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.since}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "legal")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Legal Proceedings
                            </label>
                            <Select
                              defaultValue={values.legal}
                              value={values.legal}
                              name="legal"
                              id="legal"
                              onChange={(selectedlegal) =>
                                setFieldValue("legal", selectedlegal)
                              }
                              options={DropdownItems.legal}
                            />
                            {errors.legal && touched.legal ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.legal.value}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "reachability")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="reachability" className="form-label">
                              Reachability{" "}
                            </label>
                            <Select
                              defaultValue={values.reachability}
                              value={values.reachability}
                              name="reachability"
                              id="reachability"
                              onChange={(selectedReachability) =>
                                setFieldValue("reachability", selectedReachability)
                              }
                              options={DropdownItems.reachability}
                            />
                            {errors.reachability && touched.reachability ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.reachability.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "recoveryprobability")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="recoveryprobability"
                              className="form-label"
                            >
                              Recovery probability
                            </label>
                            <Select
                              value={values.recoveryprobability}
                              name="recoveryprobability"
                              id="recoveryprobability"
                              onChange={(selectedrecoveryprobability) =>
                                setFieldValue(
                                  "recoveryprobability",
                                  selectedrecoveryprobability
                                )
                              }
                              options={DropdownItems.recoveryprobability}
                            /> 
                            {errors.recoveryprobability && touched.recoveryprobability ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.recoveryprobability.value}
                              </p>
                            ) : null}
                            
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "percentages")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label htmlFor="ontimepayment" className="form-label">
                              On-time Payment
                            </label>

                            {/* <Select
                            value={
                              partnerData.on_time_Payment === null
                                ? DropdownItems.percentages.find(
                                    (item) => item.value === 0
                                  )
                                : DropdownItems.percentages.find(
                                    (item) =>
                                      item.value === partnerData.on_time_Payment
                                  )
                            }
                            name="percentages"
                            id="percentages"
                            onChange={(selectedpercentages) =>
                              setFieldValue("percentages", selectedpercentages)
                            }
                            options={DropdownItems.percentages}
                          /> */}
                            {/* <Select
                            value={
                              partnerData.on_time_Payment === null
                                ? DropdownItems.percentages.find(
                                    (item) => item.value === 0
                                  )
                                : DropdownItems.percentages.find(
                                    (item) =>
                                      item.value === partnerData.on_time_Payment
                                  )
                            }
                            name="percentages"
                            id="percentages"
                            onChange={(selectedpercentages) =>
                              setFieldValue("percentages", selectedpercentages)
                            }
                            options={DropdownItems.percentages}
                          /> */}
                            <Select
                              defaultValue={values.ontimepayment}
                              value={values.ontimepayment}
                              name="ontimepayment"
                              id="ontimepayment"
                              onChange={(selectedOntimepayment) =>
                                setFieldValue("ontimepayment", selectedOntimepayment)
                              }
                              options={DropdownItems.percentages}
                            />
                            {errors.ontimepayment && touched.ontimepayment ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.ontimepayment.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(
                              values.score,
                              "transactionPaidOntime"
                            )
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor=" transactionpaidontime"
                              className="form-label"
                            >
                              Transaction paid on time
                            </label>
                            {/* <Select
                            value={values.transactionPaidOntime}
                            name="transactionPaidOntime"
                            id="transactionPaidOntime"
                            onChange={(selectedTransactionPaidOntime) =>
                              setFieldValue(
                                "transactionPaidOntime",
                                selectedTransactionPaidOntime
                              )
                            }
                            options={DropdownItems.transactionpaidontime}
                          /> */}
                            <Select
                              defaultValue={values.transactionPaidOntime}
                              value={values.transactionPaidOntime}
                              name="transactionPaidOntime"
                              id="transactionPaidOntime"
                              onChange={(selectedtransactionPaidOntime) =>
                                setFieldValue("transactionPaidOntime", selectedtransactionPaidOntime)
                              }
                              options={DropdownItems.transactionpaidontime}
                            />
                            {errors.transactionPaidOntime && touched.transactionPaidOntime ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.transactionPaidOntime.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "gst") ? "d-none" : ""
                              }`}
                          >
                            <label htmlFor="GST" className="form-label">
                              GST Paid on Time
                            </label>

                            <Select
                              name="gst"
                              id="gst"
                              value={values.gst}
                              onChange={(selectedGST) =>
                                setFieldValue("gst", selectedGST)
                              }
                              options={DropdownItems.GST}
                            />
                              {/* <Select
                              defaultValue={values.is_gst_paid}
                              value={values.is_gst_paid}
                              name="is_gst_paid"
                              id="is_gst_paid"
                              onChange={(selectedGst) =>
                                setFieldValue("is_gst_paid", selectedGst)
                              }
                              options={DropdownItems.GST}
                            /> */}
                              {errors.gst && touched.gst ? (
                                <p className="form-error" style={{ color: "red" }}>
                                  {errors.gst.value}
                                </p>
                              ) : null}

                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "capital")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Capital worth{" "}
                            </label>
                            {/* <Select
                            defaultValue={values.capital}
                            value={values.capital}
                            name="capital"
                            id="capital"
                            onChange={(selectedcapital) =>
                              setFieldValue("capital", selectedcapital)
                            }
                            options={DropdownItems.capital_or_asseets}
                          /> */}
                            <Select
                              value={values.capital}
                              name="capital"
                              id="capital"
                              onChange={(selectedCapital) =>
                                setFieldValue("capital", selectedCapital)
                              }
                              options={DropdownItems.capital_or_asseets}
                            />
                            {errors.capital && touched.capital ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.capital.value}
                              </p>
                            ) : null}
                          </div>

                          <div
                            className={`col-md-3 top-height ${!canEnableField(values.score, "assertworth")
                              ? "d-none"
                              : ""
                              }`}
                          >
                            <label
                              htmlFor="Capital / Assest worth ?"
                              className="form-label"
                            >
                              Asset worth{" "}
                            </label>
                            {/* <Select
                            defaultValue={values.assetWorth}
                            value={values.assetWorth}
                            name="assertworth"
                            id="assertworth"
                            onChange={(selectedAssetWorth) =>
                              setFieldValue("assetWorth", selectedAssetWorth)
                            }
                            options={DropdownItems.capital_or_asseets}
                          /> */}
                            <Select
                              value={values.assets}
                              name="assets"
                              id="assets"
                              onChange={(selectedAssets) =>
                                setFieldValue("assets", selectedAssets)
                              }
                              options={DropdownItems.capital_or_asseets}
                            />
                            {errors.assets && touched.assets ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.assets.value}
                              </p>
                            ) : null}
                          </div>



                          <div className={`col-md-6 top-height ${(!values.score.value || values.score.value >= 9) && 'mx-auto'}`}>
                            <label htmlFor="comments" className="form-label">
                              Comments
                            </label>
                            <textarea
                              type="text"
                              name="comments"
                              value={values.comments}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              id="comments"
                              placeholder="Comments"
                              style={{ height: '38px' }}
                            />
                            {errors.comments && touched.comments ? (
                              <p className="form-error" style={{ color: "red" }}>
                                {errors.comments}
                              </p>
                            ) : null}
                          </div>


                          {/* <div className="col-md-6 top-height">
                          <label htmlFor="comments" className="form-label">
                            Comments
                          </label>
                          <textarea
                            type="text"
                            name="comments"
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            id="comments"
                            placeholder="Comments"
                          />
                        </div> */}

                          <div className="text-center col-md-12 mt-3 ">

                            <div className="text-center col-md-12 mt-3">

                              <button
                                type="submit"
                                className="btn btn-primary "
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div></form>
                    </div>

                  </Grid>
                </div>
              </div>














            </Box>
          </Box>
        )
      }
    </>
  );
};
export default RateMyCustomer;
