import * as Yup from "yup";

export const Login = Yup.object({
  pan: Yup.string()
    .length(10, "Pan number must be exactly 10 characters")
    .required("Please enter pan number"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Please enter your password"),
});
