import sliceCreator from '../index.js';
import {RAISED_DISPUTE} from "../../../constants/index.js"

const _putResolvedDisputeAction = sliceCreator('RAISED_DISPUTE', RAISED_DISPUTE, 'post');

const { asyncAction: putResolvedDispute, reducer, clearData: clearPutResolvedDisputeData } = _putResolvedDisputeAction;

export default reducer;

export {
    putResolvedDispute,
    clearPutResolvedDisputeData
}




