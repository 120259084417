import sliceCreator from '../index.js';
import {FORGOT_PASSWORD} from "../../../constants/index.js"

const _forgotPasswordAction = sliceCreator('FORGOT_PASSWORD', FORGOT_PASSWORD, 'post');

const { asyncAction: forgotPasswordAction, reducer, clearData: clearForgotPasswordActionData } = _forgotPasswordAction;

export default reducer;

export {
    forgotPasswordAction,
    clearForgotPasswordActionData
}
