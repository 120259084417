   
import sliceCreator from '../index.js';
import {USERSEARCHBYLOCATION} from "../../../constants"

const _getUserSearchByLocation = sliceCreator('USERSEARCHBYLOCATION', USERSEARCHBYLOCATION, 'get');

const { asyncAction: getUserSearchByLocation, reducer, clearData: clearGetUserSearchByLocation } = _getUserSearchByLocation;

export default reducer;

export {
    getUserSearchByLocation,
    clearGetUserSearchByLocation
}