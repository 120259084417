import sliceCreator from '../index.js';
import {RAISED_DISPUTE} from "../../../constants/index.js"

const _getRaiseDisputeAction = sliceCreator('RAISED_DISPUTE', RAISED_DISPUTE, 'get');

const { asyncAction: getRaiseDispute, reducer, clearData: clearGetRaiseDisputeData } = _getRaiseDisputeAction;

export default reducer;

export {
    getRaiseDispute,
    clearGetRaiseDisputeData
}
