import sliceCreator from '../index.js';
import {VERIFY_PASSWORD} from "../../../constants/index.js"

const _verifyPasswordAction = sliceCreator('VERIFY_PASSWORD', VERIFY_PASSWORD, 'post');

const { asyncAction: verifyPasswordAction, reducer, clearData: clearVerifyPasswordActionData } = _verifyPasswordAction;

export default reducer;

export {
    verifyPasswordAction,
    clearVerifyPasswordActionData
}
