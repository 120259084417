import React from 'react';
import { useTable } from 'react-table';
import './table.scss';
import PropTypes from 'prop-types';
import { BsEyeFill, BsFillPencilFill,BsTrash } from 'react-icons/bs';
import { Divider } from '@mui/material';



function Table({ columns, data, onClickViewDetails, onClickEditDetails,handleRowClick ,handleDeleteClick,handleRatingDeleteClick,handleRecievedRatingView }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  
  

  return (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="tableHeader">
          {headerGroups.map(headerGroup => (
            <tr  {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  if (cell?.column?.Header === 'Action') {
                    if (cell?.column?.id === 'myRatingActions') {
                      return (
                        <td {...cell.getCellProps()}>
                          {(cell.row.original.score <= 5) && (
                            <button
                              style={{padding: '8px'}}
                              className="raisedisputebutton btn btn-primary"
                              disabled={cell.row.original.is_raised_dispute}
                              onClick={() => onClickViewDetails(cell.row.id)}
                            >
                              Raise dispute
                            </button>
                          )}
                        </td>
                      );
                    } else if (cell?.column?.id === 'myResolvedActions') {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.row.original.is_resolved ? (
                            <p>Resolved</p>
                          ) : (
                            <button
                              className="resolveDisputeAction btn btn-primary"
                              onClick={() => onClickEditDetails(cell.row.id)}
                            >
                              Resolve Dispute
                            </button>
                          )}
                        </td>
                      );
                    }
                    else if (cell?.column?.id === 'paymentsAction') {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className='icons-container' >
                          <BsEyeFill
                            className="action-icons"
                            onClick={() => handleRowClick(row.original)}
                          />
                          <Divider orientation="vertical"  sx={{ height: 24, backgroundColor: "black", width: "1px" }} />
                          <BsTrash
                            className="action-icons"
                            onClick={() => handleDeleteClick(row.original)}
                          />
                          </div>
                      </td>                     
                      );
                    }
                    else if (cell?.column?.id === 'editLinkedPartner') {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className='icons-container'>
                             <BsFillPencilFill
                            className="action-icons"
                            onClick={() => onClickEditDetails(row.original)}
                          />
                           <Divider orientation="vertical"  sx={{ height: 24, backgroundColor: "black", width: "1px" }} />
                          <BsTrash
                            className="action-icons"
                            onClick={() => handleDeleteClick(row.original)}/>
                         </div>
                      </td>
                      );
                    }

                    else if (cell?.column?.id === 'recievedRatingsAction') {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className='icons-container' >
                          <BsEyeFill
                            className="action-icons"
                            onClick={() => handleRecievedRatingView(row.original)}
                          />
                          <Divider orientation="vertical"  sx={{ height: 24, backgroundColor: "black", width: "1px" }} />
                          <BsTrash
                            className="action-icons"
                            onClick={() => handleRatingDeleteClick(row.original)}
                          />
                          </div>
                      </td>                     
                      );
                    }
                  }

                  if (cell.column.Header === 'StatusSubscription Type') {
                    let subscriptionLabel;

                    switch (cell.row.original.subscription) {
                      case 1:
                        subscriptionLabel = 'State access';
                        break;
                      case 2:
                        subscriptionLabel = 'India access';
                        break;
                      case 3:
                        subscriptionLabel = 'CB E+E';
                        break;
                      default:
                        subscriptionLabel = '';
                    }

                    return (
                      <td {...cell.getCellProps()}>
                        {subscriptionLabel}
                      </td>
                    );
                  }

                  if (cell?.column?.Header === 'Status') {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.row.original.is_success ? 'Success' : 'Pending'}
                      </td>
                    );
                  }

                   if (cell?.column?.Header === 'Linked') {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.row.original.isValid ? 'Linked' : 'Pending'}
                      </td>
                    );
                  }

                  if (cell?.column?.Header === 'Action View/Edit') {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className='icons-container' >
                          <BsEyeFill
                            className="action-icons"
                            onClick={() => onClickViewDetails(row?.index)}
                          />
                          <Divider orientation="vertical"  sx={{ height: 24, backgroundColor: "black", width: "1px" }} />
                          <BsFillPencilFill
                            className="action-icons"
                            onClick={() => onClickEditDetails(row?.index)}
                          />
                        </div>
                      </td>
                    );
                  }

                   if (cell?.column?.Header === 'View') {
                    return (
                      <td {...cell.getCellProps()}>
                          <BsEyeFill
                            className="action-icons"
                            onClick={() => onClickViewDetails(row?.index)}
                          />
                      </td>
                    );
                  }

                  if (cell?.column?.Header === 'Score') {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.row.original.score}  
                      </td>
                    );
                  }



                  if (cell?.column?.Header === 'Name'|| cell?.column?.Header === 'Location' ) {
                    return (
                      <td {...cell.getCellProps()}>
                          <div   className='NameColumn' {...cell.getCellProps()}>{cell.render('Cell')}</div>
                      </td>
                    );
                  }
                  if (cell?.column?.Header === 'Industry'|| cell?.column?.Header === 'Partner Type' ) {
                    return (
                      <td  className='remove_in_smallScreen' {...cell.getCellProps()}>
                        <div  {...cell.getCellProps()}>{cell.render('Cell')}</div>
                      </td>
                    );
                  }

                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickViewDetails: PropTypes.func.isRequired,
  onClickEditDetails: PropTypes.func.isRequired,
  handleRatingDeleteClick: PropTypes.func.isRequired,
  handleRecievedRatingView: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,

};

export default Table;
