
export const designationOptions = [
    { label: "Accounts Manager", value : "Accounts Manager" },
    { label: "Board Member",value: "Board Member" },
    { label: "CEO",value: "CEO" },
    { label: "Chairperson/Chairman",value: "Chairperson/Chairman" },
    { label: "COO",value: "COO" },
    { label: "CFO",value: "CFO" },
    { label: "Department Manager",value: "Department Manager" },
    { label: "Director",value: "Director" },
    { label: "General Manager (GM)",value: "General Manager (GM)" },
    { label: "Head of Legal",value: "Head of Legal" },
    { label: "Plant Manager",value: "Plant Manager" },
    { label: "Power of Attorney Designee",value: "Power of Attorney Designee" },
    { label: "President",value: "President" },
    { label: "Regional Manager",value: "Regional Manager" },
    { label: "SuperVisor",value: "SuperVisor" },
    { label: "Vice President (VP)",value: "Vice President (VP)" },
  ];