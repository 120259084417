import React from 'react';
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import clearbiz1 from '../../assets/clearbiz1.png'; // Ensure the path is correct
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
      <Toolbar>
        <img src={clearbiz1} alt="ClearBiz Logo" style={{ width: '150px', marginRight: 'auto' }} />
        <Box sx={{ flexGrow: 1 }} />
        <Link to="/Login" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#0073b1', 
              color: 'white',
              '&:hover': {
                backgroundColor: '#005f8f',
              }
            }}
          >
            Login/SignUp
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
