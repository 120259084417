import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./company.scss";

import { Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import CompanyName from "./companyname";
import SideBar from "./sidebar";

const About = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const navigate = useNavigate();

  const location = useLocation();

  const backHandler = () => {
    navigate("/about");
  };

  const routeState = location.pathname === "/about";

  if (isMobile) {
    return (
        <Box
          sx={{ backgroundColor: "#457b9d", paddingTop: routeState ? 0 : 3 }}
        >
          {location.pathname === "/about" ? <CompanyName /> : null}
          <Box
            sx={{
              padding: 3,
              borderTopRightRadius: "32px",
              borderTopLeftRadius: "32px",
              backgroundColor: "white",
            }}
          >
            {location.pathname === "/about" ? <SideBar /> : null}

            {location.pathname !== "/about" ? (
              <Button
                variant="text"
                sx={{
                  color: "#213554",
                  fontSize: "20px",
                  textTransform: "none",
                }}
                onClick={backHandler}
                startIcon={<ArrowBackIosIcon fontSize="small" margin={0} />}
                disableElevation
              >
                <Typography variant="body1" sx={{ fontSize: "18px" }}>
                  Back
                </Typography>
              </Button>
            ) : null}

            {location.pathname !== "/about" ? <Outlet /> : null}
          </Box>
        </Box>
    );
  } else {
    return (
      <Box >
        <Box  pt={1} pl={3} sx={{ backgroundColor: "#457b9d" }}>
          <CompanyName />
        </Box>
        <Box pl={3} marginTop={4} pb={4}>
          <Grid  maxWidth={"90vw"}container spacing={2} margin={0}>
            <Grid item sm={3} md={2}>
              <SideBar />
            </Grid>
            <Grid item sm={8} md={10} >
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
};

export default About;
