import React from 'react';
import { Box, Typography, Grid, useTheme, Paper } from '@mui/material';
import solution from '../../assets/solution.png'; // Replace with the correct path

const Solution = () => {
  const theme = useTheme();

  return (
    <Paper elevation={6} sx={{ margin: theme.spacing(3), padding: theme.spacing(3), borderRadius: theme.shape.borderRadius }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={5} lg={4}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img 
              src={solution} 
              alt="Business Solutions" 
              style={{ 
                width: '250px', 
                height: '250px', 
                borderRadius: '50%' // Making the image circular
              }} 
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={6}>
          <Box sx={{ textAlign: 'left', paddingRight: theme.spacing(2) }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#0073b1', marginBottom: theme.spacing(2) }}>
              The Solution
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Clear Biz is a user-friendly app that allows businesses to rate and review their partners based on payment history and reliability.
              Users can easily search for potential partners based on their ratings and reviews.
              The platform provides real-time information on a partner's availability and payment behavior.
              Clear Biz ensures transparency and builds trust among businesses.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Solution;
