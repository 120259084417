import React from "react";
import CommentDetail from "./CommentDetail";

import "../../styles/comments.css";
import PropTypes from 'prop-types';

function Comments({comments}) {

    // Check if the comments array is not empty
    if (comments.length === 1) {
        return (
          <div className="container">
            <h3>No comments available.</h3>
          </div>
        );
      }
  return (
    <>
    <h3 className="comment-text">Comments : {comments.length-1}</h3>
    <div className="cotainer">
        
      {comments.map((comment) => (
        
// Check if both heading and comment are not empty strings before rendering
comment.heading !== '' && comment.comment !== '' ? (
    <div key={comment}>
      <CommentDetail heading={comment.heading} comment={comment.comment} />
      <hr/>
    </div>
  ) : null

      ))}
      </div>
    </>
  );
}

export default Comments;

Comments.propTypes = {
  comments: PropTypes.string.isRequired,
};