import React from "react";
import '../../styles/scoreDetails.css'
import PropTypes from 'prop-types';

const CapitalWorth = ({capitalWorth}) => {
  return (
    <>
    
    <div className="styleforheading">Capital Worth</div>
      <div className="main">
        <h5>Yes : {capitalWorth['0'].Y}</h5>
        <h5>Moderate : {capitalWorth['0'].M}</h5>
        <h5>No : {capitalWorth['0'].N}</h5>
 
    </div>
    </>
  );
};

export default CapitalWorth;

CapitalWorth.propTypes = {
  capitalWorth: PropTypes.string.isRequired,
};
