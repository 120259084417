import sliceCreator from '../index.js';
import {SEND_OTP} from "../../../constants"

const _sendOtpAction = sliceCreator('SEND_OTP_ACTION', SEND_OTP, 'post');

const { asyncAction: sendOtpAction, reducer, clearData: clearSendOtpData } = _sendOtpAction;

export default reducer;

export {
  sendOtpAction,
  clearSendOtpData
}
