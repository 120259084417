import React,{useEffect,useState}from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getadminInctiveUsersAction } from "../../../store/actions";
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { Box, Typography } from '@mui/material';
import Loader from '../../../components/loader';



function InactiveUserList() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { successData, loading } = useSelector(state => state.getadminInctiveUsersAction);
  
    
    useEffect(() => {
        dispatch(getadminInctiveUsersAction());
    }, [dispatch]);
  
    useEffect(() => {
        if (successData) {
            setData(successData);
        }
    }, [successData,dispatch]);
    

    const columns = React.useMemo(() => TableData.inActiveUsers, []);
  
  let content 

  if (loading) {
    content = <Loader/>
  }
  else if (data.length > 0) {
    content = <Table columns={columns} data={data} />;
  }
  else {
    content = <p>No Inavative Users</p>
  }


    return (
       <Box height="82vh" boxShadow={4} m={2} p={2} >
      <Box>
        <Typography variant="h4" mb={2}>Inactive Users</Typography>
      </Box>
          {content} 
      </Box>
    );
}

export default InactiveUserList;

        