import React, { useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const ErrorPopup = ({ message, backgroundColor }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 5000); // Close the error message after 5 seconds
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const alertStyle = {
    backgroundColor: backgroundColor || 'red', // Use the provided background color or default to 'red'
  };

  return (
    <Snackbar 
      open={isOpen} 
      autoHideDuration={3000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={handleClose} 
        severity="error" 
        variant="filled"
        style={alertStyle} // Apply the background color here
      >
        <strong>Oops!</strong> {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorPopup;

ErrorPopup.propTypes = {
  message: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};
