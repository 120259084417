import sliceCreator from '../index.js';
import {NOTIFICATIONS} from "../../../constants"
const _getMyNotificationsAction = sliceCreator('NOTIFICATIONS', NOTIFICATIONS, 'get');
const { asyncAction: getMyNotificationsAction, reducer, clearData: clearGetMyNotificationsActionData } = _getMyNotificationsAction;

export default reducer;

export {
    getMyNotificationsAction,
    clearGetMyNotificationsActionData
}

