import React, { useState, useEffect } from 'react';
import Signupwithpan from './Signupwithpan'; // Import the new Signupwithpan component
import GSTCard from './GSTCard';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const Register = () => {
  const [whichform,setWhichform] =  useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    error: registrationError,
    errorInfo: registrationErrorInfo,
  } = useSelector((state) => state.registrationData) || {};
  const handleFormChange = ()=>{
     setWhichform(!whichform);
    }

    const handleClose = () => {
      setOpenDialog(false);

    };

    useEffect(() => {
      if (registrationError && registrationErrorInfo) {
        if (
          registrationErrorInfo.includes("duplicate key value violates unique") ||
          registrationErrorInfo.includes("Phone Number already exists")
        ) {
          setOpenDialog(true);
        }
      }
    }, [registrationError, registrationErrorInfo]);
    
  return (
    <>
      {whichform && <Signupwithpan handleFormChange={handleFormChange}/>}
      {!whichform && <GSTCard handleFormChange={handleFormChange} />}
      <Dialog
  open={openDialog}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
>
<DialogTitle id="alert-dialog-title">
  <Box display="flex" alignItems="center">
    <WarningIcon color="error" sx={{ marginRight: 1 }} />
    User Already Exists
    <IconButton 
        edge="end" 
        color="inherit" 
        onClick={handleClose} 
        aria-label="close"
        sx={{ ml: 'auto' }}
      >
        <CloseIcon />
      </IconButton>
  </Box>
</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      The user with this phone number already exists. Would you like to login or claim your account?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
  <Button 
    onClick={()=> navigate('/login')} 
    color="primary"
    variant='contained'
    endIcon={<ArrowForwardIcon />}
  >
    Login
  </Button>
  <Button 
    onClick={()=> navigate('/resetpassword')} 
    color="primary"
    variant='contained' 
    autoFocus
    startIcon={<VpnKeyIcon />}
  >
    Claim Account
  </Button>
</DialogActions>
</Dialog>
    </>
  );
};

export default Register;
