import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableData from "../../utils/tableutils";
import Table from "../../components/table";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SideBar from "../../components/sidebar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Loader from "../../components/loader";
import { getRaiseDispute, putResolvedDispute } from "../../store/actions";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types';
import "./raise.scss";

const DisputeContent = ({
  loadingRaisedDispute,
  isSelectedResolvedDispute,
  resolvedDisputes,
  raisedDisputes,
  resolvedDisputeColumns,
  columns,
  onClickEditDetails
}) => {
  if (loadingRaisedDispute) {
    return <Loader />;
  }

  if (isSelectedResolvedDispute) {
    return resolvedDisputes.length > 0 ? (
      <Table
        columns={resolvedDisputeColumns}
        data={resolvedDisputes}
        onClickEditDetails={onClickEditDetails}
      />
    ) : (
      <h3 style={{ fontSize: '1.5rem' }}>No Disputes to Resolve</h3>
    );
  }

  return raisedDisputes.length > 0 ? (
    <Table columns={columns} data={raisedDisputes} />
  ) : (
    <h3 style={{ fontSize: '1.5rem' }}>No Raised Disputes</h3>
  );
};

DisputeContent.propTypes = {
  loadingRaisedDispute: PropTypes.bool.isRequired,
  isSelectedResolvedDispute: PropTypes.bool.isRequired,
  resolvedDisputes: PropTypes.array.isRequired,
  raisedDisputes: PropTypes.array.isRequired,
  resolvedDisputeColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onClickEditDetails: PropTypes.func.isRequired
};

const RaiseComponent = () => {
  const dispatch = useDispatch();
  const [raisedDisputes, setRaisedDisputes] = useState([]);
  const [resolvedDisputes, setResolvedDisputes] = useState([]);
  const [isSelectedResolvedDispute, setIsSelectedResolvedDispute] = useState(false);
  const [selectedRaisedUsername, setSelectedRaisedUsername] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const { successData: putResolvedData } = useSelector((state) => state.putResolvedDispute) || {};
  const { loading: loadingRaisedDispute, successData: disputesData } = useSelector((state) => state.getRaiseDispute) || {};

  const columns = React.useMemo(() => TableData.raisedDispute, []);
  const resolvedDisputeColumns = React.useMemo(() => TableData.resolvedDispute, []);

  const fetchDisputes = useCallback(() => {
    dispatch(getRaiseDispute());
  }, [dispatch]);

  useEffect(() => {
    fetchDisputes();
  }, [fetchDisputes]);

  useEffect(() => {
    if (disputesData) {
      setRaisedDisputes(disputesData.raisedDispute);
      setResolvedDisputes(disputesData.resolveDispute);
    }
  }, [disputesData]);

  useEffect(() => {
    if (putResolvedData) {
      setResolvedDisputes((prevResolvedDisputes) => {
        const updatedResolvedDisputes = [...prevResolvedDisputes];
        // Update resolvedDisputes state as needed
        return updatedResolvedDisputes;
      });
      setReasonText("");
      setShowModal(false);
    }
  }, [putResolvedData]);

  const onClickEditDetails = (rowIndex) => {
    const selectedResolvedDispute = disputesData.resolveDispute[rowIndex];
    const selectedRaisedUsername = selectedResolvedDispute
      ? selectedResolvedDispute.user.first_name
      : "";
    setSelectedIndex(rowIndex);
    setSelectedRaisedUsername(selectedRaisedUsername);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedIndex(null);
  };

  const onSubmitResolvedData = () => {
    if (!reasonText) {
      toast.error("Please enter a reason before submitting.");
      return;
    }

    setShowModal(false);
    const data = {
      reason: reasonText,
      user: resolvedDisputes[selectedIndex]?.user.id
    };
    dispatch(putResolvedDispute(data));
    setReasonText("");
  };

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={8}>
          <Grid>
            <Grid container item xs={12} mt={2} ml={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={10} mx={4}>
                  <Typography variant="h4" className="Myratingsheading">
                    Disputes
                  </Typography>
                </Grid>
                <p className="numberofdisputes">
                  Number of Disputes: {raisedDisputes.length}
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={1} ml={2}>
              <Tabs
                value={isSelectedResolvedDispute ? 1 : 0}
                onChange={(event, newValue) => setIsSelectedResolvedDispute(newValue === 1)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Raised Disputes" />
                <Tab label="Resolved Disputes" />
              </Tabs>
            </Grid>
          </Grid>
          <Grid xs={12} lg={9} ml={2} mt={2}>
            <DisputeContent
              loadingRaisedDispute={loadingRaisedDispute}
              isSelectedResolvedDispute={isSelectedResolvedDispute}
              resolvedDisputes={resolvedDisputes}
              raisedDisputes={raisedDisputes}
              resolvedDisputeColumns={resolvedDisputeColumns}
              columns={columns}
              onClickEditDetails={onClickEditDetails}
            />
          </Grid>
        </Box>
      </Box>
      <div className="model_resolve_dispute">
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Resolve Dispute of <strong>{selectedRaisedUsername}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              label="Reason"
              multiline
              rows={6}
              fullWidth
              name="reason"
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
            />
            <div className="raisedbuttoncontainer">
              <button
                className="btn btn-primary"
                type="button"
                onClick={onSubmitResolvedData}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RaiseComponent;
