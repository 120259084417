import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Link, useTheme, useMediaQuery } from '@mui/material';

export default function Termsandpolicy() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const linkStyle = {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  };

  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
      }}
    >
      <Grid container spacing={4} justifyContent="space-evenly">
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Link href="/about/pricing" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Product & Pricing
            </Link>
            <Link href="/about/terms" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Terms of use
            </Link>
            <Link href="/about/privacy" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Privacy policy
            </Link>
            <Link href="/about/refund" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Refund
            </Link>
            <Link href="/about/shipping" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Shipping
            </Link>
            <Link href="/about/contact" target="_blank" rel="noopener noreferrer" style={linkStyle}>
              Contact
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}