import { createSlice } from '@reduxjs/toolkit'
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    is_subscription_active: false,
  },
  reducers: {
    setIsSubscriptionActive: (state, action) => {
      state.is_subscription_active = action.payload;
    },
    getIsSubscriptionActive: function(state) {
      return state.is_subscription_active;
    }
  },
});
export const { setIsSubscriptionActive,getIsSubscriptionActive } = userSlice.actions;
export default userSlice.reducer;