
import * as Yup from "yup";
 
export const editAndViewRatingSchema = Yup.object({
  score: Yup.object({
    value: Yup.number().required("Please select Score"),
    label: Yup.string().required(),
  }),
  totaloutstanding: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    return [4].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select total outstanding"),
          label: Yup.string(),
        })
      : schema;
  }),
  ontimepayment: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,4,5].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select this field"),
          label: Yup.string(),
        })
      : schema;
  }),
  reachability: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,3,4,6,7].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select reachability"),
          label: Yup.string(),
        })
      : schema;
  }),
  recoveryprobability: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    return [1,2,3,4].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select this field"),
          label: Yup.string(),
        })
      : schema;
  }),
  pendingrecievables: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,3].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select this field"),
          label: Yup.string(),
        })
      : schema;
  }),
  dso: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [4].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select dso"),
          label: Yup.string(),
        })
      : schema;
  }),

  gst: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    return [4,6,7].includes(scoreValue[0].value)
      ? schema.shape({
          value: Yup.string().required("Please select gst"),
          label: Yup.string(),
        })
      : schema;
  }),
  capital: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,3,4,5].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select capital"),
          label: Yup.string(),
        })
      : schema;
  }),
  legal: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,3,4].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select this field"),
          label: Yup.string(),
        })
      : schema;
  }),
  transactionPaidOntime: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,4].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select this field"),
          label: Yup.string(),
        })
      : schema;
  }),
  assets: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }).when("score", (scoreValue, schema) => {
    
    return [1,2,3,4,5].includes(scoreValue[0].value) 
      ? schema.shape({
          value: Yup.string().required("Please select asset"),
          label: Yup.string(),
        })
      : schema;
  }),
  since: Yup.date().when("score", (scoreValue, schema) => {
    return [ 1, 2, 3].includes(scoreValue[0].value) 
      ? schema.required("Date is required*")
      : schema;
  }),
  comments: Yup.string().when("score", (scoreValue, schema) => {
    return [1, 2, 3, 4,5,6,7].includes(scoreValue[0].value) 
      ? schema.required("comment is required*")
      : schema;
  }),


});

