import sliceCreator from '../index.js';
import {PROFILE} from "../../../constants"

const _putProfileAction = sliceCreator('PUT_PROFILE', PROFILE, 'put');

const { asyncAction: putProfileAction, reducer, clearData: clearPutProfileActionData } = _putProfileAction;

export default reducer;

export {
    putProfileAction,
    clearPutProfileActionData
}
