import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import './company.scss'
import { useNavigate } from "react-router-dom";


import companylogo from "../../assets/companylogo.png";


const CompanyName = () => {
   const navigate = useNavigate();
  return (
      <Grid container spacing={2} padding={2} > 
     

    <Grid container spacing={2} padding={2} style={{ backgroundColor: '#457b9d' }}>
  <Grid item display="flex" justifyContent="center" alignItems="center" direction="column">
    <div>
            <button onClick={()=>navigate('/')} type="button" style={{all: "unset",
}}>
              <img src={companylogo} alt="company-logo" className="company" />
            </button>

            
    </div>
  </Grid>

  <Grid item xs={8} md={10} display="flex" justifyContent="center" direction="column">
    <Typography variant="h5" component="p" className='name'>
    CLEARBIZ SOFTWARE SOLUTIONS PRIVATE LIMITED
    </Typography>
  </Grid>
</Grid>

</Grid>
  );
};

export default CompanyName;
