
import sliceCreator from '../index.js';
import {MY_PARTNER} from "../../../constants"
const _myPartnerAction = sliceCreator('MY_PARTNER', MY_PARTNER, 'post');

const { asyncAction: myPartnerAction, reducer, clearData: clearMyPartnerActionData } = _myPartnerAction;

export default reducer;

export {
    myPartnerAction,
    clearMyPartnerActionData
}


