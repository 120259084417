import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import logo from "../../assets/clearbiz2.png"; // Adjust the path as needed

const theme = createTheme();

export default function SetNewPassword() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: "#457b9d" }}>
        <Toolbar>
          <img
            src={logo}
            alt="ClearBiz Logo"
            style={{ height: "50px", marginRight: "16px" }}
          />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          sx={{ marginTop: "2rem" }}
        >
          Set New Password
        </Typography>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <TextField
            id="new-password"
            label="New Password"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            required
          />
          <TextField
            id="confirm-password"
            label="Confirm Password"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            required
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
          >
            Save Password
          </Button>
        </form>
      </Container>
    </ThemeProvider>
  );
}
