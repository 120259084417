const industryTypes = [
    { id: 1, label: 'Seeds ' },
    { id: 2, label: 'Fertilizers' },
    { id: 3, label: 'Pesticides' },
    { id: 4, label: 'Seeds,Fertilizer & Pesticides' },
    { id: 5, label: 'Seeds & Pesticides' },
    {id:6,label:"Oil Mill"},
    {id:7,label:"Ginning Mill"},
    {id:8,label:"Oil & Ginning Mill"},
];
  export default industryTypes;