import sliceCreator from '../index.js';
import {VERIFY_OTP} from "../../../constants"

const _verifyOtpAction = sliceCreator('VERIFY_OTP_ACTION', VERIFY_OTP, 'post');

const { asyncAction: verifyOtpAction, reducer, clearData: clearVerifyOtpData } = _verifyOtpAction;

export default reducer;

export {
  verifyOtpAction,
  clearVerifyOtpData
}
