import sliceCreator from '../index.js';
import {LOGIN_OTP} from "../../../constants"
const _loginOtpAction = sliceCreator('LOGIN_OTP', LOGIN_OTP, 'post');
const { asyncAction: postloginOtpAction, reducer, clearData: clearloginOtpActionData } = _loginOtpAction;

export default reducer;

export {
    postloginOtpAction,
    clearloginOtpActionData
}