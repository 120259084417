
import { List, ListItem, ListItemText,   } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';



import Grid  from "@mui/material/Grid";


import "./sidebar.scss";



const useStyles = makeStyles({
  root: {
    // Your custom styles here
    fontSize: '1.2rem',
    fontWeight: 'bold',
    fontFamily: "sans-serif",

  },
});

const SideBar = () => {
   const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <List>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/terms"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Terms and Conditions" />
        </ListItem>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/privacy"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Privacy Policy" />
        </ListItem>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/pricing"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Products and Pricing" />
        </ListItem>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/refund"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Cancellation and Refund Policy" />
        </ListItem>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/shipping"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Shipping and Delivery Policy" />
        </ListItem>
        <ListItem
          className='ListItem'
          component={NavLink}
          to="/about/contact"
          activeClassName="SideBarActive"
        >
          <ListItemText classes={{ primary: classes.root }} primary="Contact Us" />
        </ListItem>
      </List>
    </Grid>
  );
};

export default SideBar;