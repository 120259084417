   
import sliceCreator from '../index.js';
import {ADMIN_PAYMENTS} from "../../../constants"

const _getadminPaymentsAction = sliceCreator('ADMIN_PAYMENTS', ADMIN_PAYMENTS, 'get');

const { asyncAction: getadminPaymentsAction, reducer, clearData: clearGetAdminPaymentsAction } = _getadminPaymentsAction;

export default reducer;

export {
    getadminPaymentsAction,
    clearGetAdminPaymentsAction
}