import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import DropdownItems from "../../utils/dropdownutils";
import "./ratingcard.scss";
import PropTypes from "prop-types"

const Ratingscard = ({ overallDetails, partnerName, partnersRatings }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#4b31ad" : "#ed4224",
    },
  }));

  const getRatingLabels = () => {
    const first5Scores = DropdownItems.score.slice(0, 10);

    const rLbls = first5Scores.map((score, i) => {
      const ratingValue =
        overallDetails.overAllRatings[DropdownItems.score.length - i];
      if (ratingValue > 0) {
        return (
          <div key={score.value}>
            <Typography
              style={{
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "4px",
                marginTop: "12px",
              }}
            >
              {score.label}: {ratingValue}
            </Typography>

            <BorderLinearProgress
              variant="determinate"
              value={(ratingValue / partnersRatings.length) * 100}
            />
          </div>
        );
      } else {
        return null; // or you can return an empty fragment <></> to avoid rendering anything
      }
    });

    return rLbls;
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}></div>

      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        {getRatingLabels()}
      </div>
    </div>
  );
};
export default Ratingscard;


Ratingscard.propTypes = {
  overallDetails: PropTypes.any.isRequired,
  partnersRatings: PropTypes.any.isRequired,
  partnerName: PropTypes.any.isRequired,
}
