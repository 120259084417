import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import PropTypes from 'prop-types';


const GivenRatingTable = ({ givenRatings,loading }) => {

  const columns = React.useMemo(() => TableData.givenRatings, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!givenRatings || givenRatings.length === 0) {
    return (
      <Box mt={2}>
        <Typography>No received ratings available</Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Table columns={columns} data={givenRatings} />
    </Box>
  );
};

export default GivenRatingTable;

GivenRatingTable.propTypes = {
  givenRatings: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
};
