import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactStars from 'react-stars';
import PropTypes from 'prop-types';


import "bootstrap/dist/css/bootstrap.css";

const LinktoCustomerNext = ({ onSubmit }) => {
  const [fullname, setFullname] = useState("");
  const [pan, setPan] = useState("");
  const [knownsince, setKnownsince] = useState("");
  const [credibility, setCredibility] = useState("");
  const [rating, setRating] = useState("");

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="formFullname">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter name"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formPan">
        <Form.Label>PAN</Form.Label>
        <Form.Control
          type="text"
          placeholder="Pan Number"
          value={pan}
          onChange={(e) => setPan(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formKnownsince">
        <Form.Label>Known Since</Form.Label>
        <Form.Control
          type="date"
          placeholder="mm/yyyy"
          value={knownsince}
          onChange={(e) => setKnownsince(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formCredibility">
        <Form.Label>Credibility</Form.Label>
        <Form.Control
          type="text"
          placeholder="--Select--"
          value={credibility}
          onChange={(e) => setCredibility(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formRating">
        <Form.Label>Ratings</Form.Label>
        <ReactStars 
        count={5}
        size={30}
        value={rating}
        onChange={(e) => setRating(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit" block>
        Next
      </Button>
    </Form>
  );
};

const LinktoCust = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Link this existing Business Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LinktoCustomerNext onSubmit={onLoginFormSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default LinktoCust


LinktoCustomerNext.propTypes = {
  onSubmit: PropTypes.func.isRequired,

};