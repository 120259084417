   
import sliceCreator from '../index.js';
import {ADMIN_PAYMENTS} from "../../../constants"

const _deleteadminPaymentsAction = sliceCreator('ADMIN_PAYMENTS', ADMIN_PAYMENTS, 'delete');

const { asyncAction: deleteadminPaymentsAction, reducer, clearData: clearDeleteAdminPaymentsAction } = _deleteadminPaymentsAction;

export default reducer;

export {
    deleteadminPaymentsAction,
    clearDeleteAdminPaymentsAction
}