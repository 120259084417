import React from "react";
import '../../styles/scoreDetails.css'
import "./outstanding.scss";

import PropTypes from "prop-types"

const OutstandingLoans = ({outstanding,totalCustomer,dsoAverageDays}) => {
  return (
    <>
    
      <div className="styleforheading ">Current Oustanding</div>
      <div className="main">
        <h5>{outstanding} approx.</h5>
        <h5>{totalCustomer} - Customers </h5>
        <h5>{Math.floor(dsoAverageDays)} - Days Average</h5>
    </div>
    </>
  );
};

export default OutstandingLoans;




OutstandingLoans.propTypes = {
  outstanding:PropTypes.any.isRequired,
  totalCustomer:PropTypes.any.isRequired,
  dsoAverageDays:PropTypes.any.isRequired
}