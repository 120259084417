import React from "react";
import PropTypes from 'prop-types';

//consume information/props from parent(i.e., index.js)
const CommentDetail = ({heading,comment}) => {

  
    return (
    <div className="comment">
      
        <div className="heading-text">
          <h6 className="heading-text">{heading}</h6>
        </div>
        <span className="text commentArea">{comment}</span>
      
    </div>
  );
};

export default CommentDetail;

CommentDetail.propTypes = {
  heading: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};
