import React from "react";
import '../../styles/scoreDetails.css'
import PropTypes from 'prop-types';

const AssetWorth = ({assetWorth}) => {
  return (
    <>
    
    <div className="styleforheading">Asset Worth</div>
      <div className="main">
        <h5>Yes : {assetWorth['0'].Yes}</h5>
        <h5>Moderate : {assetWorth['0'].Moderate}</h5>
        <h5>No : {assetWorth['0'].No}</h5>
 
    </div>
    </>
  );
};

export default AssetWorth;

AssetWorth.propTypes = {
  assetWorth: PropTypes.string.isRequired,
};