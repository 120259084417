import React,{ useEffect }from 'react';
import Header from './header';
import MainVisual from './main';  
import KeyFeatures from './keyfeatures';
import Pillars from './Pillars';
import Benefits from './Benefits';
import Why from './Why';
import Solution from './solution';
import Termsandpolicy from "../terms_policy";
import { useLocation } from "react-router-dom";

const Homepage = () => {
  const location = useLocation();
  useEffect(() => {
    const elementId = location.hash.substring(1); // Get the element id from the hash
    const element = document.getElementById(elementId);

    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, [location]); // Listen for changes in the location
  return (
    <div>
      <Header />
      <MainVisual />
      <KeyFeatures/>
      <div id="about" >
        <Why/>
      </div>
      <Pillars/>
      <Solution/>
      <Benefits/>
      <Termsandpolicy />
      {/* <FeaturesSection /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Homepage;
