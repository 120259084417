import React from "react";
import './content.scss'



const Shipping = () => {
  return(
    <>
    <p className='termsHeading'>Shipping</p>
    <hr className='separate' />
    <p className='text-color'>Shipping is not applicable for business.</p>
    </>
  );
};

export default Shipping;