import React from 'react';
import { Box, Typography, Grid, useTheme, Paper } from '@mui/material';
import QueImage from '../../assets/que.png'; // Importing the image

const Why = () => {
  const theme = useTheme();

  return (
    <Paper elevation={6} sx={{ margin: theme.spacing(3), padding: theme.spacing(3), borderRadius: theme.shape.borderRadius }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#0073b1', marginBottom: theme.spacing(2) }}>
              The Problem
            </Typography>
            <Typography variant="body" sx={{ color: theme.palette.text.primary }}>
              Many businesses face challenges when it comes to finding reliable business partners.
              Traditional methods of assessing trustworthiness, such as credit checks, are often insufficient.
              Lack of a centralized platform to showcase business partners' payment history and reliability.
              ClearBiz addresses these issues by providing a comprehensive solution.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {/* Use the imported image here */}
            <img src={QueImage} alt="Question Mark" style={{ 
                width: '250px', 
                height: '250px', 
                borderRadius: '50%'  }} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Why;
