import sliceCreator from '../index.js';
import {SUBSCRIPTION} from "../../../constants/index.js"

const _getSubscriptionAction = sliceCreator('SUBSCRIPTION', SUBSCRIPTION, 'get');

const { asyncAction: getSubscriptionAction, reducer, clearData: clearSubscriptionData } = _getSubscriptionAction;

export default reducer;

export {
  getSubscriptionAction,
  clearSubscriptionData
}

