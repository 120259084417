   
import sliceCreator from '../index.js';
import {LINKED_PAN} from "../../../constants"

const _postLinkedReationAction = sliceCreator('LINKED_PAN', LINKED_PAN, 'post');

const { asyncAction: postLinkedReationActon, reducer, clearData: clearpostLinkedReationAction
    
 } = _postLinkedReationAction;

export default reducer;

export {
    postLinkedReationActon,
    clearpostLinkedReationAction
    
}


