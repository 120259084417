import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import logo from '../../assets/clearbiz2.png';
import qrCodeImage from '../../assets/Upi.png';
import PaymentSuccess from '../Paymentsuccess';


import { postPayment } from '../../store/actions';

const theme = createTheme();

export default function PaymentPage() {
  const paymentId= localStorage.getItem('paymentId');
  const offer_Prize = localStorage.getItem("offer_Prize")
  const navigate = useNavigate();
  const { successData: postPaymentData, error: paymentError, errorInfo: paymentErrorInfo } = useSelector(state => state.postPaymentDetails) || {};

  const [transactionId, setTransactionId] = React.useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    if (postPaymentData) {
      localStorage.setItem("is_subscription_active",true)
      setOpen(true);
    }
  }, [postPaymentData]);

  React.useEffect(() => {
    if (paymentError) {
      toast.error(paymentErrorInfo)
    }
  }, [paymentError,paymentErrorInfo]);

  const onSubmit = () => {
    const data = {
      transaction_id: transactionId,
      payment_amount: offer_Prize,
      subscription:parseInt(paymentId),
    };
    dispatch(postPayment(data));
    localStorage.removeItem('offer_prize');
    
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/');
    window.location.href = '/'; 

  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: '#457b9d' }}>
        <Toolbar>
          <img src={logo} alt="ClearBiz Logo" style={{ height: '50px', marginRight: '16px' }} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ marginTop: '1rem', textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom style={{ color: '#d90429', marginBottom: '1rem' }}>
          Important Update
        </Typography>
        <Typography variant="h5" component="h3" gutterBottom style={{ fontWeight: 'bold' }}>
          As per RBI guidelines, certain transactions are not supported through our payment gateway.
        </Typography>
        <Typography variant="h5" component="h3" gutterBottom>
          However, we facilitate these transactions seamlessly via QR code payments.
        </Typography>
        <Grid container item xs={12} sm={12} md={12} lg={12} ml={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ marginTop: '20px' }}>
              <img src={qrCodeImage} alt="QR Code" style={{ maxWidth: '100%' }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div style={{ marginTop: '120px', marginLeft: '40px' }}>
              <TextField
                label="Transaction Id"
                variant="outlined"
                style={{ width: '180%' }}
                onChange={(e) => setTransactionId(e.target.value)}
                sx={{
                  width: '20%',
                }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '1rem', width: '100%' }}
                disabled={transactionId.length < 8}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
        <PaymentSuccess handleClose={handleClose} />
      </Dialog>
      </Container>
      {/* Payment Success Popup */}
      
    </ThemeProvider>
  );
}
