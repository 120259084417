import { BASE_URL, STATIC_BASE_URL } from '../../constants';
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const getApi = endPoint => ({
    get: async () => {
        const token = localStorage.getItem('token');
        let headers = {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json"
        };
        if (endPoint === 'globalConfig/') {
            headers = {
                "Content-Type": "application/json"
            };
        }
        let options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'get',
            headers: headers
        };
        if (endPoint === 'subscriptions/') {
            headers = {
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }
        if (endPoint === 'gstSearch/') {
            const gst = localStorage.getItem('gst');
            headers = {
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}?searchKey=${gst}`,
                method: 'get',
                headers: headers
            };
        }
        if (endPoint === 'admin/admin_dashboard/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }

        if (endPoint === 'admin/userSearch/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }

        if (endPoint === 'admin/payments/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }

        if (endPoint === 'admin/inactive_users/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }

        if (endPoint === '/admin/linkedRelationship/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'get',
                headers: headers
            };
        }

        try {
            return await axios(options);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                throw new Error("Token is invalid or expired. Please log in again.");
            }
            throw error;
        }
    },
    post: async data => {
        const token = localStorage.getItem('token', '');
        let headers = {};

        if (token) {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
        }

        if (endPoint === 'login/' || endPoint === 'registration/') {
            if (endPoint === 'registration/' && token !== '' && token !== null) {
                headers = {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                };
            } else {
                headers = {
                    "Content-Type": "application/json"
                };
            }
        }

        if (endPoint === 'password_reset/') {
            headers = {
                "Content-Type": "application/json"
            };

            data = { "email": data };

            const options = {
                url: `${STATIC_BASE_URL}${endPoint}`,
                method: 'post',
                data,
                headers: headers
            };
            return axios(options);
        }

        if (endPoint === 'linkedRelationship/') {
            headers = {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            };
            const options = {
                url: `${BASE_URL}${endPoint}`,
                method: 'post',
                headers: headers,
                data: JSON.stringify(data)
            };
            return axios(options);
        }

        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'post',
            data,
            headers: headers
        };
        return axios(options);
    },
    patch: async data => {
        const token = localStorage.getItem('token');
        let options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'patch',
            data,
            headers: {
                Authorization: `Token ${token}`
            }
        };
        return axios(options);
    },
    put: async data => {
        const token = localStorage.getItem('token');
        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'put',
            data,
            headers: {
                Authorization: `Token ${token}`
            }
        };
        return axios(options);
    },
    delete: async data => {
        const token = localStorage.getItem('token');
        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'delete',
            data,
            headers: {
                Authorization: `Token ${token}`
            }
        };
        return axios(options);
    },
});

export default function sliceCreator(sliceName, endPoint, method) {
    const action = createAsyncThunk(
        (`${sliceName}/${method}`).toUpperCase(),
        async (data, { rejectWithValue }) => {
            try {
                const response = await getApi(data?.endPoint || endPoint)[method](data);
                return response.data;
            } catch (err) {
                const message = err.response?.data?.message || err.message || "An error occurred.";

                if (message === "Token is invalid or expired. Please log in again.") {
                    toast.error("Your session has expired because you logged in on another device. Please log in again.");
                    setTimeout(() => {
                        window.location.href = '/login';
                        localStorage.clear();
                    }, 3000);
                } else {
                    console.log(message);   
                }

                return rejectWithValue(err.response?.data || message);
            }
        }
    );

    const clearData = `${(method).toUpperCase()}/clear`;

    const slice = createSlice({
        name: (sliceName).toUpperCase(),
        initialState: {
            loading: false,
            successData: null,
            error: false,
            errorInfo: null,
        },
        reducers: {
            [clearData]: () => ({})
        },
        extraReducers: {
            [action.pending]: (state) => {
                state.loading = true;
                state.successData = null;
                state.error = false;
                state.errorInfo = null;
            },
            [action.rejected]: (state, action) => {
                state.loading = false;
                state.successData = null;
                state.error = true;
                state.errorInfo = action.payload?.message || action.payload?.non_field_errors?.toString() || action.payload;
            },
            [action.fulfilled]: (state, action) => {
                state.loading = false;
                state.successData = action.payload === "" ? { success: true } : action.payload;
                state.error = false;
                state.errorInfo = null;
            }
        }
    });

    return {
        reducer: slice.reducer,
        asyncAction: action,
        clearData: slice.actions[clearData]
    };
}
