import React from "react";
import './content.scss'



const Refund = () => {
  return(
    <>
    <p className='termsHeading'>Refund</p>
    <hr className='separate' />
    <p className='text-color'>No cancellations & Refunds are entertained</p>
    </>
  );
};
export default Refund;