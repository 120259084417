   
import sliceCreator from '../index.js';
import {ADMIN_PAYMENTS} from "../../../constants"

const _patchAdminPaymentsAction = sliceCreator('ADMIN_PAYMENTS', ADMIN_PAYMENTS, 'patch');

const { asyncAction: patchAdminPaymentsAction, reducer, clearData: clearPatchAdminPaymentsAction } = _patchAdminPaymentsAction;

export default reducer;

export {
    patchAdminPaymentsAction,
    clearPatchAdminPaymentsAction
}