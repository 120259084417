import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, TextField, Button, Box, IconButton, Typography, useMediaQuery, styled, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { patchEditBusinessAction, globalConfigAction, patchEditUserAction,getUserSearchAction } from '../../../store/actions';
import PropTypes from 'prop-types';
import { getLableAndValuesFromData,getDistricts,getStates } from "../../../utils";
import { toast } from 'react-toastify';
import { designationOptions } from "../../../constants/designationOptions";

// Validation schema using Yup
const validationSchema = Yup.object({
  businessName: Yup.string().required('Required'),
  PanNumber: Yup.string().required('Required'),
  incorporation_date: Yup.string().required('Required'),
  industry: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  authorizedPersonName: Yup.string(),
  authorizedPersonDesignation: Yup.string(),
  authorizedPersonPhoneNumber: Yup.string(),
});

// Reusable styled component
const StyledBox = styled(Box)(({ theme, isMobile }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  justifyContent: isMobile ? 'flex-start' : 'space-between',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
}));

// Reusable TextField component
const CustomTextField = ({ label, name, value, error, helperText, onChange, onBlur, readOnly, size }) => (
  <TextField
    label={label}
    name={name}
    variant="outlined"
    fullWidth
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    error={error}
    helperText={helperText}
    InputProps={{ readOnly }}
    size={size}
  />
);

const Profile = ({ profile,searchValue }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isPersonalInfoEdit, setIsPersonalInfoEdit] = useState(false);
  const [isAuthorizedPersonEdit, setIsAuthorizedPersonEdit] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [industryTypes, setIndustryTypes] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');

  const statesData = getStates();

  const handleStateChange = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setFieldValue('state', value);
    setFieldValue('district', ''); // Reset district when state changes
  };

  // Synchronize district state with Formik
  const handleDistrictChange = (event) => {
    const { value } = event.target;
    setSelectedDistrict(value);
    setFieldValue('district', value);  // Update Formik's district field
  };

  const districtData = React.useMemo(() => {
    if (selectedState) {
      return getDistricts(selectedState);
    }
    return [];
  }, [selectedState]);

  const {
    successData: globalSuccessData,
  } = useSelector((state) => state.globalConfigData) || {};

  const {
    values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue,
  } = useFormik({
    initialValues: {
      businessName: profile?.business.name || '',
      PanNumber: profile?.business.masked_pan || '',
      incorporation_date: profile?.business.incorporation_date || '',
      industry: profile?.business.industry || '',
      state: profile?.business.state || '',
      city: profile?.business.city || '',
      district: profile?.business.district || '',
      firstName: profile?.first_name || '',
      email: profile?.email || '',
      phoneNumber: profile?.phone_number || '',
      isPremiumUser: profile?.is_premium_user || false,
      isActive: profile?.is_active || false,
      premiumType:profile?.premium_type || '',
      authorizedPersonName: profile?.business.authorized_user_name || '',
      authorizedPersonDesignation: profile?.business.authorized_user_designation || '',
      authorizedPersonPhoneNumber: profile?.business.authorized_user_phone_number || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const businessDetails = {
        businessName: values.businessName,
        PanNumber: values.PanNumber,
        incorporation_date: values.incorporation_date,
        industry: values.industry,
        state: values.state,
        city: values.city,
        district: values.district, // District now comes from Formik
        authorized_user_name: values.authorizedPersonName,
        authorized_user_designation: values.authorizedPersonDesignation,
        authorized_user_phone_number: values.authorizedPersonPhoneNumber,
      };

      const userDetails = {
        first_name: values.firstName,
        email: values.email,
        phone_number: values.phoneNumber,
        is_premium_user: values.isPremiumUser,
        is_active: values.isActive,
        premium_type: values.premiumType,
      };

      Promise.all([
        dispatch(
          patchEditUserAction({
            endPoint: `admin/edit-user/${profile.id}/`,
            ...userDetails,
          })
        ),
        dispatch(
          patchEditBusinessAction({
            endPoint: `admin/edit-business/${profile.business.id}/`,
            ...businessDetails,
          })
        ),
      ])
        .then(() => {
          toast.success('Details updated successfully');
        })
        .catch(() => {
          toast.error('Failed to update details');
        });

      dispatch(getUserSearchAction({
        endPoint: `admin/userSearch/?pan_number=${searchValue}`,
      }));

      setIsEdit(false);
      setIsPersonalInfoEdit(false);
      setIsAuthorizedPersonEdit(false);
    },
  });

  useEffect(() => {
    if (globalSuccessData) {
      setIndustryTypes(getLableAndValuesFromData(globalSuccessData.industry_type));
    }
  }, [globalSuccessData]);

  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  const handleTextFieldChange = useCallback((event) => {
    handleChange(event);
  }, [handleChange]);

const getPremiumTypeLabel = (premiumType) => {
    switch (premiumType) {
      case 1:
        return 'StateAccess';
      case 2:
        return 'IndiaAccess';
      case 3:
        return 'CB E+E';
      default:
        return 'Unknown';
    }
  };

  useEffect(() => {
    if (profile?.business?.district) {
      setSelectedDistrict(profile.business.district);
      setFieldValue('district', profile.business.district); // Sync with Formik
    }
  }, [profile, setFieldValue]);

  return (
    <Box sx={{ maxWidth: "100%", padding: isMobile ? '16px' : '32px' }}>
      {/* Personal Info Section */}
      <Box display="flex" alignItems="center">
        <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>Personal Info</Typography>
        <IconButton onClick={() => setIsPersonalInfoEdit(!isPersonalInfoEdit)}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>User Name:</Typography>
            {isPersonalInfoEdit ? (
              <CustomTextField
                label="First Name"
                name="firstName"
                value={values.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                onChange={handleTextFieldChange}
                onBlur={handleBlur}
                size="small" 
              />
            ) : (
              <Typography>{profile?.first_name}</Typography>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Email:</Typography>
            {isPersonalInfoEdit ? (
              <CustomTextField
                label="Email"
                name="email"
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onChange={handleTextFieldChange}
                onBlur={handleBlur}
                size="small" 
              />
            ) : (
              <Typography>{profile?.email}</Typography>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Phone Number:</Typography>
            {isPersonalInfoEdit ? (
              <CustomTextField
                label="Phone Number"
                name="phoneNumber"
                value={values.phoneNumber}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small" 
              />
            ) : (
              <Typography>{profile?.phone_number}</Typography>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>PAN Number:</Typography>
            <Typography>{profile?.masked_pan}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Premium Type:</Typography>
            {isPersonalInfoEdit ? (
              <FormControl fullWidth>
                <InputLabel id="premium-type-label">Premium Type</InputLabel>
                <Select
                  labelId="premium-type-label"
                  id="premium-type"
                  name="premiumType"
                  value={values.premiumType}
                  onChange={handleChange}
                  size='small'
                >
                  <MenuItem value={1}>StateAccess</MenuItem>
                  <MenuItem value={2}>IndiaAccess</MenuItem>
                  <MenuItem value={3}>CB E+E</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Typography>{getPremiumTypeLabel(profile.premium_type)}</Typography>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Created Date:</Typography>
            <Typography>{profile?.created_at?.slice(0, 10)}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Last Login:</Typography>
            <Typography>{profile?.last_login?.slice(0, 10)}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Is Premium User:</Typography>
            {isPersonalInfoEdit ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isPremiumUser}
                    onChange={handleChange}
                    name="isPremiumUser"
                    color="primary"
                    size='small'
                  />
                }
                label="Premium User"
              />
            ) : (
              <Typography>{profile?.is_premium_user ? "TRUE" : "FALSE"}</Typography>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            <Typography sx={{ fontWeight: '600' }}>Is Active:</Typography>
            {isPersonalInfoEdit ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isActive}
                    onChange={handleChange}
                    name="isActive"
                    color="primary"
                    size='small'
                  />
                }
                label="Active"
              />
            ) : (
              <Typography>{profile?.is_active ? "TRUE" : "FALSE"}</Typography>
            )}
          </StyledBox>
        </Grid>
      </Grid>

      {isPersonalInfoEdit && (
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '16px' }}>
          Save Personal Info
        </Button>
      )}

      {/* Business Details Section */}
      <Box display="flex" alignItems="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Business Details</Typography>
        <IconButton onClick={() => setIsEdit(!isEdit)}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="Business Name"
            name="businessName"
            value={values.businessName}
            error={touched.businessName && Boolean(errors.businessName)}
            helperText={touched.businessName && errors.businessName}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="PAN Number"
            name="PanNumber"
            value={values.PanNumber}
            error={touched.PanNumber && Boolean(errors.PanNumber)}
            helperText={touched.PanNumber && errors.PanNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="Incorporation Date"
            name="incorporation_date"
            value={values.incorporation_date}
            error={touched.incorporation_date && Boolean(errors.incorporation_date)}
            helperText={touched.incorporation_date && errors.incorporation_date}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth error={touched.industry && Boolean(errors.industry)}>
            <InputLabel id="industry-label">Industry</InputLabel>
            <Select
              labelId="industry-label"
              id="industry"
              name="industry"
              value={values.industry}
              onChange={(event) => setFieldValue('industry', event.target.value)}
              label="Industry"
              disabled={!isEdit}
            >
              {industryTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {touched.industry && Boolean(errors.industry) && (
              <Typography color="error" variant="caption">{errors.industry}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="state">State</InputLabel>
            <Select
              label="State"
              id="state"
              name="state"
              value={values.state}
              onChange={handleStateChange}
              disabled={!isEdit}
              
            >
              {statesData.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBox isMobile={isMobile}>
            {isEdit ? (
              <FormControl fullWidth>
                <InputLabel htmlFor="district">District</InputLabel>
                <Select
                  label="District"
                  id="district"
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  disabled={!values.state || !isEdit}
                >
                  {districtData.map((district) => (
                    <MenuItem key={district.value} value={district.value}>
                      {district.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
              
              label="District"
              value={values.district}
              id='district'
              fullWidth
              
              />
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="City"
            name="city"
            value={values.city}
            error={touched.city && Boolean(errors.city)}
            helperText={touched.city && errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        {isEdit && (
          <Grid item xs={12} sm={6}>
            <Button
             onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        )}
      </Grid>

      {/* Authorized Person Section */}
      <Box display="flex" alignItems="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Authorized Person</Typography>
        <IconButton onClick={() => {
          setIsAuthorizedPersonEdit(!isAuthorizedPersonEdit)
          setFieldValue("authorizedPersonPhoneNumber", profile?.business.authorized_user_phone_number)
          }}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            label="Name"
            name="authorizedPersonName"
            value={values.authorizedPersonName}
            error={touched.authorizedPersonName && Boolean(errors.authorizedPersonName)}
            helperText={touched.authorizedPersonName && errors.authorizedPersonName}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isAuthorizedPersonEdit}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="designation-label">Designation</InputLabel>
            <Select
              labelId="designation-label"
              id="authorizedPersonDesignation"
              name="authorizedPersonDesignation"
              value={values.authorizedPersonDesignation}
              onChange={handleChange}  
              label="Designation"
              disabled={!isAuthorizedPersonEdit}
            >
              {designationOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {touched.authorizedPersonDesignation && Boolean(errors.authorizedPersonDesignation) && (
              <Typography color="error" variant="caption">
                {errors.authorizedPersonDesignation}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            label="Phone Number"
            name="authorizedPersonPhoneNumber"
            value={values.authorizedPersonPhoneNumber}
            error={touched.authorizedPersonPhoneNumber && Boolean(errors.authorizedPersonPhoneNumber)}
            helperText={touched.authorizedPersonPhoneNumber && errors.authorizedPersonPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isAuthorizedPersonEdit}
          />
        </Grid>
        {isAuthorizedPersonEdit && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ marginTop: '16px' }}
            >
              Save Authorized Person Info
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  searchValue: PropTypes.object.isRequired,
};

export default Profile;
