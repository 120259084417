import sliceCreator from '../index.js';
import { NOTIFICATIONS } from "../../../constants"


const _putMyNotificationsAction = sliceCreator('NOTIFICATIONS', NOTIFICATIONS, 'put');


const { asyncAction: putNotificationsAction, reducer, clearData: clearPutNotificationsActionData } = _putMyNotificationsAction;

export default reducer;

export {
    putNotificationsAction, 
    clearPutNotificationsActionData 
}
