import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar";
import { clearSearchPartnerActionData} from "../../store/actions";
import { dispatchAll } from "../../utils";
import "./noresultssearch.scss";

const Noresultssearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    successData: searchData,} = useSelector((state) => state.searchPartnersData) || {};

  React.useEffect(() => {
    if (searchData && searchData.length > 0) {
      navigate("/searchprofile", {
        state: { ...searchData[0] },
        replace: false,
      });
    } else if (searchData && searchData.length === 0) {
      toast.success("no results found");
    }
  }, [searchData,navigate]);

  React.useEffect(() => {
    return () => dispatchAll([clearSearchPartnerActionData], dispatch);
  }, [dispatch]);
  const formSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Sidebar />

      <div className="card_container" style={{minHeight:'90vh'}}>
        <div className="card">
          <form onSubmit={formSubmit}>
            <div style={{ textAlign: "center" }}>
              <p className="no_search_result">No results found</p>
              <p className="tab-center business_partner_para">
                Sorry, We Couldn't Find Your Partner. Add a New One.
              </p>
              <Link to="/newcustomer">
          
                <button
                  className="btn btn-primary addcustomer"
                  type="button"
                  id="button-addon2"
                
                >
                  Add Partner
                </button>
          
               
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Noresultssearch;
