import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "./signup.scss"
import {
  clearRegistrationData,
  clearSendOtpData,
  clearVerifyOtpData,
  registrationAction,
  sendOtpAction,
  verifyOtpAction,globalConfigAction
} from "../../store/actions";
import {
  dispatchAll,
  getLableAndValuesFromData 
} from "../../utils";


import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";

import * as Yup from "yup";


import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { designationOptions } from "../../constants/designationOptions";
import ReCAPTCHA from 'react-google-recaptcha';
import {SITE_KEY} from "../../constants/index"




const FormFieldError = ({ errors, touched, fieldName }) => {
  return (
    <>
      {errors[fieldName] && touched[fieldName] && (
        <p className="form-error remove-margin" style={{ color: "red" }}>
          {errors[fieldName]}
        </p>
      )}
    </>
  );
};

function Signupwithpan({ handleFormChange }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [industryTypes, setIndustryTypes] = React.useState([]);
  const [partnerTypes, setPartnerTypes] = React.useState([]);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [phoneOtpSent, setPhoneOtpSent] = useState(false);
  const [emailOtpTimer, setEmailOtpTimer] = useState(30);
  const [phoneOtpTimer, setPhoneOtpTimer] = useState(30);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [verifyIdentifier, setVerifyIdentifier] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [isFieldsReadOnly, setIsFieldsReadOnly] = useState(false);
  const [pinError, setPinError] = useState('');

  const {
    successData: sendOtpSuccessData,
    loading: sendOtpLoading,
    error: sendOtpError,
    errorInfo: sendOtpErrorInfo,
  } = useSelector((state) => state.sendOtpReducer) || {};
  const {
    successData: verifyOtpSuccessData,
    loading: verifyOtpLoading,
    error: verifyOtpError,
    errorInfo: verifyOtpErrorInfo,
  } = useSelector((state) => state.verifyOtpReducer) || {};

  const handleSendOtp = (identifier) => {
    setIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(sendOtpAction({ identifier: values.email }));
        break;
      case "phoneNumber":
        dispatch(sendOtpAction({ identifier: values.phoneNumber }));
        break;
      default:
        toast.error("Please try again");
    }
  };

  const handleVerifyOtp = (identifier) => {
    setVerifyIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(
          verifyOtpAction({
            identifier: values.email,
            otp: values.emailOtp,
          })
        );
        break;
      case "phoneNumber":
        dispatch(
          verifyOtpAction({
            identifier: values.phoneNumber,
            otp: values.phoneOtp,
          })
        );
        break;
      default:
        toast.error("Please try again");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  // here successData is from different reducer for verify page and signup page
  const {  successData, error, errorInfo } =
    useSelector((state) => state.registrationData) || {};

  const {
    successData: globalSuccessData,
  } = useSelector((state) => state.globalConfigData) || {};



  const initialValues = {
    pan: "",
    confirmPanNumber:"",
    date: "",
    businessname: "",
    company: {},
    industry: {},
    partner: {},
    city: "",
    password: "",
    name: "",
    confirmPassword: "",
    district:"",
    state: "",
    pin:"",
    phoneNumber: "",
    email: "",
    termsAndConditions: false,
    authorizedDetails: false,
    authorizedName:"",
    designation:{value: '', label: '',},
    authorizedMobileNumber:"",
  };

  const Signup = Yup.object().shape({
    pan: Yup.string()
      .length(10, "PAN must be exactly 10 characters")
      .required("Please enter your Pan* "),

    confirmPanNumber: Yup.string()
    .oneOf([Yup.ref('pan'), null], 'PAN numbers must match') 
    .required('Please confirm your PAN*'),

    date: Yup.date()
      .required("Date of Incorporation is required*"),
    businessname: Yup.string().required("Business Name is required*"),
    phoneNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
      .matches(
        /^\d*$/,
        "Phone number should not contain alphabetic characters"
      )
      .required("Please enter a valid 10-digit phone number"),
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email address")
       .required("Please enter a valid email address"),
   
    password: Yup.string()
      .matches( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,15}$/,'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 to 15 characters long.')
      .min(8).required("Please enter your password * "),
    confirmPassword: Yup.string()
      .min(8)
       .oneOf([Yup.ref('password'), null], 'Passwords must match')
       .required('Please confirm your password * '),
   
    name: Yup.string().required("Please enter owner name* "),
    
    pin: Yup.string()
    .matches(/^[1-9]\d{5}$/, "Postal code must be a 6-digit number starting with a non-zero digit")
    .required("Postal code is required"),


    industry: Yup.object({
      value: Yup.string().required("Please select industry type"),
      label: Yup.string().required(),
    }).required("Please select industry type"),
    company: Yup.object({
      value: Yup.string().required("Please select company type"),
      label: Yup.string().required(),
    }).required("Please select company type"),
    partner: Yup.object({
      value: Yup.string().required("Please select partner type"),
      label: Yup.string().required(),
    }).required("Please select partner type"),


    state: Yup.string().required("Please Enter your State"),
    
    district: Yup.string().required("Please enter your city* "),

    city: Yup.string().required("Please enter your city* "),

    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions*"
    ),
    emailOtp: Yup.string()
    .length(6, 'OTP must be exactly 6 digits'),
    phoneOtp: Yup.string()
    .length(6, 'OTP must be exactly 6 digits'),
    authorizedMobileNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
    .nullable(true),
    authorizedName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Name should contain only letters")
    .nullable(true),
  });

  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaChecked,setCaptchaChecked] = useState(false)


  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaChecked(false)
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Signup,
    validateOnChange: true,
    validateOnBlur: true,
    

    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {

      if (!captchaValue) {
        setCaptchaChecked(true);
        return;
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password not matched";
        toast.error("Passwords not matched");
      } else {
        const userData = {
          user: {
            email: values.email,
            user_type: values.partner.value,
            first_name: values.name,
            pan_number: values.pan,
            phone_number: values.phoneNumber,
            password1: values.password,
            password2: values.confirmPassword,
            captcha: captchaValue
          },
          business: {
            industry: values.industry.value,
            company: values.company.value,
            name: values.businessname,
            pan_number: values.pan,
            city: values.city,
            incorporation_date: values.date,
            state: values.state,
            district: values.district,
            pin:values.pin,
            authorized_user_name:values.authorizedName,
            authorized_user_phone_number:values.authorizedMobileNumber,
            authorized_user_designation:values.designation.value 
          },
        };
        if (values.email === "") {
          delete userData.user.email;
        }
        if (userData.business.incorporation_date === "") {
          delete userData.business.incorporation_date;
        }

        dispatch(registrationAction(userData));
      }
    },
  });

 const handlePinFetch = async () => {
    const pinCodeRegex = /^[1-9]\d{5}$/; // Regular expression for valid pincode

  if (!pinCodeRegex.test(values.pin)) {
    setPinError('Invalid Pincode'); // Set error message if pincode is invalid
    setFieldValue('');
    setFieldValue('');
    setFieldValue('');
    setIsFieldsReadOnly(false);
    return;
  }

  try {
    const response = await fetch(`https://api.postalpincode.in/pincode/${values.pin}`);
    const data = await response.json();
    if (data?.[0]?.PostOffice?.length > 0) {
      const postOffice = data[0].PostOffice[0];
      setFieldValue('district', postOffice.District);
      setFieldValue('state', postOffice.State);
      setFieldValue('city', postOffice.Block);
      setIsFieldsReadOnly(true); // Make fields read-only after fetching data
      setPinError(''); // Clear error message
    } else {
      setPinError('Pincode data not found'); // Set error message if no data found
    }
  } catch (error) {
    setPinError('Error fetching pincode data'); // Set error message on fetch error
  }
};
 
  
  useEffect(() => {
    if (sendOtpSuccessData && identifier) {
      if (identifier === "email") {
        toast.success("OTP sent to email");
        setEmailOtpSent(true);
        setEmailOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      } else if (identifier === "phoneNumber") {
        toast.success("OTP sent to phone number");
        setPhoneOtpSent(true);
        setPhoneOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      }
    } else if (sendOtpError) {
      toast.error(
        sendOtpErrorInfo || "Failed to send OTP"
      );
      dispatch(clearSendOtpData());
    }
  }, [sendOtpSuccessData, sendOtpError, sendOtpErrorInfo, identifier,dispatch]);

  useEffect(() => {
    if (emailOtpSent && emailOtpTimer > 0) {
      const timer = setInterval(() => {
        setEmailOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (emailOtpTimer === 0) {
      setEmailOtpSent(false);
    }
  }, [emailOtpSent, emailOtpTimer]);

  useEffect(() => {
    if (phoneOtpSent && phoneOtpTimer > 0) {
      const timer = setInterval(() => {
        setPhoneOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (phoneOtpTimer === 0) {
      setPhoneOtpSent(false);
    }
  }, [phoneOtpSent, phoneOtpTimer]);

  useEffect(() => {
    if (verifyOtpSuccessData && verifyIdentifier) {
      if (verifyIdentifier === "email") {
        toast.success("Email verified successfully");
        setEmailOtpSent(false);
        setIsEmailVerified(true);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      } else if (verifyIdentifier === "phoneNumber") {
        toast.success("Phone number verified successfully");
        setIsPhoneVerified(true);
        setPhoneOtpSent(false);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      }
    } else if (verifyOtpError) {
      toast.error(
        verifyOtpErrorInfo || "Failed to verify OTP"
        );
      dispatch(clearVerifyOtpData());
    }
  }, [
    verifyOtpSuccessData,
    verifyOtpError,
    verifyOtpErrorInfo,
    verifyIdentifier,
    dispatch,
  ]);

  React.useEffect(() => {
    localStorage.removeItem("token");
    dispatch(globalConfigAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (globalSuccessData) {
      setCompanyTypes(
        getLableAndValuesFromData(globalSuccessData.company_type)
      );
      setIndustryTypes(
        getLableAndValuesFromData(globalSuccessData.industry_type)
      );
      setPartnerTypes(
        getLableAndValuesFromData(globalSuccessData.partner_type)
      );
    }
  }, [globalSuccessData]);


  // Add the "None" option to the industry, partner, and company type fields
  const industryOptions = [...industryTypes];
  const partnerOptions = [...partnerTypes];
  const companyOptions = [...companyTypes];

  //verify page states and functionality

  React.useEffect(() => {
    return () => dispatchAll([clearRegistrationData], dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    if (successData) {
      localStorage.setItem("token", successData?.data?.key);
      localStorage.setItem("id", successData?.data?.user.id);
      localStorage.setItem("is_subscription_active", successData.data?.is_subscription_active || false);
      localStorage.setItem("is_company", successData.data?.user?.business?.is_company);
      navigate("/subscription");
      toast.success("User Registration is successful");

    }
  }, [successData,navigate]);

  React.useEffect(() => {
    if (error) {
      toast.error(errorInfo || "User Already Exist...");
      if (
        errorInfo.includes("duplicate key value violates unique") ||
        errorInfo.includes("Phone Number already exists")
      ) {
        setPhoneOtpSent(false);
        setIsPhoneVerified(false);
      }
    }
  }, [errorInfo, error]);

  

  return (
    <>
      <div className="my-4">
        <h1 className="text-center"> SIGNUP/ JOIN US</h1>
        <p className="text-center">Create your account</p>
      </div>

      <Container sx={{ position: "relative" }}>
        {/* buton to goback to gst flow */}
        <Button
          onClick={() => {
            handleFormChange();
          }}
          color="primary"
          sx={{ padding: 0, marginBottom: 2 }}
        >
          <Typography variant="h6" component="h1">
            Register with GSTIN?
          </Typography>
        </Button>

        <form onSubmit={handleSubmit} className="form-style">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label htmlFor="pan" className="form-label">
                PAN Number
              </label>
              <input
                type="text"
                name="pan"
                value={values.pan}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                onBlur={handleBlur}
                className="form-control"
                id="pan"
                placeholder="Pan"
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="pan"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="confirmPanNumber" className="form-label">
                Confirm PAN Number
              </label>
              <input
                type="text"
                name="confirmPanNumber"
                value={values.confirmPanNumber}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                onBlur={handleBlur}
                className="form-control"
                id="confirmPanNumber"
                placeholder="Confirm Pan Number"
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="confirmPanNumber"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Owner Name
              </label>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                onBlur={handleBlur}
                className="form-control"
                id="name"
                placeholder="Owner Name"
              />

              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Business Name
              </label>
              <input
                type="text"
                name="businessname"
                value={values.businessname}
                onChange={handleChange}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                onBlur={handleBlur}
                className="form-control"
                id="businessname"
                placeholder="Business Name"
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="businessname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="date" className="form-label">
                Date of Incorporation
              </label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
                id="date"
                placeholder=""
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Industry
              </label>
              <Select
                defaultValue={values.industry}
                value={values.industry}
                name="industry"
                id="industry"
                onChange={(selectedIndustry) =>
                  setFieldValue("industry", selectedIndustry)
                }
                options={industryOptions} // Use the updated options array
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="industry"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="exampleFormControlInput2" >
                Partner Type
              </label>
              <Select
                className="form-label-partnerType"
                defaultValue={values.partner}
                name="partner"
                id="partner"
                value={values.partner}
                onChange={(selectedPartner) =>
                  setFieldValue("partner", selectedPartner)
                }
                options={partnerOptions} // Use the updated options array
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="partner"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="exampleFormControlInput3" className="form-label">
                Company Type
              </label>
              <Select
                defaultValue={values.company}
                name="company"
                id="company"
                value={values.company}
                onChange={(selectedCompany) =>
                  setFieldValue("company", selectedCompany)
                }
                options={companyOptions} // Use the updated options array
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="company"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="pin" className="form-label">
                Pin Number
              </label>
              <div className="input-group">
                <input
                  type="text"
                  name="pin"
                  value={values.pin}
                  onChange={handleChange}
                  className="form-control"
                  id="pin"
                  placeholder="Enter PIN Number"
                  maxLength={6}
                  minLength={6}
                  onBlur={handlePinFetch}
                />  
              </div>
              {pinError && <p className="form-error error-message">{pinError}</p>}
               <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="pin"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="district" className="form-label">
                District
              </label>
              <input
                type="text"
                name="district"
                value={values.district}
                onChange={handleChange}
                className="form-control"
                id="district"
                placeholder="District"
                readOnly={isFieldsReadOnly} 
              />
               <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="district"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                className="form-control"
                id="city"
                placeholder="City"
                 
              />
               <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="city"
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                name="state"
                value={values.state}
                onChange={handleChange}
                className="form-control"
                id="state"
                placeholder="State"
                readOnly={isFieldsReadOnly} 
              />
               <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="state"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="email" className="form-label align-verify-icon">
                Email ID{" "}
                {isEmailVerified && (
                  <CheckCircleIcon
                    style={{ color: "green", marginBottom: "-4px" }}
                  />
                )}
              </label>
              <input
                type="email"
                name="email"
                className="form-control mb-2"
                autoComplete="off"
                value={values.email}
                id="email"
                placeholder="Email ID"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={emailOtpSent || isEmailVerified}
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="email"
              />
              {!errors.email && values.email && (
                <>
                  {emailOtpSent && (
                    <>
                      <span>OTP Expires in :</span>{" "}
                      <span className="text-danger">
                        {Math.floor(emailOtpTimer / 60)}:
                        {emailOtpTimer % 60 < 10 ? "0" : ""}
                        {emailOtpTimer % 60}
                      </span>
                      {"   "}
                    </>
                  )}
                  <div className="signup-otp-container">
                    {!emailOtpSent && !isEmailVerified && (
                      <button
                        type="button"
                        className="signup-otp-button"
                        disabled={sendOtpLoading}
                        onClick={() => handleSendOtp("email")}
                      >
                        {sendOtpLoading ? (
                          <CircularProgress
                            size={24}
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Send OTP"
                        )}
                      </button>
                    )}
                    {emailOtpSent && (
                      <>
                        <input
                          type="text"
                          name="emailOtp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="signup-otp-input"
                          placeholder="OTP sent to email"
                        />

                        <button
                          type="button"
                          className="signup-otp-button"
                          disabled={
                            verifyOtpLoading || values.emailOtp?.length !== 6
                          }
                          onClick={() => handleVerifyOtp("email")}
                        >
                          Submit OTP
                        </button>
                      </>
                    )}
                  </div>
                  {emailOtpSent && (
                    <>
                      {errors.emailOtp && touched.emailOtp && (
                        <p className="form-error error-message remove-margin">
                          {errors.emailOtp}
                        </p>
                      )}
                      <span className="signup-otp-timer">
                        <button
                          className="signup-otp-action"
                          onClick={() => {
                            handleSendOtp("email");
                            setEmailOtpSent(false);
                          }}
                        >
                          Resend OTP
                        </button>{" "}
                        or
                        <button
                          className="signup-otp-action"
                          onClick={() => setEmailOtpSent(false)}
                        >
                          {" "}
                          Change email
                        </button>
                      </span>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="phoneNumber" className="form-label">
                Mobile Number{" "}
                {isPhoneVerified && (
                  <CheckCircleIcon
                    style={{ color: "green", marginBottom: "-4px" }}
                  />
                )}
              </label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Mobile Number"
                className="form-control mb-2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                id="phoneNumber"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
                disabled={phoneOtpSent || isPhoneVerified}
              />
              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="phoneNumber"
              />

              {!errors.phoneNumber && values.phoneNumber && (
                <>
                  {phoneOtpSent && (
                    <>
                      <span>OTP Expires in :</span>{" "}
                      <span className="text-danger">
                        {Math.floor(phoneOtpTimer / 60)}:
                        {phoneOtpTimer % 60 < 10 ? "0" : ""}
                        {phoneOtpTimer % 60}
                      </span>
                      {"   "}
                    </>
                  )}

                  <div className="signup-otp-container">
                    {!phoneOtpSent && !isPhoneVerified && (
                      <button
                        type="button"
                        className="signup-otp-button"
                        onClick={() => handleSendOtp("phoneNumber")}
                      >
                        {sendOtpLoading ? (
                          <CircularProgress
                            size={24}
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Send OTP"
                        )}
                      </button>
                    )}
                    {phoneOtpSent && (
                      <>
                        <input
                          type="text"
                          name="phoneOtp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="signup-otp-input"
                          placeholder="OTP sent to phone"
                        />

                        <button
                          type="button"
                          className="signup-otp-button"
                          onClick={() => handleVerifyOtp("phoneNumber")}
                          disabled={
                            verifyOtpLoading || values.phoneOtp?.length !== 6
                          }
                        >
                          Submit OTP
                        </button>
                      </>
                    )}
                  </div>
                  {phoneOtpSent && (
                    <>
                      {errors.phoneOtp && touched.phoneOtp && (
                        <p className="form-error error-message remove-margin">
                          {errors.phoneOtp}
                        </p>
                      )}
                      <span className="signup-otp-timer">
                        <button
                          className="signup-otp-action"
                          onClick={() => {
                            handleSendOtp("phoneNumber");
                            setPhoneOtpSent(false);
                          }}
                        >
                          Resend OTP
                        </button>{" "}
                        or
                        <button
                          className="signup-otp-action"
                          onClick={() => setPhoneOtpSent(false)}
                        >
                          {" "}
                          Change mobile number
                        </button>
                      </span>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  style={{ paddingRight: "40px" }} // Make room for the eye icon
                />
                <button
                  type="button"
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </button>
              </div>

              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  name="confirmPassword"
                  type={confirmShowPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  id="confirmpassword"
                  placeholder="Confirmpassword"
                  style={{ paddingRight: "40px" }}
                />
                <button
                  type="button"
                  className="eye-icon"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {confirmShowPassword ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </button>
              </div>

              <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="confirmPassword"
              />
            </Grid>
          </Grid>
          <Grid item>
            <ReCAPTCHA
              sitekey ={SITE_KEY}
              onChange={handleRecaptchaChange}
            />
            {captchaChecked && <p style={{color:'red'}}>please complete the captcha*</p>}
          </Grid>
          <Stack direction="row" alignItems="center">
            <Checkbox
              name="authorizedDetails"
              id="authorizedDetails"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.authorizedDetails}
              style={{ paddingLeft: 0 }}
            />
            <div>
              <label
                htmlFor="authorizedDetails"
                className="form-label remove-margin-bottom"
              >
                Would you Like to Add Authorized Person Details?
              </label>
            </div>
          </Stack>
          {values.authorizedDetails && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label htmlFor="authorizedName" className="form-label">
                  Authorized Name
                </label>
                <input
                  name="authorizedName"
                  type="text"
                  value={values.authorizedName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  id="authorizedName"
                  placeholder="Authorized Name"
                />
                <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="authorizedName"
              />
              </Grid>

              <Grid item xs={12} sm={6}>
                <label htmlFor="designation" className="form-label">
                  Designation
                </label>
                <Select
                  defaultValue={values.designation}
                  value={values.designation}
                  name="designation"
                  id="designation"
                  onChange={(selectedDesignation) =>
                    setFieldValue("designation", selectedDesignation)
                  }
                  options={designationOptions}
                />
              </Grid>


              <Grid item xs={12} sm={6}>
                <label htmlFor="authorizedMobileNumber" className="form-label">
                  Mobile Number
                </label>
                <input
                  name="authorizedMobileNumber"
                  type="text"
                  value={values.authorizedMobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  id="authorizedMobileNumber"
                  placeholder="Mobile Number"
                />
                <FormFieldError
                errors={errors}
                touched={touched}
                fieldName="authorizedMobileNumber"
              />
              </Grid>
            </Grid>
          )}
        
          <Stack direction="row" alignItems="center">
            <Checkbox
              name="termsAndConditions"
              id="termsAndConditions"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.termsAndConditions}
              style={{ paddingLeft: 0 }}
            />
            <div>
              <label
                htmlFor="termsAndConditions"
                className="form-label remove-margin-bottom"
              >
                By Clicking here you agree to our{" "}
                <a href="about/terms" target="_blank">
                  Terms and Conditions
                </a>
              </label>
            </div>
          </Stack>
          {/* error message for checkbox*/}
          {errors.termsAndConditions && touched.termsAndConditions ? (
            <p className="form-error error-message remove-margin checkbox-error-message">
              {errors.termsAndConditions}
            </p>
          ) : null}

          <div className="text-center">
            <Tooltip
              title={
                !isPhoneVerified && !isEmailVerified
                  ? "Verify mobile and email to register"
                  : ""
              }
              placement="top"
            >
              <span>
                <button
                  type="submit"
                  className="btn btn-primary signup-button"
                  disabled={!isPhoneVerified || !isEmailVerified}
                >
                  Submit
                </button>
              </span>
            </Tooltip>
          </div>

          <div className="text-center mb-4 pb-4">
            <span>Already on ClearBiz? </span> <a href="/Login">Login</a>
          </div>
        </form>
      </Container>
    </>
  );
}

export default Signupwithpan;


Signupwithpan.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  
};


FormFieldError.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.any.isRequired,
  fieldName:PropTypes.string.isRequired

};