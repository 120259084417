import sliceCreator from '../index.js';
import {MY_RATINGS} from "../../../constants"
const _getMyRatingAction = sliceCreator('MY_RATINGS', MY_RATINGS, 'get');
const { asyncAction: getMyRatingAction, reducer, clearData: clearGetMyRatingActionData } = _getMyRatingAction;

export default reducer;

export {
    getMyRatingAction,
    clearGetMyRatingActionData
}

