import sliceCreator from '../index.js';
import {PAYMENTS} from "../../../constants/index.js"

const _postPaymentAction = sliceCreator('TRANSACTIONS', PAYMENTS, 'post');

const { asyncAction: postPayment, reducer, clearData: clearPostPaymentData } = _postPaymentAction;

export default reducer;

export {
    postPayment,
    clearPostPaymentData
}
