import sliceCreator from '../index.js';
import {Edit_Received_Rating} from "../../../constants/index.js"

const _editReceivedRatingAction = sliceCreator('Edit_Received_Rating', Edit_Received_Rating, 'patch');

const { asyncAction: editReceivedRatingAction, reducer, clearData: clearEditReceivedRatingAction } = _editReceivedRatingAction;

export default reducer;

export {
    editReceivedRatingAction,
    clearEditReceivedRatingAction
}