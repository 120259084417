
import sliceCreator from '../index.js';
import {REGISTRATION} from "../../../constants"
const _registrationAction = sliceCreator('REGISTRATION_ACTION', REGISTRATION, 'post');

const { asyncAction: registrationAction, reducer, clearData: clearRegistrationData } = _registrationAction;

export default reducer;

export {
    registrationAction,
    clearRegistrationData
}


