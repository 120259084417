const statesData = [
   {    "label": "Andaman Nicobar",
        "value": "Andaman Nicobar",
        "districts": [      {
            "label": "Nicobar",
            "value": "Nicobar"
        },
        {
            "label": "North Middle Andaman",
            "value": "North Middle Andaman"
        },
        {
            "label": "South Andaman",
            "value": "South Andaman"
        }
        ]
 },
 {
   "label": "Andhra Pradesh",
   "value": "Andhra Pradesh",
   "districts": [
     {
       "label": "Anantapur",
       "value": "Anantapur"
     },
     {
       "label": "Chittoor",
       "value": "Chittoor"
     },
     {
       "label": "East Godavari",
       "value": "East Godavari"
     },
     {
       "label": "Alluri Sitarama Raju",
       "value": "Alluri Sitarama Raju"
     },
     {
       "label": "Anakapalli",
       "value": "Anakapalli"
     },
     {
       "label": "Annamaya",
       "value": "Annamaya"
     },
     {
       "label": "Bapatla",
       "value": "Bapatla"
     },
     {
       "label": "Eluru",
       "value": "Eluru"
     },
     {
       "label": "Guntur",
       "value": "Guntur"
     },
     {
       "label": "Kadapa",
       "value": "Kadapa"
     },
     {
       "label": "Kakinada ",
       "value": "Kakinada "
     },
     {
       "label": "Konaseema",
       "value": "Konaseema"
     },
     {
       "label": "Krishna ",
       "value": "Krishna "
     },
     {
       "label": "Kurnool",
       "value": "Kurnool"
     },
     {
       "label": "Manyam",
       "value": "Manyam"
     },
     {
       "label": "N T Rama Rao",
       "value": "N T Rama Rao"
     },
     {
       "label": "Nandyal",
       "value": "Nandyal"
     },
     {
       "label": "Nellore",
       "value": "Nellore"
     },
     {
       "label": "Palnadu",
       "value": "Palnadu"
     },
     {
       "label": "Prakasam",
       "value": "Prakasam"
     },
     {
       "label": "Sri Balaji",
       "value": "Sri Balaji"
     },
     {
       "label": "Sri Balaji",
       "value": "Sri Balaji"
     },
     {
       "label": "Sri Satya Sai",
       "value": "Sri Satya Sai"
     },
     {
       "label": "Srikakulam",
       "value": "Srikakulam"
     },
     {
       "label": "Visakhapatnam",
       "value": "Visakhapatnam"
     },
     {
       "label": "Vizianagaram",
       "value": "Vizianagaram"
     },
     {
       "label": "West Godavari",
       "value": "West Godavari"
     }
   ]
 },
{
   "label": "Arunachal Pradesh",
   "value": "Arunachal Pradesh",
   "districts": [
     {
       "label": "Anjaw",
       "value": "Anjaw"
     },
     {
       "label": "Central Siang",
       "value": "Central Siang"
     },
     {
       "label": "Changlang",
       "value": "Changlang"
     },
     {
       "label": "Dibang Valley",
       "value": "Dibang Valley"
     },
     {
       "label": "East Kameng",
       "value": "East Kameng"
     },
     {
       "label": "East Siang",
       "value": "East Siang"
     },
     {
       "label": "Kamle",
       "value": "Kamle"
     },
     {
       "label": "Kra Daadi",
       "value": "Kra Daadi"
     },
     {
       "label": "Kurung Kumey",
       "value": "Kurung Kumey"
     },
     {
       "label": "Lepa Rada",
       "value": "Lepa Rada"
     },
     {
       "label": "Lohit",
       "value": "Lohit"
     },
     {
       "label": "Longding",
       "value": "Longding"
     },
     {
       "label": "Lower Dibang Valley",
       "value": "Lower Dibang Valley"
     },
     {
       "label": "Lower Siang",
       "value": "Lower Siang"
     },
     {
       "label": "Lower Subansiri",
       "value": "Lower Subansiri"
     },
     {
       "label": "Namsai",
       "value": "Namsai"
     },
     {
       "label": "Pakke Kessang",
       "value": "Pakke Kessang"
     },
     {
       "label": "Papum Pare",
       "value": "Papum Pare"
     },
     {
       "label": "Shi Yomi",
       "value": "Shi Yomi"
     },
     {
       "label": "Tawang",
       "value": "Tawang"
     },
     {
       "label": "Tirap",
       "value": "Tirap"
     },
     {
       "label": "Upper Siang",
       "value": "Upper Siang"
     },
     {
       "label": "Upper Subansiri",
       "value": "Upper Subansiri"
     },
     {
       "label": "West Kameng",
       "value": "West Kameng"
     },
     {
       "label": "West Siang",
       "value": "West Siang"
     }
   ]
},
{
  "label": "Assam",
  "value": "Assam",
  "districts": [
     {
        "label": "Baksa",
        "value": "Baksa"
     },
     {
        "label": "Barpeta",
        "value": "Barpeta"
     },
     {
        "label": "Bongaigaon",
        "value": "Bongaigaon"
     },
     {
        "label": "Cachar",
        "value": "Cachar"
     },
     {
        "label": "Charaideo",
        "value": "Charaideo"
     },
     {
        "label": "Chirang",
        "value": "Chirang"
     },
     {
        "label": "Darrang",
        "value": "Darrang"
     },
     {
        "label": "Dhemaji",
        "value": "Dhemaji"
     },
     {
        "label": "Dhubri",
        "value": "Dhubri"
     },
     {
        "label": "Dibrugarh",
        "value": "Dibrugarh"
     },
     {
        "label": "Dima Hasao",
        "value": "Dima Hasao"
     },
     {
        "label": "Goalpara",
        "value": "Goalpara"
     },
     {
        "label": "Golaghat",
        "value": "Golaghat"
     },
     {
        "label": "Hailakandi",
        "value": "Hailakandi"
     },
     {
        "label": "Jorhat",
        "value": "Jorhat"
     },
     {
        "label": "Kamrup",
        "value": "Kamrup"
     },
     {
        "label": "Kamrup Metropolitan",
        "value": "Kamrup Metropolitan"
     },
     {
        "label": "Karbi Anglong",
        "value": "Karbi Anglong"
     },
     {
        "label": "Karimganj",
        "value": "Karimganj"
     },
     {
        "label": "Kokrajhar",
        "value": "Kokrajhar"
     },
     {
        "label": "Lakhimpur",
        "value": "Lakhimpur"
     },
     {
        "label": "Majuli",
        "value": "Majuli"
     },
     {
        "label": "Morigaon",
        "value": "Morigaon"
     },
     {
        "label": "Nagaon",
        "value": "Nagaon"
     },
     {
        "label": "Nalbari",
        "value": "Nalbari"
     },
     {
        "label": "Sivasagar",
        "value": "Sivasagar"
     },
     {
        "label": "Sonitpur",
        "value": "Sonitpur"
     },
     {
        "label": "South Salmara-Mankachar",
        "value": "South Salmara-Mankachar"
     },
     {
        "label": "Tinsukia",
        "value": "Tinsukia"
     },
     {
        "label": "Udalguri",
        "value": "Udalguri"
     },
     {
        "label": "West Karbi Anglong",
        "value": "West Karbi Anglong"
     }
   ]
},
{
   "label": "Bihar",
   "value": "Bihar",
   "districts": [
     {
       "label": "Araria",
       "value": "Araria"
     },
     {
       "label": "Arwal",
       "value": "Arwal"
     },
     {
       "label": "Aurangabad",
       "value": "Aurangabad"
     },
     {
       "label": "Banka",
       "value": "Banka"
     },
     {
       "label": "Begusarai",
       "value": "Begusarai"
     },
     {
       "label": "Bhagalpur",
       "value": "Bhagalpur"
     },
     {
       "label": "Bhojpur",
       "value": "Bhojpur"
     },
     {
       "label": "Buxar",
       "value": "Buxar"
     },
     {
       "label": "Darbhanga",
       "value": "Darbhanga"
     },
     {
       "label": "East Champaran",
       "value": "East Champaran"
     },
     {
       "label": "Gaya",
       "value": "Gaya"
     },
     {
       "label": "Gopalganj",
       "value": "Gopalganj"
     },
     {
       "label": "Jamui",
       "value": "Jamui"
     },
     {
       "label": "Jehanabad",
       "value": "Jehanabad"
     },
     {
       "label": "Kaimur",
       "value": "Kaimur"
     },
     {
       "label": "Katihar",
       "value": "Katihar"
     },
     {
       "label": "Khagaria",
       "value": "Khagaria"
     },
     {
       "label": "Kishanganj",
       "value": "Kishanganj"
     },
     {
       "label": "Lakhisarai",
       "value": "Lakhisarai"
     },
     {
       "label": "Madhepura",
       "value": "Madhepura"
     },
     {
       "label": "Madhubani",
       "value": "Madhubani"
     },
     {
       "label": "Munger",
       "value": "Munger"
     },
     {
       "label": "Muzaffarpur",
       "value": "Muzaffarpur"
     },
     {
       "label": "Nalanda",
       "value": "Nalanda"
     },
     {
       "label": "Nawada",
       "value": "Nawada"
     },
     {
       "label": "Patna",
       "value": "Patna"
     },
     {
       "label": "Purnia",
       "value": "Purnia"
     },
     {
       "label": "Rohtas",
       "value": "Rohtas"
     },
     {
       "label": "Saharsa",
       "value": "Saharsa"
     },
     {
       "label": "Samastipur",
       "value": "Samastipur"
     },
     {
       "label": "Saran",
       "value": "Saran"
     },
     {
       "label": "Sheikhpura",
       "value": "Sheikhpura"
     },
     {
       "label": "Sheohar",
       "value": "Sheohar"
     },
     {
       "label": "Sitamarhi",
       "value": "Sitamarhi"
     },
     {
       "label": "Siwan",
       "value": "Siwan"
     },
     {
       "label": "Supaul",
       "value": "Supaul"
     },
     {
       "label": "Vaishali",
       "value": "Vaishali"
     },
     {
       "label": "West Champaran",
       "value": "West Champaran"
     }
   ]
},
{
 "label": "Chandigarh",
 "value": "Chandigarh",
 "districts": [
   {
     "label": "Balod",
     "value": "Balod"
   },
   {
     "label": "Baloda Bazar",
     "value": "Baloda Bazar"
   },
   {
     "label": "Balrampur",
     "value": "Balrampur"
   },
   {
     "label": "Bastar",
     "value": "Bastar"
   },
   {
     "label": "Bemetara",
     "value": "Bemetara"
   },
   {
     "label": "Bijapur",
     "value": "Bijapur"
   },
   {
     "label": "Bilaspur",
     "value": "Bilaspur"
   },
   {
     "label": "Dantewada",
     "value": "Dantewada"
   },
   {
     "label": "Dhamtari",
     "value": "Dhamtari"
   },
   {
     "label": "Durg",
     "value": "Durg"
   },
   {
     "label": "Gariaband",
     "value": "Gariaband"
   },
   {
     "label": "Gaurela Pendra Marwahi",
     "value": "Gaurela Pendra Marwahi"
   },
   {
     "label": "Janjgir Champa",
     "value": "Janjgir Champa"
   },
   {
     "label": "Jashpur",
     "value": "Jashpur"
   },
   {
     "label": "Kabirdham",
     "value": "Kabirdham"
   },
   {
     "label": "Kanker",
     "value": "Kanker"
   },
   {
     "label": "Khairagarh",
     "value": "Khairagarh"
   },
   {
     "label": "Kondagaon",
     "value": "Kondagaon"
   },
   {
     "label": "Korba",
     "value": "Korba"
   },
   {
     "label": "Koriya",
     "value": "Koriya"
   },
   {
     "label": "Mahasamund",
     "value": "Mahasamund"
   },
   {
     "label": "Manendragarh",
     "value": "Manendragarh"
   },
   {
     "label": "Mohla Manpur",
     "value": "Mohla Manpur"
   },
   {
     "label": "Mungeli",
     "value": "Mungeli"
   },
   {
     "label": "Narayanpur",
     "value": "Narayanpur"
   },
   {
     "label": "Raigarh",
     "value": "Raigarh"
   },
   {
     "label": "Raipur",
     "value": "Raipur"
   },
   {
     "label": "Rajnandgaon",
     "value": "Rajnandgaon"
   },
   {
     "label": "Sakti",
     "value": "Sakti"
   },
   {
     "label": "Sarangarh Bilaigarh",
     "value": "Sarangarh Bilaigarh"
   },
   {
     "label": "Sukma",
     "value": "Sukma"
   },
   {
     "label": "Surajpur",
     "value": "Surajpur"
   },
   {
     "label": "Surguja",
     "value": "Surguja"
   }
]
},
{
 "label": "Daman and Diu",
 "value": "DNHDD",
 "districts": [
   {
     "label": "Dadra and Nagar Haveli",
     "value": "Dadra and Nagar Haveli"
   },
   {
     "label": "Daman",
     "value": "Daman"
   },
   {
     "label": "Diu",
     "value": "Diu"
   }
]
},
{
 "label": "Delhi",
 "value": "Delhi",
 "districts": [
   {
     "label": "Central Delhi",
     "value": "Central Delhi"
   },
   {
     "label": "East Delhi",
     "value": "East Delhi"
   },
   {
     "label": "New Delhi",
     "value": "New Delhi"
   },
   {
     "label": "North Delhi",
     "value": "North Delhi"
   },
   {
     "label": "North East Delhi",
     "value": "North East Delhi"
   },
   {
     "label": "North West Delhi",
     "value": "North West Delhi"
   },
   {
     "label": "Shahdara",
     "value": "Shahdara"
   },
   {
     "label": "South Delhi",
     "value": "South Delhi"
   },
   {
     "label": "South East Delhi",
     "value": "South East Delhi"
   },
   {
     "label": "South West Delhi",
     "value": "South West Delhi"
   },
   {
     "label": "West Delhi",
     "value": "West Delhi"
   }
]
},
{
 "label": "Goa",
 "value": "Goa",
 "districts": [
   {
     "label": "North Goa",
     "value": "North Goa"
   },
   {
     "label": "South Goa",
     "value": "South Goa"
   }]
},
{
 "label": "Gujarat",
 "value": "Gujarat",
 "districts": [
   {
     "label": "Ahmedabad",
     "value": "Ahmedabad"
   },
   {
     "label": "Amreli",
     "value": "Amreli"
   },
   {
     "label": "Anand",
     "value": "Anand"
   },
   {
     "label": "Aravalli",
     "value": "Aravalli"
   },
   {
     "label": "Banaskantha",
     "value": "Banaskantha"
   },
   {
     "label": "Bharuch",
     "value": "Bharuch"
   },
   {
     "label": "Bhavnagar",
     "value": "Bhavnagar"
   },
   {
     "label": "Botad",
     "value": "Botad"
   },
   {
     "label": "Chhota Udaipur",
     "value": "Chhota Udaipur"
   },
   {
     "label": "Dahod",
     "value": "Dahod"
   },
   {
     "label": "Dang",
     "value": "Dang"
   },
   {
     "label": "Devbhoomi Dwarka",
     "value": "Devbhoomi Dwarka"
   },
   {
     "label": "Gandhinagar",
     "value": "Gandhinagar"
   },
   {
     "label": "Gir Somnath",
     "value": "Gir Somnath"
   },
   {
     "label": "Jamnagar",
     "value": "Jamnagar"
   },
   {
     "label": "Junagadh",
     "value": "Junagadh"
   },
   {
     "label": "Kheda",
     "value": "Kheda"
   },
   {
     "label": "Kutch",
     "value": "Kutch"
   },
   {
     "label": "Mahisagar",
     "value": "Mahisagar"
   },
   {
     "label": "Mehsana",
     "value": "Mehsana"
   },
   {
     "label": "Morbi",
     "value": "Morbi"
   },
   {
     "label": "Narmada",
     "value": "Narmada"
   },
   {
     "label": "Navsari",
     "value": "Navsari"
   },
   {
     "label": "Panchmahal",
     "value": "Panchmahal"
   },
   {
     "label": "Patan",
     "value": "Patan"
   },
   {
     "label": "Porbandar",
     "value": "Porbandar"
   },
   {
     "label": "Rajkot",
     "value": "Rajkot"
   },
   {
     "label": "Sabarkantha",
     "value": "Sabarkantha"
   },
   {
     "label": "Surat",
     "value": "Surat"
   },
   {
     "label": "Surendranagar",
     "value": "Surendranagar"
   },
   {
     "label": "Tapi",
     "value": "Tapi"
   },
   {
     "label": "Vadodara",
     "value": "Vadodara"
   },
   {
     "label": "Valsad",
     "value": "Valsad"
   }]
},
{
  "label": "Haryana",
  "value": "Haryana",
  "districts": [
     {
        "label": "Ambala",
        "value": "Ambala"
     },
     {
        "label": "Bhiwani",
        "value": "Bhiwani"
     },
     {
        "label": "Charkhi Dadri",
        "value": "Charkhi Dadri"
     },
     {
        "label": "Faridabad",
        "value": "Faridabad"
     },
     {
        "label": "Fatehabad",
        "value": "Fatehabad"
     },
     {
        "label": "Gurugram",
        "value": "Gurugram"
     },
     {
        "label": "Hisar",
        "value": "Hisar"
     },
     {
        "label": "Jhajjar",
        "value": "Jhajjar"
     },
     {
        "label": "Jind",
        "value": "Jind"
     },
     {
        "label": "Kaithal",
        "value": "Kaithal"
     },
     {
        "label": "Karnal",
        "value": "Karnal"
     },
     {
        "label": "Kurukshetra",
        "value": "Kurukshetra"
     },
     {
        "label": "Mahendragarh",
        "value": "Mahendragarh"
     },
     {
        "label": "Mewat",
        "value": "Mewat"
     },
     {
        "label": "Palwal",
        "value": "Palwal"
     },
     {
        "label": "Panchkula",
        "value": "Panchkula"
     },
     {
        "label": "Panipat",
        "value": "Panipat"
     },
     {
        "label": "Rewari",
        "value": "Rewari"
     },
     {
        "label": "Rohtak",
        "value": "Rohtak"
     },
     {
        "label": "Sirsa",
        "value": "Sirsa"
     },
     {
        "label": "Sonipat",
        "value": "Sonipat"
     },
     {
        "label": "Yamunanagar",
        "value": "Yamunanagar"
     }
   ]
},
{
   "label": "Himachal Pradesh",
   "value": "Himachal Pradesh",
   "districts": [
     {
       "label": "Bilaspur",
       "value": "Bilaspur"
     },
     {
       "label": "Chamba",
       "value": "Chamba"
     },
     {
       "label": "Hamirpur",
       "value": "Hamirpur"
     },
     {
       "label": "Kangra",
       "value": "Kangra"
     },
     {
       "label": "Kinnaur",
       "value": "Kinnaur"
     },
     {
       "label": "Kullu",
       "value": "Kullu"
     },
     {
       "label": "Lahaul Spiti",
       "value": "Lahaul Spiti"
     },
     {
       "label": "Mandi",
       "value": "Mandi"
     },
     {
       "label": "Shimla",
       "value": "Shimla"
     },
     {
       "label": "Sirmaur",
       "value": "Sirmaur"
     },
     {
       "label": "Solan",
       "value": "Solan"
     },
     {
       "label": "Una",
       "value": "Una"
     }
   ]
},
{
  "label": "Jammu and Kashmir",
  "value": "Jammu and Kashmir",
  "districts": [
     {
        "label": "Anantnag",
        "value": "Anantnag"
     },
     {
        "label": "Bandipora",
        "value": "Bandipora"
     },
     {
        "label": "Baramulla",
        "value": "Baramulla"
     },
     {
        "label": "Budgam",
        "value": "Budgam"
     },
     {
        "label": "Doda",
        "value": "Doda"
     },
     {
        "label": "Ganderbal",
        "value": "Ganderbal"
     },
     {
        "label": "Jammu",
        "value": "Jammu"
     },
     {
        "label": "Kathua",
        "value": "Kathua"
     },
     {
        "label": "Kishtwar",
        "value": "Kishtwar"
     },
     {
        "label": "Kulgam",
        "value": "Kulgam"
     },
     {
        "label": "Kupwara",
        "value": "Kupwara"
     },
     {
        "label": "Poonch",
        "value": "Poonch"
     },
     {
        "label": "Pulwama",
        "value": "Pulwama"
     },
     {
        "label": "Rajouri",
        "value": "Rajouri"
     },
     {
        "label": "Ramban",
        "value": "Ramban"
     },
     {
        "label": "Reasi",
        "value": "Reasi"
     },
     {
        "label": "Samba",
        "value": "Samba"
     },
     {
        "label": "Shopian",
        "value": "Shopian"
     },
     {
        "label": "Srinagar",
        "value": "Srinagar"
     },
     {
        "label": "Udhampur",
        "value": "Udhampur"
     }
   ]
},
{
     "label": "Jharkhand",
     "value": "Jharkhand",
     "districts": [
       {
         "label": "Bokaro",
         "value": "Bokaro"
       },
       {
         "label": "Chatra",
         "value": "Chatra"
       },
       {
         "label": "Deoghar",
         "value": "Deoghar"
       },
       {
         "label": "Dhanbad",
         "value": "Dhanbad"
       },
       {
         "label": "Dumka",
         "value": "Dumka"
       },
       {
         "label": "East Singhbhum",
         "value": "East Singhbhum"
       },
       {
         "label": "Garhwa",
         "value": "Garhwa"
       },
       {
         "label": "Giridih",
         "value": "Giridih"
       },
       {
         "label": "Godda",
         "value": "Godda"
       },
       {
         "label": "Gumla",
         "value": "Gumla"
       },
       {
         "label": "Hazaribagh",
         "value": "Hazaribagh"
       },
       {
         "label": "Jamtara",
         "value": "Jamtara"
       },
       {
         "label": "Khunti",
         "value": "Khunti"
       },
       {
         "label": "Koderma",
         "value": "Koderma"
       },
       {
         "label": "Latehar",
         "value": "Latehar"
       },
       {
         "label": "Lohardaga",
         "value": "Lohardaga"
       },
       {
         "label": "Pakur",
         "value": "Pakur"
       },
       {
         "label": "Palamu",
         "value": "Palamu"
       },
       {
         "label": "Ramgarh",
         "value": "Ramgarh"
       },
       {
         "label": "Ranchi",
         "value": "Ranchi"
       },
       {
         "label": "Sahebganj",
         "value": "Sahebganj"
       },
       {
         "label": "Seraikela Kharsawan",
         "value": "Seraikela Kharsawan"
       },
       {
         "label": "Simdega",
         "value": "Simdega"
       },
       {
         "label": "West Singhbhum",
         "value": "West Singhbhum"
       }
       ]
},
{
     "label":"Karnataka",
     "value":"Karnataka",
     "districts":[
        {
           "label":"Bagalkot",
           "value":"Bagalkot"
        },
        {
           "label":"Bangalore Rural",
           "value":"Bangalore Rural"
        },
        {
           "label":"Bangalore Urban",
           "value":"Bangalore Urban"
        },
        {
           "label":"Belgaum",
           "value":"Belgaum"
        },
        {
           "label":"Bellary",
           "value":"Bellary"
        },
        {
           "label":"Bidar",
           "value":"Bidar"
        },
        {
           "label":"Chamarajanagar",
           "value":"Chamarajanagar"
        },
        {
           "label":"Chikkaballapur",
           "value":"Chikkaballapur"
        },
        {
           "label":"Chikkamagaluru",
           "value":"Chikkamagaluru"
        },
        {
           "label":"Chitradurga",
           "value":"Chitradurga"
        },
        {
           "label":"Dakshina Kannada",
           "value":"Dakshina Kannada"
        },
        {
           "label":"Davanagere",
           "value":"Davanagere"
        },
        {
           "label":"Dharwad",
           "value":"Dharwad"
        },
        {
           "label":"Gadag",
           "value":"Gadag"
        },
        {
           "label":"Gulbarga",
           "value":"Gulbarga"
        },
        {
           "label":"Hassan",
           "value":"Hassan"
        },
        {
           "label":"Haveri",
           "value":"Haveri"
        },
        {
           "label":"Kodagu",
           "value":"Kodagu"
        },
        {
           "label":"Kolar",
           "value":"Kolar"
        },
        {
           "label":"Koppal",
           "value":"Koppal"
        },
        {
           "label":"Mandya",
           "value":"Mandya"
        },
        {
           "label":"Mysore",
           "value":"Mysore"
        },
        {
           "label":"Raichur",
           "value":"Raichur"
        },
        {
           "label":"Ramanagara",
           "value":"Ramanagara"
        },
        {
           "label":"Shimoga",
           "value":"Shimoga"
        },
        {
           "label":"Tumkur",
           "value":"Tumkur"
        },
        {
           "label":"Udupi",
           "value":"Udupi"
        },
        {
           "label":"Uttara Kannada",
           "value":"Uttara Kannada"
        },
        {
           "label":"Vijayanagara",
           "value":"Vijayanagara"
        },
        {
           "label":"Vijayapura",
           "value":"Vijayapura"
        },
        {
           "label":"Yadgir",
           "value":"Yadgir"
        }
       ]
},
{
 "label": "Kerala",
 "value": "Kerala",
 "districts": [
   {
     "label": "Alappuzha",
     "value": "Alappuzha"
   },
   {
     "label": "Ernakulam",
     "value": "Ernakulam"
   },
   {
     "label": "Idukki",
     "value": "Idukki"
   },
   {
     "label": "Kannur",
     "value": "Kannur"
   },
   {
     "label": "Kasaragod",
     "value": "Kasaragod"
   },
   {
     "label": "Kollam",
     "value": "Kollam"
   },
   {
     "label": "Kottayam",
     "value": "Kottayam"
   },
   {
     "label": "Kozhikode",
     "value": "Kozhikode"
   },
   {
     "label": "Malappuram",
     "value": "Malappuram"
   },
   {
     "label": "Palakkad",
     "value": "Palakkad"
   },
   {
     "label": "Pathanamthitta",
     "value": "Pathanamthitta"
   },
   {
     "label": "Thiruvananthapuram",
     "value": "Thiruvananthapuram"
   },
   {
     "label": "Thrissur",
     "value": "Thrissur"
   },
   {
     "label": "Wayanad",
     "value": "Wayanad"
   }]
},
{
  "label": "Ladakh",
  "value": "Ladakh",
  "districts": [
     {
        "label": "Kargil",
        "value": "Kargil"
     },
     {
        "label": "Leh",
        "value": "Leh"
     }]
},
{
  "label": "Lakshadweep",
  "value": "Lakshadweep",
  "districts": [
     {
        "label": "Lakshadweep",
        "value": "Lakshadweep"
     }]},
{
   "label": "Madhya Pradesh",
   "value": "Madhya Pradesh",
   "districts": [
     {
       "label": "Agar Malwa",
       "value": "Agar Malwa"
     },
     {
       "label": "Alirajpur",
       "value": "Alirajpur"
     },
     {
       "label": "Anuppur",
       "value": "Anuppur"
     },
     {
       "label": "Ashoknagar",
       "value": "Ashoknagar"
     },
     {
       "label": "Balaghat",
       "value": "Balaghat"
     },
     {
       "label": "Barwani",
       "value": "Barwani"
     },
     {
       "label": "Betul",
       "value": "Betul"
     },
     {
       "label": "Bhind",
       "value": "Bhind"
     },
     {
       "label": "Bhopal",
       "value": "Bhopal"
     },
     {
       "label": "Burhanpur",
       "value": "Burhanpur"
     },
     {
       "label": "Chachaura",
       "value": "Chachaura"
     },
     {
       "label": "Chhatarpur",
       "value": "Chhatarpur"
     },
     {
       "label": "Chhindwara",
       "value": "Chhindwara"
     },
     {
       "label": "Damoh",
       "value": "Damoh"
     },
     {
       "label": "Datia",
       "value": "Datia"
     },
     {
       "label": "Dewas",
       "value": "Dewas"
     },
     {
       "label": "Dhar",
       "value": "Dhar"
     },
     {
       "label": "Dindori",
       "value": "Dindori"
     },
     {
       "label": "Guna",
       "value": "Guna"
     },
     {
       "label": "Gwalior",
       "value": "Gwalior"
     },
     {
       "label": "Harda",
       "value": "Harda"
     },
     {
       "label": "Hoshangabad",
       "value": "Hoshangabad"
     },
     {
       "label": "Indore",
       "value": "Indore"
     },
     {
       "label": "Jabalpur",
       "value": "Jabalpur"
     },
     {
       "label": "Jhabua",
       "value": "Jhabua"
     },
     {
       "label": "Katni",
       "value": "Katni"
     },
     {
       "label": "Khandwa",
       "value": "Khandwa"
     },
     {
       "label": "Khargone",
       "value": "Khargone"
     },
     {
       "label": "Maihar",
       "value": "Maihar"
     },
     {
       "label": "Mandla",
       "value": "Mandla"
     },
     {
       "label": "Mandsaur",
       "value": "Mandsaur"
     },
     {
       "label": "Morena",
       "value": "Morena"
     },
     {
       "label": "Nagda",
       "value": "Nagda"
     },
     {
       "label": "Narsinghpur",
       "value": "Narsinghpur"
     },
     {
       "label": "Neemuch",
       "value": "Neemuch"
     },
     {
       "label": "Niwari",
       "value": "Niwari"
     },
     {
       "label": "Panna",
       "value": "Panna"
     },
     {
       "label": "Raisen",
       "value": "Raisen"
     },
     {
       "label": "Rajgarh",
       "value": "Rajgarh"
     },
     {
       "label": "Ratlam",
       "value": "Ratlam"
     },
     {
       "label": "Rewa",
       "value": "Rewa"
     },
     {
       "label": "Sagar",
       "value": "Sagar"
     },
     {
       "label": "Satna",
       "value": "Satna"
     },
     {
       "label": "Sehore",
       "value": "Sehore"
     },
     {
       "label": "Seoni",
       "value": "Seoni"
     },
     {
       "label": "Shahdol",
       "value": "Shahdol"
     },
     {
       "label": "Shajapur",
       "value": "Shajapur"
     },
     {
       "label": "Sheopur",
       "value": "Sheopur"
     },
     {
       "label": "Shivpuri",
       "value": "Shivpuri"
     },
     {
       "label": "Sidhi",
       "value": "Sidhi"
     },
     {
       "label": "Singrauli",
       "value": "Singrauli"
     },
     {
       "label": "Tikamgarh",
       "value": "Tikamgarh"
     },
     {
       "label": "Ujjain",
       "value": "Ujjain"
     },
     {
       "label": "Umaria",
       "value": "Umaria"
     },
     {
       "label": "Vidisha",
       "value": "Vidisha"
     }]
},
{
   "label": "Maharashtra",
   "value": "Maharashtra",
   "districts": [
     {
       "label": "Ahmednagar",
       "value": "Ahmednagar"
     },
     {
       "label": "Akola",
       "value": "Akola"
     },
     {
       "label": "Amravati",
       "value": "Amravati"
     },
     {
       "label": "Aurangabad",
       "value": "Aurangabad"
     },
     {
       "label": "Beed",
       "value": "Beed"
     },
     {
       "label": "Bhandara",
       "value": "Bhandara"
     },
     {
       "label": "Buldhana",
       "value": "Buldhana"
     },
     {
       "label": "Chandrapur",
       "value": "Chandrapur"
     },
     {
       "label": "Dhule",
       "value": "Dhule"
     },
     {
       "label": "Gadchiroli",
       "value": "Gadchiroli"
     },
     {
       "label": "Gondia",
       "value": "Gondia"
     },
     {
       "label": "Hingoli",
       "value": "Hingoli"
     },
     {
       "label": "Jalgaon",
       "value": "Jalgaon"
     },
     {
       "label": "Jalna",
       "value": "Jalna"
     },
     {
       "label": "Kolhapur",
       "value": "Kolhapur"
     },
     {
       "label": "Latur",
       "value": "Latur"
     },
     {
       "label": "Mumbai City",
       "value": "Mumbai City"
     },
     {
       "label": "Mumbai Suburban",
       "value": "Mumbai Suburban"
     },
     {
       "label": "Nagpur",
       "value": "Nagpur"
     },
     {
       "label": "Nanded",
       "value": "Nanded"
     },
     {
       "label": "Nandurbar",
       "value": "Nandurbar"
     },
     {
       "label": "Nashik",
       "value": "Nashik"
     },
     {
       "label": "Osmanabad",
       "value": "Osmanabad"
     },
     {
       "label": "Palghar",
       "value": "Palghar"
     },
     {
       "label": "Parbhani",
       "value": "Parbhani"
     },
     {
       "label": "Pune",
       "value": "Pune"
     },
     {
       "label": "Raigad",
       "value": "Raigad"
     },
     {
       "label": "Ratnagiri",
       "value": "Ratnagiri"
     },
     {
       "label": "Sangli",
       "value": "Sangli"
     },
     {
       "label": "Satara",
       "value": "Satara"
     },
     {
       "label": "Sindhudurg",
       "value": "Sindhudurg"
     },
     {
       "label": "Solapur",
       "value": "Solapur"
     },
     {
       "label": "Thane",
       "value": "Thane"
     },
     {
       "label": "Wardha",
       "value": "Wardha"
     },
     {
       "label": "Washim",
       "value": "Washim"
     },
     {
       "label": "Yavatmal",
       "value": "Yavatmal"
     }]
},
{
  "label": "Manipur",
  "value": "Manipur",
  "districts": [
     {
        "label": "Bishnupur",
        "value": "Bishnupur"
     },
     {
        "label": "Chandel",
        "value": "Chandel"
     },
     {
        "label": "Churachandpur",
        "value": "Churachandpur"
     },
     {
        "label": "Imphal East",
        "value": "Imphal East"
     },
     {
        "label": "Imphal West",
        "value": "Imphal West"
     },
     {
        "label": "Jiribam",
        "value": "Jiribam"
     },
     {
        "label": "Kakching",
        "value": "Kakching"
     },
     {
        "label": "Kamjong",
        "value": "Kamjong"
     },
     {
        "label": "Kangpokpi",
        "value": "Kangpokpi"
     },
     {
        "label": "Noney",
        "value": "Noney"
     },
     {
        "label": "Pherzawl",
        "value": "Pherzawl"
     },
     {
        "label": "Senapati",
        "value": "Senapati"
     },
     {
        "label": "Tamenglong",
        "value": "Tamenglong"
     },
     {
        "label": "Tengnoupal",
        "value": "Tengnoupal"
     },
     {
        "label": "Thoubal",
        "value": "Thoubal"
     },
     {
        "label": "Ukhrul",
        "value": "Ukhrul"
     }]
},
{
  "label": "Meghalaya",
  "value": "Meghalaya",
  "districts": [
     {
        "label": "East Garo Hills",
        "value": "East Garo Hills"
     },
     {
        "label": "East Jaintia Hills",
        "value": "East Jaintia Hills"
     },
     {
        "label": "East Khasi Hills",
        "value": "East Khasi Hills"
     },
     {
        "label": "Mairang",
        "value": "Mairang"
     },
     {
        "label": "North Garo Hills",
        "value": "North Garo Hills"
     },
     {
        "label": "Ri Bhoi",
        "value": "Ri Bhoi"
     },
     {
        "label": "South Garo Hills",
        "value": "South Garo Hills"
     },
     {
        "label": "South West Garo Hills",
        "value": "South West Garo Hills"
     },
     {
        "label": "South West Khasi Hills",
        "value": "South West Khasi Hills"
     },
     {
        "label": "West Garo Hills",
        "value": "West Garo Hills"
     },
     {
        "label": "West Jaintia Hills",
        "value": "West Jaintia Hills"
     },
     {
        "label": "West Khasi Hills",
        "value": "West Khasi Hills"
     }]
},
{
     "label":"Mizoram",
     "value":"Mizoram",
     "districts":[
        {
           "label":"Aizawl",
           "value":"Aizawl"
        },
        {
           "label":"Champhai",
           "value":"Champhai"
        },
        {
           "label":"Hnahthial",
           "value":"Hnahthial"
        },
        {
           "label":"Kolasib",
           "value":"Kolasib"
        },
        {
           "label":"Khawzawl",
           "value":"Khawzawl"
        },
        {
           "label":"Lawngtlai",
           "value":"Lawngtlai"
        },
        {
           "label":"Lunglei",
           "value":"Lunglei"
        },
        {
           "label":"Mamit",
           "value":"Mamit"
        },
        {
           "label":"Saiha",
           "value":"Saiha"
        },
        {
           "label":"Serchhip",
           "value":"Serchhip"
        },
        {
           "label":"Saitual",
           "value":"Saitual"
        }]
},
{
 "label": "Nagaland",
 "value": "Nagaland",
 "districts": [
   {
     "label": "Chumukedima",
     "value": "Chumukedima"
   },
   {
     "label": "Dimapur",
     "value": "Dimapur"
   },
   {
     "label": "Kiphire",
     "value": "Kiphire"
   },
   {
     "label": "Kohima",
     "value": "Kohima"
   },
   {
     "label": "Longleng",
     "value": "Longleng"
   },
   {
     "label": "Mokokchung",
     "value": "Mokokchung"
   },
   {
     "label": "Mon",
     "value": "Mon"
   },
   {
     "label": "Niuland",
     "value": "Niuland"
   },
   {
     "label": "Noklak",
     "value": "Noklak"
   },
   {
     "label": "Peren",
     "value": "Peren"
   },
   {
     "label": "Phek",
     "value": "Phek"
   },
   {
     "label": "Shamator",
     "value": "Shamator"
   },
   {
     "label": "Tseminyu",
     "value": "Tseminyu"
   },
   {
     "label": "Tuensang",
     "value": "Tuensang"
   },
   {
     "label": "Wokha",
     "value": "Wokha"
   },
   {
     "label": "Zunheboto",
     "value": "Zunheboto"
   }]
},
{
 "label": "Odisha",
 "value": "Odisha",
 "districts": [
   {
     "label": "Angul",
     "value": "Angul"
   },
   {
     "label": "Balangir",
     "value": "Balangir"
   },
   {
     "label": "Balasore",
     "value": "Balasore"
   },
   {
     "label": "Bargarh",
     "value": "Bargarh"
   },
   {
     "label": "Bhadrak",
     "value": "Bhadrak"
   },
   {
     "label": "Boudh",
     "value": "Boudh"
   },
   {
     "label": "Cuttack",
     "value": "Cuttack"
   },
   {
     "label": "Debagarh",
     "value": "Debagarh"
   },
   {
     "label": "Dhenkanal",
     "value": "Dhenkanal"
   },
   {
     "label": "Gajapati",
     "value": "Gajapati"
   },
   {
     "label": "Ganjam",
     "value": "Ganjam"
   },
   {
     "label": "Jagatsinghpur",
     "value": "Jagatsinghpur"
   },
   {
     "label": "Jajpur",
     "value": "Jajpur"
   },
   {
     "label": "Jharsuguda",
     "value": "Jharsuguda"
   },
   {
     "label": "Kalahandi",
     "value": "Kalahandi"
   },
   {
     "label": "Kandhamal",
     "value": "Kandhamal"
   },
   {
     "label": "Kendrapara",
     "value": "Kendrapara"
   },
   {
     "label": "Kendujhar",
     "value": "Kendujhar"
   },
   {
     "label": "Khordha",
     "value": "Khordha"
   },
   {
     "label": "Koraput",
     "value": "Koraput"
   },
   {
     "label": "Malkangiri",
     "value": "Malkangiri"
   },
   {
     "label": "Mayurbhanj",
     "value": "Mayurbhanj"
   },
   {
     "label": "Nabarangpur",
     "value": "Nabarangpur"
   },
   {
     "label": "Nayagarh",
     "value": "Nayagarh"
   },
   {
     "label": "Nuapada",
     "value": "Nuapada"
   },
   {
     "label": "Puri",
     "value": "Puri"
   },
   {
     "label": "Rayagada",
     "value": "Rayagada"
   },
   {
     "label": "Sambalpur",
     "value": "Sambalpur"
   },
   {
     "label": "Subarnapur",
     "value": "Subarnapur"
   },
   {
     "label": "Sundergarh",
     "value": "Sundergarh"
   }]
},
{
 "label": "Puducherry",
 "value": "Puducherry",
 "districts": [
   {
     "label": "Karaikal",
     "value": "Karaikal"
   },
   {
     "label": "Mahe",
     "value": "Mahe"
   },
   {
     "label": "Puducherry",
     "value": "Puducherry"
   },
   {
     "label": "Yanam",
     "value": "Yanam"
   }]
},
{
  "label": "Punjab",
  "value": "Punjab",
  "districts": [
     {
        "label": "Amritsar",
        "value": "Amritsar"
     },
     {
        "label": "Barnala",
        "value": "Barnala"
     },
     {
        "label": "Bathinda",
        "value": "Bathinda"
     },
     {
        "label": "Faridkot",
        "value": "Faridkot"
     },
     {
        "label": "Fatehgarh Sahib",
        "value": "Fatehgarh Sahib"
     },
     {
        "label": "Fazilka",
        "value": "Fazilka"
     },
     {
        "label": "Firozpur",
        "value": "Firozpur"
     },
     {
        "label": "Gurdaspur",
        "value": "Gurdaspur"
     },
     {
        "label": "Hoshiarpur",
        "value": "Hoshiarpur"
     },
     {
        "label": "Jalandhar",
        "value": "Jalandhar"
     },
     {
        "label": "Kapurthala",
        "value": "Kapurthala"
     },
     {
        "label": "Ludhiana",
        "value": "Ludhiana"
     },
     {
        "label": "Malerkotla",
        "value": "Malerkotla"
     },
     {
        "label": "Mansa",
        "value": "Mansa"
     },
     {
        "label": "Moga",
        "value": "Moga"
     },
     {
        "label": "Mohali",
        "value": "Mohali"
     },
     {
        "label": "Muktsar",
        "value": "Muktsar"
     },
     {
        "label": "Pathankot",
        "value": "Pathankot"
     },
     {
        "label": "Patiala",
        "value": "Patiala"
     },
     {
        "label": "Rupnagar",
        "value": "Rupnagar"
     },
     {
        "label": "Sangrur",
        "value": "Sangrur"
     },
     {
        "label": "Shaheed Bhagat Singh Nagar",
        "value": "Shaheed Bhagat Singh Nagar"
     },
     {
        "label": "Tarn Taran",
        "value": "Tarn Taran"
     }]
   },
{
   "label": "Rajasthan",
   "value": "Rajasthan",
   "districts": [
     {
       "label": "Ajmer",
       "value": "Ajmer"
     },
     {
       "label": "Alwar",
       "value": "Alwar"
     },
     {
       "label": "Banswara",
       "value": "Banswara"
     },
     {
       "label": "Baran",
       "value": "Baran"
     },
     {
       "label": "Barmer",
       "value": "Barmer"
     },
     {
       "label": "Bharatpur",
       "value": "Bharatpur"
     },
     {
       "label": "Bhilwara",
       "value": "Bhilwara"
     },
     {
       "label": "Bikaner",
       "value": "Bikaner"
     },
     {
       "label": "Bundi",
       "value": "Bundi"
     },
     {
       "label": "Chittorgarh",
       "value": "Chittorgarh"
     },
     {
       "label": "Churu",
       "value": "Churu"
     },
     {
       "label": "Dausa",
       "value": "Dausa"
     },
     {
       "label": "Dholpur",
       "value": "Dholpur"
     },
     {
       "label": "Dungarpur",
       "value": "Dungarpur"
     },
     {
       "label": "Hanumangarh",
       "value": "Hanumangarh"
     },
     {
       "label": "Jaipur",
       "value": "Jaipur"
     },
     {
       "label": "Jaisalmer",
       "value": "Jaisalmer"
     },
     {
       "label": "Jalore",
       "value": "Jalore"
     },
     {
       "label": "Jhalawar",
       "value": "Jhalawar"
     },
     {
       "label": "Jhunjhunu",
       "value": "Jhunjhunu"
     },
     {
       "label": "Jodhpur",
       "value": "Jodhpur"
     },
     {
       "label": "Karauli",
       "value": "Karauli"
     },
     {
       "label": "Kota",
       "value": "Kota"
     },
     {
       "label": "Nagaur",
       "value": "Nagaur"
     },
     {
       "label": "Pali",
       "value": "Pali"
     },
     {
       "label": "Pratapgarh",
       "value": "Pratapgarh"
     },
     {
       "label": "Rajsamand",
       "value": "Rajsamand"
     },
     {
       "label": "Sawai Madhopur",
       "value": "Sawai Madhopur"
     },
     {
       "label": "Sikar",
       "value": "Sikar"
     },
     {
       "label": "Sirohi",
       "value": "Sirohi"
     },
     {
       "label": "Sri Ganganagar",
       "value": "Sri Ganganagar"
     },
     {
       "label": "Tonk",
       "value": "Tonk"
     },
     {
       "label": "Udaipur",
       "value": "Udaipur"
     }]
},
{
 "label": "Sikkim",
 "value": "Sikkim",
 "districts": [
   {
     "label": "East Sikkim",
     "value": "East Sikkim"
   },
   {
     "label": "North Sikkim",
     "value": "North Sikkim"
   },
   {
     "label": "Pakyong",
     "value": "Pakyong"
   },
   {
     "label": "Soreng",
     "value": "Soreng"
   },
   {
     "label": "South Sikkim",
     "value": "South Sikkim"
   },
   {
     "label": "West Sikkim",
     "value": "West Sikkim"
   }]
},
{
   "label": "Tamil Nadu",
   "value": "Tamil Nadu",
   "districts": [
     {
       "label": "Ariyalur",
       "value": "Ariyalur"
     },
     {
       "label": "Chengalpattu",
       "value": "Chengalpattu"
     },
     {
       "label": "Chennai",
       "value": "Chennai"
     },
     {
       "label": "Coimbatore",
       "value": "Coimbatore"
     },
     {
       "label": "Cuddalore",
       "value": "Cuddalore"
     },
     {
       "label": "Dharmapuri",
       "value": "Dharmapuri"
     },
     {
       "label": "Dindigul",
       "value": "Dindigul"
     },
     {
       "label": "Erode",
       "value": "Erode"
     },
     {
       "label": "Kallakurichi",
       "value": "Kallakurichi"
     },
     {
       "label": "Kanchipuram",
       "value": "Kanchipuram"
     },
     {
       "label": "Kanyakumari",
       "value": "Kanyakumari"
     },
     {
       "label": "Karur",
       "value": "Karur"
     },
     {
       "label": "Krishnagiri",
       "value": "Krishnagiri"
     },
     {
       "label": "Madurai",
       "value": "Madurai"
     },
     {
       "label": "Mayiladuthurai",
       "value": "Mayiladuthurai"
     },
     {
       "label": "Nagapattinam",
       "value": "Nagapattinam"
     },
     {
       "label": "Namakkal",
       "value": "Namakkal"
     },
     {
       "label": "Nilgiris",
       "value": "Nilgiris"
     },
     {
       "label": "Perambalur",
       "value": "Perambalur"
     },
     {
       "label": "Pudukkottai",
       "value": "Pudukkottai"
     },
     {
       "label": "Ramanathapuram",
       "value": "Ramanathapuram"
     },
     {
       "label": "Ranipet",
       "value": "Ranipet"
     },
     {
       "label": "Salem",
       "value": "Salem"
     },
     {
       "label": "Sivaganga",
       "value": "Sivaganga"
     },
     {
       "label": "Tenkasi",
       "value": "Tenkasi"
     },
     {
       "label": "Thanjavur",
       "value": "Thanjavur"
     },
     {
       "label": "Theni",
       "value": "Theni"
     }]
},
// {
//   "label": "Tamil Nadu",
//   "value": "Tamil Nadu",
//   "districts": [
//      {
//         "label": "Thoothukudi",
//         "value": "Thoothukudi"
//      },
//      {
//         "label": "Tiruchirappalli",
//         "value": "Tiruchirappalli"
//      },
//      {
//         "label": "Tirunelveli",
//         "value": "Tirunelveli"
//      },
//      {
//         "label": "Tirupattur",
//         "value": "Tirupattur"
//      },
//      {
//         "label": "Tiruppur",
//         "value": "Tiruppur"
//      },
//      {
//         "label": "Tiruvallur",
//         "value": "Tiruvallur"
//      },
//      {
//         "label": "Tiruvannamalai",
//         "value": "Tiruvannamalai"
//      },
//      {
//         "label": "Tiruvarur",
//         "value": "Tiruvarur"
//      },
//      {
//         "label": "Vellore",
//         "value": "Vellore"
//      },
//      {
//         "label": "Viluppuram",
//         "value": "Viluppuram"
//      },
//      {
//         "label": "Virudhunagar",
//         "value": "Virudhunagar"
//      }]
// },
{
  "label": "Telangana",
  "value": "Telangana",
  "districts": [
     {
        "label": "Adilabad",
        "value": "Adilabad"
     },
     {
        "label": "Bhadradri Kothagudem",
        "value": "Bhadradri Kothagudem"
     },
     {
        "label": "Hyderabad",
        "value": "Hyderabad"
     },
     {
        "label": "Jagtial",
        "value": "Jagtial"
     },
     {
        "label": "Jangaon",
        "value": "Jangaon"
     },
     {
        "label": "Jayashankar Bhupalpally",
        "value": "Jayashankar Bhupalpally"
     },
     {
        "label": "Jogulamba Gadwal",
        "value": "Jogulamba Gadwal"
     },
     {
        "label": "Kamareddy",
        "value": "Kamareddy"
     },
     {
        "label": "Karimnagar",
        "value": "Karimnagar"
     },
     {
        "label": "Khammam",
        "value": "Khammam"
     },
     {
        "label": "Komaram Bheem",
        "value": "Komaram Bheem"
     },
     {
        "label": "Mahabubabad",
        "value": "Mahabubabad"
     },
     {
        "label": "Mahbubnagar",
        "value": "Mahbubnagar"
     },
     {
        "label": "Mancherial",
        "value": "Mancherial"
     },
     {
        "label": "Medak",
        "value": "Medak"
     },
     {
        "label": "Medchal",
        "value": "Medchal"
     },
     {
        "label": "Mulugu",
        "value": "Mulugu"
     },
     {
        "label": "Nagarkurnool",
        "value": "Nagarkurnool"
     },
     {
        "label": "Nalgonda",
        "value": "Nalgonda"
     },
     {
        "label": "Narayanpet",
        "value": "Narayanpet"
     },
     {
        "label": "Nirmal",
        "value": "Nirmal"
     },
     {
        "label": "Nizamabad",
        "value": "Nizamabad"
     },
     {
        "label": "Peddapalli",
        "value": "Peddapalli"
     },
     {
        "label": "Rajanna Sircilla",
        "value": "Rajanna Sircilla"
     },
     {
        "label": "Ranga Reddy",
        "value": "Ranga Reddy"
     },
     {
        "label": "Sangareddy",
        "value": "Sangareddy"
     },
     {
        "label": "Siddipet",
        "value": "Siddipet"
     },
     {
        "label": "Suryapet",
        "value": "Suryapet"
     },
     {
        "label": "Vikarabad",
        "value": "Vikarabad"
     },
     {
        "label": "Wanaparthy",
        "value": "Wanaparthy"
     },
     {
        "label": "Warangal",
        "value": "Warangal"
     },
     {
        "label": "Hanamkonda",
        "value": "Hanamkonda"
     },
     {
        "label": "Yadadri Bhuvanagiri",
        "value": "Yadadri Bhuvanagiri"
     }]
},
{
 "label": "Tripura",
 "value": "Tripura",
 "districts": [
   {
     "label": "Dhalai",
     "value": "Dhalai"
   },
   {
     "label": "Gomati",
     "value": "Gomati"
   },
   {
     "label": "Khowai",
     "value": "Khowai"
   },
   {
     "label": "North Tripura",
     "value": "North Tripura"
   },
   {
     "label": "Sepahijala",
     "value": "Sepahijala"
   },
   {
     "label": "South Tripura",
     "value": "South Tripura"
   },
   {
     "label": "Unakoti",
     "value": "Unakoti"
   },
   {
     "label": "West Tripura",
     "value": "West Tripura"
   }]
},
{
  "label": "Uttar Pradesh",
  "value": "Uttar Pradesh",
  "districts": [
     {
        "label": "Agra",
        "value": "Agra"
     },
     {
        "label": "Aligarh",
        "value": "Aligarh"
     },
     {
        "label": "Ambedkar Nagar",
        "value": "Ambedkar Nagar"
     },
     {
        "label": "Amethi",
        "value": "Amethi"
     },
     {
        "label": "Amroha",
        "value": "Amroha"
     },
     {
        "label": "Auraiya",
        "value": "Auraiya"
     },
     {
        "label": "Ayodhya",
        "value": "Ayodhya"
     },
     {
        "label": "Azamgarh",
        "value": "Azamgarh"
     },
     {
        "label": "Baghpat",
        "value": "Baghpat"
     },
     {
        "label": "Bahraich",
        "value": "Bahraich"
     },
     {
        "label": "Ballia",
        "value": "Ballia"
     },
     {
        "label": "Balrampur",
        "value": "Balrampur"
     },
     {
        "label": "Banda",
        "value": "Banda"
     },
     {
        "label": "Barabanki",
        "value": "Barabanki"
     },
     {
        "label": "Bareilly",
        "value": "Bareilly"
     },
     {
        "label": "Basti",
        "value": "Basti"
     },
     {
        "label": "Bhadohi",
        "value": "Bhadohi"
     },
     {
        "label": "Bijnor",
        "value": "Bijnor"
     },
     {
        "label": "Budaun",
        "value": "Budaun"
     },
     {
        "label": "Bulandshahr",
        "value": "Bulandshahr"
     },
     {
        "label": "Chandauli",
        "value": "Chandauli"
     },
     {
        "label": "Chitrakoot",
        "value": "Chitrakoot"
     },
     {
        "label": "Deoria",
        "value": "Deoria"
     },
     {
        "label": "Etah",
        "value": "Etah"
     },
     {
        "label": "Etawah",
        "value": "Etawah"
     },
     {
        "label": "Farrukhabad",
        "value": "Farrukhabad"
     },
     {
        "label": "Fatehpur",
        "value": "Fatehpur"
     },
     {
        "label": "Firozabad",
        "value": "Firozabad"
     },
     {
        "label": "Gautam Buddha Nagar",
        "value": "Gautam Buddha Nagar"
     },
     {
        "label": "Ghaziabad",
        "value": "Ghaziabad"
     },
     {
        "label": "Ghazipur",
        "value": "Ghazipur"
     },
     {
        "label": "Gonda",
        "value": "Gonda"
     },
{
       "label": "Gorakhpur",
       "value": "Gorakhpur"
     },
     {
       "label": "Hamirpur",
       "value": "Hamirpur"
     },
     {
       "label": "Hapur",
       "value": "Hapur"
     },
     {
       "label": "Hardoi",
       "value": "Hardoi"
     },
     {
       "label": "Hathras",
       "value": "Hathras"
     },
     {
       "label": "Jalaun",
       "value": "Jalaun"
     },
     {
       "label": "Jaunpur",
       "value": "Jaunpur"
     },
     {
       "label": "Jhansi",
       "value": "Jhansi"
     },
     {
       "label": "Kannauj",
       "value": "Kannauj"
     },
     {
       "label": "Kanpur Dehat",
       "value": "Kanpur Dehat"
     },
     {
       "label": "Kanpur Nagar",
       "value": "Kanpur Nagar"
     },
     {
       "label": "Kasganj",
       "value": "Kasganj"
     },
     {
       "label": "Kaushambi",
       "value": "Kaushambi"
     },
     {
       "label": "Kheri",
       "value": "Kheri"
     },
     {
       "label": "Kushinagar",
       "value": "Kushinagar"
     },
     {
       "label": "Lalitpur",
       "value": "Lalitpur"
     },
     {
       "label": "Lucknow",
       "value": "Lucknow"
     },
     {
       "label": "Maharajganj",
       "value": "Maharajganj"
     },
     {
       "label": "Mahoba",
       "value": "Mahoba"
     },
     {
       "label": "Mainpuri",
       "value": "Mainpuri"
     },
     {
       "label": "Mathura",
       "value": "Mathura"
     },
     {
       "label": "Mau",
       "value": "Mau"
     },
     {
       "label": "Meerut",
       "value": "Meerut"
     },
     {
       "label": "Mirzapur",
       "value": "Mirzapur"
     },
     {
       "label": "Moradabad",
       "value": "Moradabad"
     },
     {
       "label": "Muzaffarnagar",
       "value": "Muzaffarnagar"
     },
     {
       "label": "Pilibhit",
       "value": "Pilibhit"
     },
     {
       "label": "Pratapgarh",
       "value": "Pratapgarh"
     },
       {
         "label": "Prayagraj",
         "value": "Prayagraj"
       },
       {
         "label": "Raebareli",
         "value": "Raebareli"
       },
       {
         "label": "Rampur",
         "value": "Rampur"
       },
       {
         "label": "Saharanpur",
         "value": "Saharanpur"
       },
       {
         "label": "Sambhal",
         "value": "Sambhal"
       },
       {
         "label": "Sant Kabir Nagar",
         "value": "Sant Kabir Nagar"
       },
       {
         "label": "Shahjahanpur",
         "value": "Shahjahanpur"
       },
       {
         "label": "Shamli",
         "value": "Shamli"
       },
       {
         "label": "Shravasti",
         "value": "Shravasti"
       },
       {
         "label": "Siddharthnagar",
         "value": "Siddharthnagar"
       },
       {
         "label": "Sitapur",
         "value": "Sitapur"
       },
       {
         "label": "Sonbhadra",
         "value": "Sonbhadra"
       },
       {
         "label": "Sultanpur",
         "value": "Sultanpur"
       },
       {
         "label": "Unnao",
         "value": "Unnao"
       },
       {
         "label": "Varanasi",
         "value": "Varanasi"
       }]
},
{
 "label": "Uttarakhand",
 "value": "Uttarakhand",
 "districts": [
   {
     "label": "Almora",
     "value": "Almora"
   },
   {
     "label": "Bageshwar",
     "value": "Bageshwar"
   },
   {
     "label": "Chamoli",
     "value": "Chamoli"
   },
   {
     "label": "Champawat",
     "value": "Champawat"
   },
   {
     "label": "Dehradun",
     "value": "Dehradun"
   },
   {
     "label": "Haridwar",
     "value": "Haridwar"
   },
   {
     "label": "Nainital",
     "value": "Nainital"
   },
   {
     "label": "Pauri",
     "value": "Pauri"
   },
   {
     "label": "Pithoragarh",
     "value": "Pithoragarh"
   },
   {
     "label": "Rudraprayag",
     "value": "Rudraprayag"
   },
   {
     "label": "Tehri",
     "value": "Tehri"
   },
   {
     "label": "Udham Singh Nagar",
     "value": "Udham Singh Nagar"
   },
   {
     "label": "Uttarkashi",
     "value": "Uttarkashi"
   }]
},
{
 "label": "West Bengal",
 "value": "West Bengal",
 "districts": [
   {
     "label": "Alipurduar",
     "value": "Alipurduar"
   },
   {
     "label": "Bankura",
     "value": "Bankura"
   },
   {
     "label": "Birbhum",
     "value": "Birbhum"
   },
   {
     "label": "Cooch Behar",
     "value": "Cooch Behar"
   },
   {
     "label": "Dakshin Dinajpur",
     "value": "Dakshin Dinajpur"
   },
   {
     "label": "Darjeeling",
     "value": "Darjeeling"
   },
   {
     "label": "Hooghly",
     "value": "Hooghly"
   },
   {
     "label": "Howrah",
     "value": "Howrah"
   },
   {
     "label": "Jalpaiguri",
     "value": "Jalpaiguri"
   },
   {
     "label": "Jhargram",
     "value": "Jhargram"
   },
   {
     "label": "Kalimpong",
     "value": "Kalimpong"
   },
   {
     "label": "Kolkata",
     "value": "Kolkata"
   },
   {
     "label": "Malda",
     "value": "Malda"
   },
   {
     "label": "Murshidabad",
     "value": "Murshidabad"
   },
   {
     "label": "Nadia",
     "value": "Nadia"
   },
   {
     "label": "North 24 Parganas",
     "value": "North 24 Parganas"
   },
   {
     "label": "Paschim Bardhaman",
     "value": "Paschim Bardhaman"
   },
   {
     "label": "Paschim Medinipur",
     "value": "Paschim Medinipur"
   },
   {
     "label": "Purba Bardhaman",
     "value": "Purba Bardhaman"
   },
   {
     "label": "Purba Medinipur",
     "value": "Purba Medinipur"
   },
   {
     "label": "Purulia",
     "value": "Purulia"
   },
   {
     "label": "South 24 Parganas",
     "value": "South 24 Parganas"
   },
   {
     "label": "Uttar Dinajpur",
     "value": "Uttar Dinajpur"
   }
 ]
}
]

 
 export default statesData;