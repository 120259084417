import sliceCreator from '../../index.js';
import {RELETEDPAN} from "../../../../constants/index.js"

const _updateRelatedPANStatusAction = sliceCreator('RELETEDPAN', RELETEDPAN, 'patch');

const { asyncAction: updateRelatedPANStatusAction, reducer, clearData: clearupdateRelatedPANStatusAction } = _updateRelatedPANStatusAction;

export default reducer;

export {
    updateRelatedPANStatusAction,
    clearupdateRelatedPANStatusAction
}