import React from 'react';
import { Link } from 'react-router-dom';
import newgiff from "../../assets/newgiff.gif";
import Navbar from "../navbar";

const index = () => {
  return (
    <>
    <Navbar />
    <div className='my-5'>
         
         </div>
         <div className='container signin_div'>
         
             <div className='row g-0'>
                 <div className='col-lg-6 order-1 order-lg-0 header-img'>
                 <img src={newgiff} className="img-fluid" alt="login img"></img>
                 </div>
                 <div className='col-lg-3 col-10 mx-auto m-5'>
                    
                     <form>
                     <h3>Forgot Password</h3>
                     <p className='text-secondary'>Enter the registered email ID</p>
     
                     <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">User ID</label>
                         <input type="text" name="userid"  onChange={InputEvent} className="form-control" id="exampleFormControlInput1" placeholder="Userid" required/>
                     </div>
                     
                    <div className="row">
                     <div className="col-sm-6">
                         <Link to="/resetpassword"><button type="submit" className="btn btn-primary mb-3">Send Reset Link</button></Link>
                     </div>

                     </div>
                     </form>
     
                 </div>
     
             </div>
     
         </div>
         </>

  )
}

export default index