import sliceCreator from '../index.js';
import {Edit_Received_Rating} from "../../../constants/index.js"

const _deleteEditReceivedRatingAction = sliceCreator('Edit_Received_Rating', Edit_Received_Rating, 'delete');

const { asyncAction: deleteEditReceivedRatingAction, reducer, clearData: cleardeleteEditReceivedRatingAction } = _deleteEditReceivedRatingAction;

export default reducer;

export {
    deleteEditReceivedRatingAction,
    cleardeleteEditReceivedRatingAction
}