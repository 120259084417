import sliceCreator from '../index.js';
import {RAISED_DISPUTE} from "../../../constants/index.js"

const _postRaiseDisputeAction = sliceCreator('RAISED_DISPUTE', RAISED_DISPUTE, 'post');

const { asyncAction: postRaiseDispute, reducer, clearData: clearPostRaiseDisputeData } = _postRaiseDisputeAction;

export default reducer;

export {
    postRaiseDispute,
    clearPostRaiseDisputeData
}
