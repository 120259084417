export const navList = [
    {
      title: "Dashboard",
      goTo: "/admin/dashboard",
      icon: "fa fa-th",
    },
  
    {
      title: "PANSearch",
      goTo: "/admin/searchWithPAN",
      icon: "fa fa-check-circle",
    },
    {
      title: "SearchDB",
      goTo: "/admin/DbSearch",
      icon: "fa fa-search"
    },
    {
      title: "Payments",
      goTo: "/admin/Payments",
      icon: "fa fa-credit-card",
    },
  
    {
      title: "InactiveUser",
      goTo: '/admin/activeUserList',
      icon: "fa fa-users"
  },
     {
      title: "ReletedPan",
      goTo: '/admin/relatedpan',
      icon: "fa fa-users"
    },
  ];