import React from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import UpdateIcon from '@mui/icons-material/Update';
import PublicIcon from '@mui/icons-material/Public';

const KeyFeatures = () => {
  const cards = [
    {
      title: 'Rating System',
      icon: <StarIcon sx={{ fontSize: 80, color: 'white' }} />,
      bgColor: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    },
    {
      title: 'Partner Search',
      icon: <PersonSearchIcon sx={{ fontSize: 80, color: 'white' }} />,
      bgColor: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    },
    {
      title: 'Real-time Updates',
      icon: <UpdateIcon sx={{ fontSize: 80, color: 'white' }} />,
      bgColor: 'linear-gradient(45deg, #673AB7 30%, #512DA8 90%)',
    },
    {
      title: 'Trustworthy Network',
      icon: <PublicIcon sx={{ fontSize: 80, color: 'white' }} />,
      bgColor: 'linear-gradient(45deg, #FFD54F 30%, #FFC107 90%)',
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3, textAlign: 'center', backgroundColor: '#f0f0f0' }}>
      <Typography variant="h4" gutterBottom component="div" sx={{ marginBottom: 4, fontWeight: 'bold', color: '#0073b1' }}>
        Key Features
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={card.title}>
            <Card sx={{ 
              minHeight: 200, 
              backgroundImage: card.bgColor,
              borderRadius: '15px', // Rounded corners
              color: 'white',
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              padding: 2,
              height: '100%',
              '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease',
              },
            }}>
              <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                <Box sx={{ fontSize: 60 }}>{card.icon}</Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: 2 }}>
                  {card.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default KeyFeatures;
