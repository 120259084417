import sliceCreator from '../index.js';
import {PROFILE} from "../../../constants"

const _getProfileAction = sliceCreator('GET_PROFILE', PROFILE, 'get');

const { asyncAction: getProfileAction, reducer, clearData: clearGetProfileActionData } = _getProfileAction;

export default reducer;

export {
  getProfileAction,
  clearGetProfileActionData
}
