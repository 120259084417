import sliceCreator from '../index.js';
import {GST_DETAILS} from "../../../constants"

const _getGstDetailsAction = sliceCreator('GST_DETAILS', GST_DETAILS, 'get');

const { asyncAction: getGstDetailsAction, reducer, clearData: clearGetGstDetailsAction } = _getGstDetailsAction;

export default reducer;

export {
    getGstDetailsAction,
    clearGetGstDetailsAction
}
