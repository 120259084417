   
import sliceCreator from '../../index.js';
import {RELETEDPAN} from "../../../../constants/index.js"

const _getRelatedPANDetailsAction = sliceCreator('RELETEDPAN', RELETEDPAN, 'get');

const { asyncAction: getRelatedPANDetailsAction, reducer, clearData: clearGetRelatedPANDetailsAction } = _getRelatedPANDetailsAction;

export default reducer;

export {
    getRelatedPANDetailsAction,
    clearGetRelatedPANDetailsAction
}