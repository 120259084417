import React from "react";
import { Navigate } from "react-router-dom";

import PropTypes from 'prop-types';
const PrivateRoute = ({ component: Component, ...rest }) => {
 
  const key = localStorage.getItem("token");


  const is_premium_user = localStorage.getItem("is_premium_user");

  const is_staff = localStorage.getItem("is_staff");
  
  const isAuthenticated = !!key;
  
  
  
const renderComponent = () => {
    if (is_staff === "true") {
      return <Navigate to="/admin" />
    }
    if (isAuthenticated && is_premium_user === "true") {
      return <Component {...rest} />;
    } else if (isAuthenticated && is_premium_user !=="true") {
      return <Navigate to="/subscription" />;
    } else {
      return <Navigate to="/Login" />;
    }
  };

  return renderComponent();
};
export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

