import Avatar from "@mui/material/Avatar";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import DropdownItems from "../../utils/dropdownutils";
import PropTypes from "prop-types"

const Ratings = ({ overallDetails, partnerName, partnersRatings }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#4b31ad" : "#ed4224",
    },
  }));

  const getRatingLabels = () => {
    let rLbls = [];
    const validfield = DropdownItems.score.slice(0, 10);

    rLbls = validfield.map((score, i) => {
      const ratingValue = overallDetails.overAllRatings[validfield.length - i];

      if (ratingValue > 0) {
        return (
          <div key={score.value} style={{ marginBottom: "5px"}}>
            <Typography
              className="bold"
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                fontWeight: "bold",
                fontSize: "16px",

              }}
            >
              {score.label}: {ratingValue}
            </Typography>
            {/* Your structure when ratingValue > 0 */}
            {/* For instance, assuming a LinearProgress here */}
            <BorderLinearProgress
              variant="determinate"
              value={(ratingValue / partnersRatings.length) * 100}
            />
          </div>
        );
      }

      return null;
    });

    return rLbls;
  };

 
  return (
    <>
      <Typography
        className="com"
        style={{ fontWeight: "bold", fontSize: "18px" }}
      >
        {`Overall Rating ${partnerName}`}
      </Typography>

      <div style={{ textAlign: 'center' }} >


        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar style={{ backgroundColor: '#ff5722', width: 'fit-content', height: 'fit-content', minWidth: '40px', minHeight: '40px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px' }}>
            {overallDetails.avgRating === 'NaN' ? "No Rating Yet" : overallDetails.avgRating}
          </Avatar>
        </div>


        <Typography className="riv" style={{ marginTop: "0px" }}>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            Total: {partnersRatings.length} Ratings
          </span>
        </Typography>
      </div>

      <div style={{  marginLeft: "10px", marginRight: "10px", backgroundColor: 'rgb(209,207,226)', borderRadius: '5px', padding: '5px' }}>
        {getRatingLabels()}

      </div>
    </>
  );
};
export default Ratings;



Ratings.propTypes = {
  overallDetails: PropTypes.string.isRequired,
  partnerName: PropTypes.any.isRequired,
  partnersRatings:PropTypes.any.isRequired
}
