import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Paper, Grid, Alert, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import covercbz from '../../assets/covercbz.png'; // Ensure the path is correct
import { postloginOtpAction, globalConfigAction, loginAction,clearloginOtpActionData } from '../../store/actions';
import Navbar from '../navbar';
import Termsandpolicy from '../terms_policy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Login } from '../login/schmaforloginpagevalidation';
import { useFormik } from "formik";

const LoginModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successData, error } = useSelector((state) => state.loginUserDetails) || {};
  const { loading: otpLoading, successData: loginWithOtpData, error: loginWithOtpError, errorInfo: loginWithOtpErrorInfo } = useSelector((state) => state.loginWithOtpReducer) || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handelBackToLogin = () => {
    setShowOTPBox(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      pan: '',
      password: '',
      phoneNumber: '',
      otp: '',
    },
    validationSchema: Login,
    onSubmit: (values) => {
      const userDetails = {
        password: values.password,
        pan_number: values.pan,
      };
      dispatch(loginAction(userDetails));
    },
  });

  useEffect(() => {
    if (successData) {
      localStorage.setItem("token", successData?.key);
      localStorage.setItem("id", successData.user.id);
      localStorage.setItem("is_premium_user", successData.user.is_premium_user);
      localStorage.setItem("is_subscription_active", successData.is_subscription_active);
      localStorage.setItem("is_company", successData.user?.is_company);
      localStorage.setItem("is_staff", successData.user.is_staff);
      navigate("/dashboard");
      toast.success(successData.user.is_staff ? "Admin logged in successfully" : "User logged in successfully");
    }
  }, [successData, navigate]);

  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error("Invalid credentials");
    }
  }, [error]);

  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const handlegetotp = () => {
    const otpDetails = {
      phone_number: values.phoneNumber,
    };
    dispatch(postloginOtpAction(otpDetails))
  };

  const handleVerifyOtp = () => {
    const otpDetails = {
      phone_number: values.phoneNumber,
      otp: otp,
    };
    setIsVerified(true);
    dispatch(postloginOtpAction(otpDetails));
  };

  useEffect(() => {
    if (loginWithOtpData) {
      if (!isOtpSent) {
        setIsOtpSent(true);
        setTimer(120); // 2 minute timer
        toast.success("OTP sent successfully.");
      } else if (isVerified && timer === 0) {
        setIsOtpSent(false);
      }
    }
  }, [loginWithOtpData, isOtpSent, isVerified, timer]);

  useEffect(() => {
    if(loginWithOtpData){
      setTimeout(() => {
        dispatch(clearloginOtpActionData());
      }, 2000);
    }
  }, [loginWithOtpData,dispatch]);



  useEffect(() => {
    if (loginWithOtpError) {
      toast.error(loginWithOtpErrorInfo[0]);
      dispatch(clearloginOtpActionData());
    }
  }, [loginWithOtpError,loginWithOtpErrorInfo,dispatch ]);

  useEffect(() => {
    if (loginWithOtpData && isVerified) {
      localStorage.setItem("token", loginWithOtpData?.key);
      localStorage.setItem("id", loginWithOtpData.user.id);
      localStorage.setItem("is_premium_user", loginWithOtpData.user.is_premium_user);
      localStorage.setItem("is_subscription_active", loginWithOtpData.is_subscription_active);
      localStorage.setItem("is_company", loginWithOtpData.user.business?.is_company);
      navigate("/dashboard");
      toast.success("OTP verified. User logged in successfully");
    }
  }, [loginWithOtpData, isVerified, navigate]);

  const handleOTPLinkClick = () => {
    setShowOTPBox(true);
  };

  const handlePhoneNumberChange = (e) => {
    const { value, name } = e.target;
    handleChange(e);

    switch (name) {
      case 'phoneNumber': {
        const isValidPhoneNumber = /^\d{10}$/.test(value);
        setPhoneNumberError(!isValidPhoneNumber);
        break;
      }
      case 'otp': {
        setOtp(value);
        const isValidOtp = /^\d{6}$/.test(value);
        setOtpError(!isValidOtp);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${covercbz})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: "90vh"
        }}
      >
        <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: 4, borderRadius: 2, maxWidth: '500px', width: '100%' }}>
          {!showOTPBox && (
            <>
              <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2, color: 'black' }}>
                Login
              </Typography>
              <Typography sx={{ textAlign: 'center', marginBottom: 3, color: 'black' }}>
                Please enter the details to login to your account
              </Typography>
            </>
          )}
          {!showOTPBox ? (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id="pan"
                name="pan"
                label="PAN Number"
                type={"text"}
                value={values.pan}
                onChange={handleChange}
                onInput={(e) => (e.target.value = ("" + e.target.value).toUpperCase())}
                onBlur={handleBlur}
                error={touched.pan && Boolean(errors.pan)}
                helperText={touched.pan && errors.pan}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleTogglePassword} edge="end">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  ),
                }}
              />
              <Button color="primary" variant="contained" fullWidth type="submit" sx={{ marginBottom: 3 }}>
                Login
              </Button>
              <Typography variant="h6" align="center" sx={{ marginBottom: 1 }}>
                <Link onClick={handleOTPLinkClick} style={{ cursor: 'pointer' }}>Use OTP Login</Link>
              </Typography>
            </form>
          ) : (
            <Box>
              <Typography variant='h5' sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}>
                OTP Verification
              </Typography>
              <TextField
                fullWidth
                label="Phone Number"
                name='phoneNumber'
                variant="outlined"
                value={values.phoneNumber}
                onChange={handlePhoneNumberChange}
                onBlur={handleBlur}
                error={phoneNumberError || (touched.phoneNumber && Boolean(errors.phoneNumber))}
                helperText={phoneNumberError ? 'Invalid Phone Number' : (touched.phoneNumber && errors.phoneNumber)}
                disabled = {isOtpSent}
                sx={{ marginBottom: 2 }}
              />
              {!isOtpSent && (
                <Box justifyContent={'space-between'} display={'flex'} alignItems={"baseline"}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={handlegetotp}
                    disabled={errors.phoneNumber || otpLoading || phoneNumberError || !values.phoneNumber}
                  >
                    {otpLoading ? "Sending.." : "Send OTP"}
                  </Button>
                  <Button onClick={handelBackToLogin}>Back to login</Button>
                </Box>
              )}
              {isOtpSent && (
                <>
                  <TextField
                    fullWidth
                    label="OTP"
                    name='otp'
                    variant="outlined"
                    value={otp}
                    onChange={handlePhoneNumberChange}
                    margin="normal"
                    error={otpError || (touched.otp && Boolean(errors.otp))}
                    helperText={otpError ? 'Invalid OTP' : (touched.otp && errors.otp)}
                    sx={{ marginBottom: 2 }}
                    onBlur={handleBlur}
                  />
                  {timer <= 0 ? <Alert severity="error">OTP has expired. Please request a new OTP.</Alert> : <Typography variant="body1" sx={{ mt: 2 }}>
                    OTP expires in: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                  </Typography>}
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    {timer <= 0 ? (
                      <Button variant="contained" color="primary" onClick={handlegetotp}>
                        Resend OTP
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary" onClick={handleVerifyOtp} disabled={timer <= 0 || otpLoading || !otp || otpError}>
                        Submit
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
          {!showOTPBox && (
            <>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    Don't have an account? <Link to="/register" underline="hover">Register now!</Link>
                  </Typography>
                </Grid>
              </Grid>
              <Link to="/resetpassword" underline="hover" style={{ display: 'block', textAlign: 'center', marginTop: 4, }}>
                <Typography variant="h5" style={{ color: "primary" }}>Forgot Password?</Typography>
              </Link>
            </>
          )}
        </Paper>
      </Box>
      <Termsandpolicy />
    </>
  );
};

export default LoginModule;
