import sliceCreator from '../index.js';
import {PASSWORD_RESET} from "../../../constants"

const _myPasswordResetAction = sliceCreator('PASSWORD_RESET', PASSWORD_RESET, 'post');

const { asyncAction: postPasswordResetAction, reducer, clearData: clearMyPasswordResetActionData } = _myPasswordResetAction;

export default reducer;

export {
    postPasswordResetAction,
    clearMyPasswordResetActionData
}
