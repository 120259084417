
import sliceCreator from '../index.js';
import {LOGIN} from "../../../constants"
const loginUserAction = sliceCreator('LOGIN_USER', LOGIN, 'post');

const { asyncAction: loginAction, reducer, clearData: clearLoginUserData } = loginUserAction;

export default reducer;

export {
    loginAction,
    clearLoginUserData
}


