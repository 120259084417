import sliceCreator from '../index.js';
import {MY_PARTNER_RATINGS} from "../../../constants"
const _getMyPartnerRatingsAction = sliceCreator('MY_PARTNER_RATINGS', MY_PARTNER_RATINGS, 'get');

const { asyncAction: getMyPartnerRatingsAction, reducer, clearData: clearGetMyPartnerRatingsActionData } = _getMyPartnerRatingsAction;

export default reducer;

export {
    getMyPartnerRatingsAction,
    clearGetMyPartnerRatingsActionData
}


