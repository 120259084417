
import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import PropTypes from 'prop-types';

const LinkedRealationships = ({ linkedDetails }) => {

    const columns = React.useMemo(() => TableData.LinkedRealationships, []);

    if (!linkedDetails || linkedDetails.length === 0) {
        return (
          <Box mt={2}>
            <Typography>No Linked Realationships Available</Typography>
          </Box>
        );
      }
    
      return (
        <Box mt={2}>
          <Table columns={columns} data={linkedDetails} />
        </Box>
      );
}

export default LinkedRealationships

LinkedRealationships.propTypes = {
  linkedDetails: PropTypes.string.isRequired,
};