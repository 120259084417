   
import sliceCreator from '../index.js';
import {MY_PARTNER} from "../../../constants"

const _getMyPartnerAction = sliceCreator('MY_PARTNER', MY_PARTNER, 'get');

const { asyncAction: getMyPartnerAction, reducer, clearData: clearGetMyPartnerActionData } = _getMyPartnerAction;

export default reducer;

export {
    getMyPartnerAction,
    clearGetMyPartnerActionData
}


