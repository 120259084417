import React from 'react';

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';



const PaymentSuccessPopup = ({handleClose}) => {
 
  
 

 

  const navigateToLogin = () => {
    handleClose(); // Close the dialog
  };

  return ( 
    <>

        <DialogTitle>
        Transaction updated successfully!
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <img src={require('../../assets/success.png')} alt="Success GIF" style={{ height: '200px', width: '200px' }} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5">
           Your account will be activated in 2 working days.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={navigateToLogin} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
    </>
  );
};

export default PaymentSuccessPopup;


PaymentSuccessPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
