import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { useDispatch, useSelector } from 'react-redux';
import { getadminPaymentsAction, deleteadminPaymentsAction } from '../../../store/actions';
import { useTheme } from '@mui/material/styles';
import SearchIcon from "@mui/icons-material/Search";
import { toast } from 'react-toastify';
import UserPayment from './payment'; // Import UserPayment component

function Payments() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('pending');
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [openUserPayment, setOpenUserPayment] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  
  const dispatch = useDispatch();

  const columns = React.useMemo(() => TableData.payments, []);

  const { successData: paymentData } = useSelector((state) => state.getadminPaymentsAction) || {};
  const { successData: deleteData, error: deleteError } = useSelector((state) => state.deleteadminPaymentsAction) || {};

  useEffect(() => {
    dispatch(getadminPaymentsAction());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(paymentData)) {
      setData(paymentData);
      filterData(paymentData, selectedOption);
    }
  }, [paymentData]);

  useEffect(() => {
    if (deleteData) {
      dispatch(getadminPaymentsAction());
      toast.success("User payment has been Deleted Successfully");
    }
  }, [deleteData, dispatch]);

  const filterData = (data, option) => {
    switch (option) {
      case 'all':
        setFilteredData(data);
        break;
      case 'pending':
        setFilteredData(data.filter(item => !item.is_success));
        break;
      case 'success':
          setFilteredData(data.filter(item => item.is_success));
          break; 
      case 'ent':
        setFilteredData(data.filter(item => item.subscription === 3));
        break;
      case 'CB':
        setFilteredData(data.filter(item => item.subscription === 2));
        break;
      case 'CBPRO':
        setFilteredData(data.filter(item => item.subscription === 1));
        break;
      default:
        setFilteredData(data);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    filterData(data, selectedOption);
  };

  const onSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
  
    if (value !== "") {
      const filteredData = paymentData.filter((item) => {
        return item.business
          .toLowerCase()
          .includes(value);
      });
      setFilteredData(filteredData);
    } else {
      filterData(paymentData, selectedOption);
    }
  };

  const handleRowClick = (row) => {
    setSelectedUser(row);
    setOpenUserPayment(true); // Open the UserPayment dialog
  };

  const handleDeleteClick = (row) => {
    setSelectedUser(row);
    setOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteadminPaymentsAction({ endPoint: `admin/payments/${selectedUser.id}/` }));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUserPayment = () => {
    setOpenUserPayment(false);
  };

  return (
    <Box minHeight="100vh" boxShadow={2} m={2} p={2}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" mb={isMobile ? 2 : 0}>Payments</Typography>
        <FormControl variant="outlined" style={{ width: isMobile ? '100%' : '300px' }}>
          <InputLabel>Select an option</InputLabel>
          <Select
            value={selectedOption}
            onChange={handleDropdownChange}
            label="Select an option"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="CB">India access</MenuItem>
            <MenuItem value="CBPRO">State access</MenuItem>
            <MenuItem value="ent">CB E+E</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mb = {2} >
        <TextField
          id="search"
          onChange={onSearchChange}
          label="Search"
          variant="standard"
          value={searchValue}
        />
        <SearchIcon sx={{ color: "#0e3c58", mr: 1,  my: 2.2 }} />
      </Box>
      {filteredData.length > 0 ? (
        <Table columns={columns} data={filteredData} handleRowClick={handleRowClick} handleDeleteClick={handleDeleteClick} />
      ) : (
        <Typography>No data available</Typography>
      )}
      {deleteError && (
        <Typography color="error">{deleteError}</Typography>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the user payment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUserPayment}
        onClose={handleCloseUserPayment}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedUser && <UserPayment paymentData={selectedUser} handleCloseUserPayment = {handleCloseUserPayment} />} {/* Pass the selectedUser data to UserPayment */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserPayment} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Payments;
