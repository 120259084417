/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Stack,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Backdrop from "../../components/backdrops/Backdrop";
import PropTypes from 'prop-types';
import {
  clearRegistrationData,
  globalConfigAction,
  registrationAction,
  clearSendOtpData,
  clearVerifyOtpData,
  sendOtpAction,
  verifyOtpAction,
} from "../../store/actions";
import { getLableAndValuesFromData } from "../../utils";
 
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
 
import "./signup.scss";
import { designationOptions } from "../../constants/designationOptions";
import ReCAPTCHA from 'react-google-recaptcha';
import {SITE_KEY} from "../../constants/index"
 
export default function Gstdata({ data }) {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
 
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
 
  const handleToggleconfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };
 
  if (!data?.fullname) {
    window.location.reload();
  }
 
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    industry: null,
    partnerType: null,
    companyType: null,
    incorporationDate: "",
    emailOtp: "",
    phoneOtp: "",
    termsAndConditions: false,
    AuthorizedName:"",
    Designation: null,
    PhoneNumber:"",
    AuthorizedDetails: false
  });
 
  const [errors, setErrors] = useState({});
 
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    // Function to validate input based on its name and value
    const validateInput = (name, value) => {
      switch (name) {
        case 'phone':
          return /^[6-9]\d{9}$/.test(value) ? '' : 'Phone number is not valid.';
          
        case 'email':
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Email is not valid.';
          
        case 'password': {
          // Wrap the case block in curly braces
          const passwordError = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,15}$/.test(value) ?
            '' :
            'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 to 15 characters long.';
          
          // Check if confirmPassword is affected by password change
          const confirmPasswordError = name === 'password' && formData.confirmPassword !== value ? 'Passwords do not match.' : '';
          
          // Update confirmPassword error state
          setErrors(prevErrors => ({
            ...prevErrors,
            confirmPassword: confirmPasswordError
          }));
          
          return passwordError;
        }
          
        case 'confirmPassword':
          return value === formData.password ? '' : 'Passwords do not match.';
          
        default:
          return '';
      }
    };
    
    
    // Validate input and update errors state
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: validateInput(name, value),
    }));

    // Update form data based on the input type
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    const validateInput = (name, value) => {
      switch (name) {
        case 'phone':
          return /^[6-9]\d{9}$/.test(value) ? '' : "Phone number is required.";
        case 'email':
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Email is required.';
        case 'password':
          return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,15}$/.test(value) ?
            '' :
            'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 to 15 characters long.';
        default:
          return '';
      }
    };


    // Validate confirmPassword onBlur
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateInput(name, value),
      confirmPassword: name === 'confirmPassword' && formData.password !== value ? 'Passwords do not match.' : prevErrors.confirmPassword,
    }));
  };




  
 
 
  const dispatch = useDispatch();
  const { successData } = useSelector((state) => state.globalConfigData) || {};
  const {
    loading: isloading,
    successData: iAmSuccess,
    error: registrationError,
    errorInfo: registrationErrorInfo,
  } = useSelector((state) => state.registrationData) || {};
  const [companyTypes, setCompanyTypes] = useState([]);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);

  const industryOptions = [...industryTypes];
  const partnerOptions = [...partnerTypes];
  const companyOptions = [...companyTypes];
 

  const navigate = useNavigate();
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [phoneOtpSent, setPhoneOtpSent] = useState(false);
  const [emailOtpTimer, setEmailOtpTimer] = useState(30);
  const [phoneOtpTimer, setPhoneOtpTimer] = useState(30);
  const [identifier, setIdentifier] = useState("");
  const [verifyIdentifier, setVerifyIdentifier] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const {
    successData: sendOtpSuccessData,
    loading: sendOtpLoading,
    error: sendOtpError,
    errorInfo: sendOtpErrorInfo,
  } = useSelector((state) => state.sendOtpReducer) || {};
  const {
    successData: verifyOtpSuccessData,
    loading: verifyOtpLoading,
    error: verifyOtpError,
    errorInfo: verifyOtpErrorInfo,
  } = useSelector((state) => state.verifyOtpReducer) || {};
 
  const handleSendOtp = (identifier) => {
    setIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(sendOtpAction({ identifier: formData.email }));
        break;
      case "phone":
        dispatch(sendOtpAction({ identifier: formData.phone }));
        break;
      default:
        toast.error("Please try again");
    }
  };
 
  const handleVerifyOtp = (identifier) => {
    setVerifyIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(
          verifyOtpAction({
            identifier: formData.email,
            otp: formData.emailOtp,
          })
        );
        break;
      case "phone":
        dispatch(
          verifyOtpAction({
            identifier: formData.phone,
            otp: formData.phoneOtp,
          })
        );
        break;
      default:
        toast.error("Please try again");
    }
  };

  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaChecked,setCaptchaChecked] = useState(false)

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaChecked(false)
    
  };
 
  useEffect(() => {
    localStorage.removeItem("token");
    dispatch(globalConfigAction());
  }, [dispatch, data]);
 
  useEffect(() => {
    if (data && companyOptions) {
      const companyType = companyOptions.find((item) => item.label === data.company);
      if (companyType) {
        setFormData(prevFormData => ({
          ...prevFormData,
          companyType: companyType,
        }));
      }
    }
  }, [data,companyOptions]);
 
  useEffect(() => {
    if (successData) {
      setCompanyTypes(getLableAndValuesFromData(successData.company_type));
      setIndustryTypes(getLableAndValuesFromData(successData.industry_type));
      setPartnerTypes(getLableAndValuesFromData(successData.partner_type));
    }
  }, [successData]);
  React.useEffect(() => {
    if (iAmSuccess) {
      localStorage.setItem("token", iAmSuccess.data.key);
      localStorage.setItem("id", iAmSuccess.data.user.id);
      localStorage.setItem("is_subscription_active", iAmSuccess.data?.is_subscription_active || false);
      localStorage.setItem("is_company", iAmSuccess.data?.user?.business?.is_company);
      navigate("/subscription");
      toast.success("User Registration is successful");
    }
  }, [iAmSuccess]);
 
  useEffect(() => {
    if (registrationError) {
      toast.error(
        registrationErrorInfo || "Failed to register"
      );
      if (
        registrationErrorInfo.includes("duplicate key value violates unique") ||
        registrationErrorInfo.includes("Phone Number already exists")
      ) {
        setPhoneOtpSent(false);
        setIsPhoneVerified(false);
      }
      dispatch(clearRegistrationData());
    }
  }, [registrationError, registrationErrorInfo]);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      // Assuming captchaValue is the token obtained from CAPTCHA
      if (!captchaValue) {
        setCaptchaChecked(true)
        return;
      }

      const userData = {
        user: {
          email: formData.email,
          user_type: formData.partnerType.value,
          first_name: data.fullname,
          last_name: data.fullname,
          pan_number: data.gstin.toUpperCase().slice(2, 12),
          phone_number: formData.phone,
          password1: formData.password,
          password2: formData.confirmPassword,
          captcha: captchaValue 
        },
        business: {
          industry: formData.industry.value,
          company: formData.companyType.value,
          name: data.business_name,
          pan_number: data.gstin.toUpperCase().slice(2, 12),
          city: data.city,
          incorporation_date: data.incorporation_date,
          state: data.state,
          district: data.district,
          authorized_user_name: formData.AuthorizedName,
          authorized_user_phone_number: formData.PhoneNumber,
          authorized_user_designation: formData.Designation ? formData.Designation.label : null,
        },
      };
      dispatch(registrationAction(userData));
    } else {
      dispatch(clearRegistrationData());
    }
  };
 
  // Styled component
  const DisabledField = styled(TextField)({
    "&.Mui-disabled": {
      backgroundColor: "white",
      color: "red",
    },
  });
  // Validation function
  const validate = () => {
    let tempErrors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Email is not valid.";
    }

    // Phone validation
    const phoneRegex = /^\d{10}$/; // Adjust regex according to your country format
    if (!phoneRegex.test(formData.phone)) {
      tempErrors.phone = "Phone number is not valid.";
    }

    // Password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}$/;
    if (!passwordRegex.test(formData.password)) {
      tempErrors.password = "Password must be 8-15 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    // Confirm Password validation
    if (formData.password !== formData.confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match.";
    }

    // Dropdowns validation
    if (!formData.companyType || formData.companyType.value === "none") {
      tempErrors.companyType = "Please select a company type.";
    }

    if (!formData.industry || formData.industry.value === "none") {
      tempErrors.industry = "Please select an industry type.";
    }

    if (!formData.partnerType || formData.partnerType.value === "none") {
      tempErrors.partnerType = "Please select a partner type.";
    }

    if (!formData.termsAndConditions) {
      tempErrors.termsAndConditions = "Please read & accept the terms and conditions.";
    }

    // Authorized Person Details validation
    if (formData.AuthorizedDetails) {
      if (formData.AuthorizedName && formData.AuthorizedName.trim() === '') {
          tempErrors.AuthorizedName = "Authorized person name is required.";
      }
      if (formData.Designation && !formData.Designation) {
          tempErrors.Designation = "Authorized person designation is required.";
      }
      if (formData.PhoneNumber && !phoneRegex.test(formData.PhoneNumber)) {
          tempErrors.PhoneNumber = "Authorized person phone number is not valid.";
      }
  }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // true if no errors
  };

  useEffect(() => {
    if (sendOtpSuccessData && identifier) {
      if (identifier === "email") {
        toast.success("OTP sent to email");
        setEmailOtpSent(true);
        setEmailOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      } else if (identifier === "phone") {
        toast.success("OTP sent to phone number");
        setPhoneOtpSent(true);
        setPhoneOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      }
    } else if (sendOtpError) {
      toast.error(
        sendOtpErrorInfo || "Failed to send OTP"
      );
      dispatch(clearSendOtpData());
    }
  }, [sendOtpSuccessData, sendOtpError, sendOtpErrorInfo, identifier]);
 
  useEffect(() => {
    if (emailOtpSent && emailOtpTimer > 0) {
      const timer = setInterval(() => {
        setEmailOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (emailOtpTimer === 0) {
      setEmailOtpSent(false);
    }
  }, [emailOtpSent, emailOtpTimer]);
 
  useEffect(() => {
    if (phoneOtpSent && phoneOtpTimer > 0) {
      const timer = setInterval(() => {
        setPhoneOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (phoneOtpTimer === 0) {
      setPhoneOtpSent(false);
    }
  }, [phoneOtpSent, phoneOtpTimer]);
 
  useEffect(() => {
    if (verifyOtpSuccessData && verifyIdentifier) {
      if (verifyIdentifier === "email") {
        toast.success("Email verified successfully");
        setEmailOtpSent(false);
        setIsEmailVerified(true);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      } else if (verifyIdentifier === "phone") {
        toast.success("Phone number verified successfully");
        setIsPhoneVerified(true);
        setPhoneOtpSent(false);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      }
    } else if (verifyOtpError) {
      toast.error(
       verifyOtpErrorInfo || "Failed to verify OTP"
      );
      dispatch(clearVerifyOtpData());
    }
  }, [
    verifyOtpSuccessData,
    verifyOtpError,
    verifyOtpErrorInfo,
    verifyIdentifier,
  ]);
 
  return (
    <>
      {isloading && <Backdrop />}
 
      {
        <Grid container justifyContent={"center"} mb={10}>
          <Grid item xs={12} lg={6}>
            <div className="text-center mt-4">
            <img  src="/images/clearbiz1.png" width={"60%"} alt="" />
            </div>
          </Grid>
          <Grid item xs={10} lg={8}>
            <Card
              sx={{ width: "100%", padding: "15px", marginTop: "10px" }}
              elevation={20}
            >
              <CardContent>
                <form method="post" onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="Full Name"
                        value={data.fullname}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="Bussiness Name"
                        value={data.business_name}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="Incorporation Date"
                        type="text" // or "date" if you want to use a date picker
                        value={data.incorporation_date}
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            incorporationDate: event.target.value,
                          });
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
 
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="GSTIN"
                        value={data.gstin}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
 
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="City"
                        value={data.city}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="District"
                        value={data.district}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DisabledField
                        label="State"
                        value={data.state}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="industry-autocomplete"
                        options={industryOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Industry"
                            variant="outlined"
                            fullWidth
                            error={!!errors.industry}
                            helperText={errors.industry}
                          />
                        )}
                        value={formData.industry}
                        onChange={(event, newValue) => {
                          setFormData({ ...formData, industry: newValue });
                        }}
                      />
                    </Grid>
 
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="partner-type-autocomplete"
                        options={partnerOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Partner Type"
                            variant="outlined"
                            fullWidth
                            error={!!errors.partnerType}
                            helperText={errors.partnerType}
                          />
                        )}
                        value={formData.partnerType}
                        onChange={(event, newValue) => {
                          setFormData({ ...formData, partnerType: newValue });
                        }}
                      />
                    </Grid>
 
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="company-type-autocomplete"
                        options={companyOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Type"
                            variant="outlined"
                            fullWidth
                            error={!!errors.companyType}
                            helperText={errors.companyType}
                          />
                        )}
                        value={formData.companyType}
                        onChange={(event, newValue) => {
                          setFormData({ ...formData, companyType: newValue });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
 
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        autoComplete="off"
                        disabled={emailOtpSent || isEmailVerified}
                        variant="outlined"
                        error={!!errors.email} // Displays error styling if there is an error for email
                        helperText={errors.email} // Displays the email error message
                        sx={{ marginBottom: "6px" }}
                      />
                      {isEmailVerified && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                          }}
                        >
                          <CheckCircleIcon />
                          <span>Verified</span>
                        </div>
                      )}
                      {!errors.email && formData.email && (
                        <>
                          {emailOtpSent && (
                            <>
                              <span>OTP Expires in :</span>{" "}
                              <span className="text-danger">
                                {Math.floor(emailOtpTimer / 60)}:
                                {emailOtpTimer % 60 < 10 ? "0" : ""}
                                {emailOtpTimer % 60}
                              </span>
                              {"   "}
                            </>
                          )}
                          <div className="signup-otp-container">
                            {!emailOtpSent && !isEmailVerified && (
                              <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                className="signup-otp-button"
                                disabled={sendOtpLoading}
                                onClick={() => handleSendOtp("email")}
                              >
                                {sendOtpLoading ? (
                                  <CircularProgress
                                    size={24}
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  "Send OTP"
                                )}
                              </Button>
                            )}
                            {emailOtpSent && (
                              <>
                                <TextField
                                  type="text"
                                  name="emailOtp"
                                  onChange={handleChange}
                                  className="signup-otp-input"
                                  placeholder="OTP sent to email"
                                />
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  className="signup-otp-button"
                                  disabled={verifyOtpLoading || formData.emailOtp.length !== 6}
                                  onClick={() => handleVerifyOtp("email")}
                                >
                                  Submit OTP
                                </Button>
                              </>
                            )}
                          </div>
                          {emailOtpSent && (
                            <span className="signup-otp-timer">
                              <button
                                className="signup-otp-action"
                                onClick={() => {
                                  handleSendOtp("email");
                                  setEmailOtpSent(false);
                                }}
                              >
                                Resend OTP
                              </button>{" "}
                              or
                              <button
                                className="signup-otp-action"
                                onClick={() => setEmailOtpSent(false)}
                              >
                                {" "}
                                Change email
                              </button>
                            </span>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                         onBlur={handleBlur}
                        fullWidth
                        autoComplete="off"
                        type="tel"
                        disabled={phoneOtpSent || isPhoneVerified}
                        variant="outlined"
                        error={!!errors.phone} // Displays error styling if there is an error for phone
                        helperText={errors.phone} // Displays the phone error message
                        sx={{ marginBottom: "6px" }}
                      />
                      {isPhoneVerified && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                          }}
                        >
                          <CheckCircleIcon />
                          <span>Verified</span>
                        </div>
                      )}
                      {!errors.phone && formData.phone && (
                        <>
                          {phoneOtpSent && (
                            <>
                              <span>OTP Expires in :</span>{" "}
                              <span className="text-danger">
                                {Math.floor(phoneOtpTimer / 60)}:
                                {phoneOtpTimer % 60 < 10 ? "0" : ""}
                                {phoneOtpTimer % 60}
                              </span>
                              {"   "}
                            </>
                          )}
                          <div className="signup-otp-container">
                            {!phoneOtpSent && !isPhoneVerified && (
                              <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                className="signup-otp-button"
                                disabled={sendOtpLoading}
                                onClick={() => handleSendOtp("phone")}
                              >
                                {sendOtpLoading ? (
                                  <CircularProgress
                                    size={24}
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  "Send OTP"
                                )}
                              </Button>
                            )}
                            {phoneOtpSent && (
                              <>
                                <TextField
                                  type="text"
                                  name="phoneOtp"
                                  onChange={handleChange}
                                  className="signup-otp-input"
                                  placeholder="OTP sent to phone"
                                />
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  className="signup-otp-button"
                                  disabled={verifyOtpLoading || formData.phoneOtp.length !== 6}
                                  onClick={() => handleVerifyOtp("phone")}
                                >
                                  Submit OTP
                                </Button>
                              </>
                            )}
                          </div>
                          {phoneOtpSent && (
                            <span className="signup-otp-timer">
                              <button
                                className="signup-otp-action"
                                onClick={(e) => {
                                  handleSendOtp("phone");
                                  setPhoneOtpSent(false);
                                }}
                              >
                                Resend OTP
                              </button>{" "}
                              or
                            <button
                              className="signup-otp-action"
                                onClick={() => setPhoneOtpSent(false)}>
                                Change phone
                              </button>
                            </span>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        error={!!errors.password} // Displays error styling if there is an error for password
                        helperText={errors.password} // Displays the password error message
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Confirm Password"
                        type={confirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                         onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        error={!!errors.confirmPassword} // Displays error styling if there is an error for confirmPassword
                        helperText={errors.confirmPassword} // Displays the confirmPassword error message
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={handleToggleconfirmPassword}
                              edge="end"
                            >
                              {confirmPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container style={{ marginLeft: '24px' }}>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          name="AuthorizedDetails"
                          checked={formData.AuthorizedDetails}
                          id="AuthorizedDetails"
                          onChange={handleChange}
                          style={{ paddingLeft: 0 }}
                        />
                        <div>
                          <label
                            htmlFor="AuthorizedDetails"
                            className="form-label remove-margin-bottom"
                          >
                            Would you Like to Add Authorized Person Details?
                          </label>
                        </div>
                      </Stack>

                      {formData.AuthorizedDetails && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="User Name"
                              name="AuthorizedName"
                              value={formData.AuthorizedName}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                              error={!!errors.AuthorizedName}
                              helperText={errors.AuthorizedName}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              id="Designation"
                              options={designationOptions}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Designation"
                                  variant="outlined"
                                  fullWidth
                                  name="Designation"
                                  error={!!errors.Designation}
                                  helperText={errors.Designation}
                                />
                              )}
                              value={formData.Designation}
                              onChange={(event, newValue) => {
                                setFormData({ ...formData, Designation: newValue });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="PhoneNumber"
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                              error={!!errors.PhoneNumber}
                              helperText={errors.PhoneNumber}
                              sx={{ marginBottom: "6px" }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          name="termsAndConditions"
                          value={formData.termsAndConditions}
                          id="termsAndConditions"
                          onChange={handleChange}
                          style={{paddingLeft:0}}
                          error={!!errors.termsAndConditions} // Displays error styling if there is an error for termsAndConditions
                        />
                        <div>
                          <label
                            htmlFor="termsAndConditions"
                            className="form-label remove-margin-bottom"
                          >
                            By Clicking here you agree to our{" "}
                            <a href="about/terms" target="_blank">
                              Terms and Conditions
                            </a>
                          </label>
                          {errors.termsAndConditions && (
                            <p className="form-error error-message remove-margin">
                              {errors.termsAndConditions}
                            </p>
                          )}
                        </div>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <ReCAPTCHA
                        sitekey={SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                      {captchaChecked && <p style={{color:'red'}}>please complete the captcha*</p>}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isEmailVerified || !isPhoneVerified}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  );
}
 
Gstdata.propTypes = {
  data: PropTypes.object.isRequired,
};
