   
import sliceCreator from '../index.js';
import {INACTIVE_USERS} from "../../../constants"

const _getadminInctiveUsersAction = sliceCreator('INACTIVE_USERS',INACTIVE_USERS, 'get');

const { asyncAction: getadminInctiveUsersAction, reducer, clearData: clearGetadminInctiveUsersAction } = _getadminInctiveUsersAction

export default reducer;

export {
   getadminInctiveUsersAction,
   clearGetadminInctiveUsersAction
}