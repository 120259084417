
import MoreIcon from "@mui/icons-material/MoreVert";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch} from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import clearbiz2 from "../../assets/clearbiz2.png";
import { clearLoginUserData } from "../../store/actions";
import { dispatchAll } from "../../utils";
import { useMediaQuery } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { navList } from "../../constants/adminNavlist";

const drawerWidth = 120;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



const CustomTypography = styled(Typography)`
  font-family: Tahoma, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
`;

const mobileMenuId = "primary-search-account-menu-mobile";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // Removed open state styles from here
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);



  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickLogout = () => {
    dispatchAll([clearLoginUserData], dispatch);
    localStorage.clear();
    navigate("/");
  };

  const logoutTimeoutRef = useRef(null);
  const resetLogoutTimer = () => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }
    logoutTimeoutRef.current = setTimeout(() => {
      onClickLogout();
    }, 43200000); // 12 minute of inactivity
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    const handleEvent = () => resetLogoutTimer();

    events.forEach((event) => window.addEventListener(event, handleEvent));
    resetLogoutTimer(); // Initialize the timer when the component mounts

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleEvent));
      if (logoutTimeoutRef.current) {
        clearTimeout(logoutTimeoutRef.current);
      }
    };
  }, []);

  const onClickSideMenuItem = (item) => {
    navigate(item.goTo);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ bgcolor: "#457b9d" }}>
        <Toolbar>
          <CustomTypography variant="h6" noWrap component="div">
            <Link to="/dashboard">
              <img
                src={clearbiz2}
                className="clearbiz-img"
                alt="Clearbiz Logo"
              />
            </Link>
          </CustomTypography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}C>
        <Divider />
        <List>
          {navList.map((item, index) => (
              <ListItem
                key={item.title}
                
                sx={{ display: "block" }}
                onClick={() => onClickSideMenuItem(item)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    flexDirection: "column",
                    alignItems: "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: theme.palette.info.dark,
                      color: theme.palette.common.white,
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mb: 1,
                      alignSelf: "center",
                      fontSize: "20px",
                    }}
                  >
                    <i className={`no-active-sidebar ${item.icon}`}></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0, alignSelf: "center" }}
                  />
                </ListItemButton>
                <Divider />
              </ListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ pb: 2 }}>
          <Drawer variant="permanent" open={open} >
            <DrawerHeader/>
            <Divider />
            <List>
              {navList.map((item, index) => (
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => onClickSideMenuItem(item)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        flexDirection: "column",
                        alignItems: "center",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: theme.palette.info.dark,
                          color: theme.palette.common.white,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mb: 1,
                          alignSelf: "center",
                          fontSize: "15px",
                        }}
                      >
                        <i className={`no-active-sidebar ${item.icon}`}></i>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        sx={{ opacity: open ? 1 : 0, alignSelf: "center" }}
                      />
                    </ListItemButton>
                    <Divider />
                  </ListItem>
              ))}
            </List>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ pb: 2 }}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    flexDirection: "column",
                    alignItems: "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: theme.palette.info.dark,
                      color: theme.palette.common.white,
                    },
                  }}
                  onClick={handleOpenDialog} // Open the confirmation dialog
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mb: 1,
                      alignSelf: "center",
                      fontSize: "20px",
                    }}
                  >
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Sign out"
                    sx={{ opacity: open ? 1 : 0, alignSelf: "center" }}
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          </Drawer>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiDialog-paper": { width: "500px" } }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Sign Out"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to exit?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} autoFocus color="primary">
                No
              </Button>
              <Button onClick={onClickLogout} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Drawer>
    </Box>
  );
}
