import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import covercbzhome from '../../assets/covercbzhome.webp';


const MainVisual = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: `url(${covercbzhome})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '620px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start', 
        color: 'white',
        padding: theme.spacing(3), 
        position: 'relative', 
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: theme.spacing(1), textAlign: 'left' }}>
        Welcome to ClearBiz
      </Typography>
      <Typography variant="h5" sx={{ fontStyle: 'italic', marginBottom: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: theme.spacing(1), textAlign: 'left' }}>
        Your Partner in Financial Growth
      </Typography>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          bottom: theme.spacing(3),
          right: theme.spacing(3),
          width: '70%',
          fontStyle: 'italic',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: theme.spacing(1),
          textAlign: 'left',
          '@media (max-width: 768px)': {
            width: "90%",
            bottom: "0px",
            padding: "0px",
            left: "40px",
          }
        }}
      >
        ClearBiz is a Private Rating Platform dedicated to helping businesses identify honest partners while spotting potential defaulters, ensuring safer transactions, and reducing bad debts.
      </Typography>

    </Box>
  );
};

export default MainVisual;