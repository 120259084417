import React from "react";
import '../../styles/scoreDetails.css'
import PropTypes from "prop-types"
const LegalProceedings = ({legalProceedings}) => {
  return (
    <>
    
      <div className="styleforheading">No. of Legal Proceedings</div>
      <div className="main">
        <h5>{legalProceedings}</h5>
    </div>
    </>
  );
};

export default LegalProceedings;

LegalProceedings.propTypes = {
  legalProceedings:PropTypes.string.isRequired
}
