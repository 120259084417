import statesInfo from './statesdata.js';

export const deepCopy = (data) => JSON.parse(JSON.stringify(data))

export const getLableAndValuesFromData = (data) => {
  const valueAndLabels = [];
  data.forEach(element => {
    const keyValueObj = {}
    keyValueObj['value'] = element.id
    keyValueObj['label'] = element.name
    valueAndLabels.push(keyValueObj)
  });

  return valueAndLabels;
}

export const getLableAndValuesFromGeoGraphicalData = (data, valueKey, labelKey) => {
  const valueAndLabels = [];
  data.forEach(element => {


    const keyValueObj = {}
    keyValueObj['value'] = element[valueKey]
    keyValueObj['label'] = element[labelKey]
    valueAndLabels.push(keyValueObj)


  });

  return valueAndLabels;
}
export const getStates = () => statesInfo.map((state) =>({ value: state.value, label: state.label}))
export const getDistricts = (selectedState) => {
  return statesInfo.filter((state) => state.value === selectedState)[0].districts
}

export function dispatchAll(actions, dispatch) {
  const _dispatch = (f) => typeof f === 'function' ? dispatch(f()) : null;

  if (Array.isArray(actions) && actions.length > 0) {
    for (const data of actions) {
      _dispatch(data);
    }
  } else {
    _dispatch(actions);
  }
}

// identify how many of 1 rating and 2 rating and 3 rating and 4 rating and 5 rating  from array of objects
const getRatingCount = (data, ratingValue) => {
  const filteredData = data.filter((item) => item['score']  === ratingValue)
  return filteredData.length
}



export const getOverallDetailsOfUser = (data) => {


  const overAllRatings = {}
  let avgRating = 0;
    for (let i = 0; i < 11; i++) {
    overAllRatings[i] = getRatingCount(data, i)
  }
  let totalAmountBalance = 0;
  let totalCustomers = 0;
  let totalPartners = 0;
  data.forEach((item) => {
    totalAmountBalance += parseInt(item['totaloutstanding'])
    avgRating += item['score'] 
    if (item['is_business_exists']) {
      totalPartners += 1
    } else {
      totalCustomers += 1
    }
  })
  avgRating = avgRating / data.length
  avgRating = parseFloat(avgRating).toFixed(1)

  return {overAllRatings, totalAmountBalance, totalCustomers, totalPartners, avgRating}
}

// function to get enable fields based on score vlaue from dropdown



export const canEnableField = (score, field) => {

const score_10_field = ["comments"]
const score_9_field = ["comments"]
const score_8_field = ["reachability","recoveryprobability", "percentages","transactionPaidOntime", "gst", "capital","assertworth","comments"]
const score_7_field = ["reachability", "gst", "comments"]
const score_6_field = ["reachability", "gst", "comments"]
const score_5_field = ["totaloutstanding", "dso",  "percentages","capital","assertworth","comments"] 
const score_4_field = ["totaloutstanding", "dso", "reachability", "legal",
                            "recoveryprobability", "percentages","transactionPaidOntime", "gst", "capital","assertworth","comments"]
const score_3_field = [ "pendingrecievable","since","legal","reachability", "recoveryprobability", "gst", "capital","assertworth","comments"]
const score_2_field = [ "pendingrecievable","since","legal","reachability",
                            "recoveryprobability", "percentages","transactionPaidOntime", "gst", "capital","assertworth","comments"]
const score_1_field = [ "pendingrecievable","since","legal","reachability",
                            "recoveryprobability", "percentages","transactionPaidOntime", "gst", "capital","assertworth","comments"]

switch(score.value) {
  case 10:
    return score_10_field.includes(field)
  case 9:
    return score_9_field.includes(field)
  case 8:
    return score_8_field.includes(field)
  case 7:
    return score_7_field.includes(field)
  case 6:
    return score_6_field.includes(field)
  case 5:
    return score_5_field.includes(field)
  case 4:
    return score_4_field.includes(field)
  case 3:
    return score_3_field.includes(field)
  case 2:
    return score_2_field.includes(field)
  case 1:
    return score_1_field.includes(field)
  default:
    return false
}
}